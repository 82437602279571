import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Skeleton,
  Typography,
} from "@mui/material";
import { ReactElement, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { operatorESignStatus, operatorLinkType } from "../../../constant/enum";
import { GenericObject } from "../../../interfaces/commonInterface";
import {
  InitialOtpState,
  IResendOtpPayload,
  IVerifyOtpPayload,
} from "../../../interfaces/operatorInterface";
import en from "../../../locales/en.json";
import {
  eSignGenerateOtpAction,
  eSignResendOtpAction,
  eSignVerifyOtpAction,
  fetchESignDocAction,
  verifyOperatorEmailAction,
} from "../../../redux/actions";
import { RootState } from "../../../redux/store";
import { CustomButton } from "../../../yor-src/components/atom/Buttons/CustomButtons";
import OtpModal from "../../../yor-src/components/template/OtpModal";
import WrongUrl from "../../redirectionPages/inavlidUrl";
import { StyleObject } from "./StyleObject";

const { operator } = en;

const initialOtpState: InitialOtpState = {
  phone: "",
  otp: "",
  referenceId: "",
  agreementId: "",
};

const OperatorVerification = (): ReactElement => {
  const { uniqueKey } = useParams(); // Extracting unique key from URL params
  const location = useLocation(); // Get the current location path
  const dispatch = useDispatch();
  const clearOtpRef = useRef<any>(null);

  // Determine verification type based on URL path
  const isEmailVerification = location.pathname.includes(
    operatorLinkType?.VERIFY_EMAIL
  );
  const isEsignVerification = location.pathname.includes(
    operatorLinkType?.E_SIGN
  );

  // Fetching data from Redux store
  const {
    verifyOperatorEmailData,
    verifyOperatorEmailLoader,
    fetchEsignDocLoader,
    fetchEsignDocSuccessData,
    eSignOtpGenerationLoader,
    eSignOtpGenerationData,
  } = useSelector((state: RootState) => state.operatorReducer);

  const [fields, setFields] = useState<InitialOtpState>({ ...initialOtpState }); // State to handle OTP fields
  const [show, setShow] = useState<boolean>(false); // Modal visibility state

  useEffect(() => {
    if (uniqueKey) {
      if (isEmailVerification) {
        dispatch(verifyOperatorEmailAction(uniqueKey)); // Dispatch email verification action
      } else if (isEsignVerification) {
        dispatch(fetchESignDocAction(uniqueKey)); // Dispatch e-sign document fetch action
      }
    }
  }, [uniqueKey, isEmailVerification, isEsignVerification, dispatch]);

  // Handle input field changes
  const handleChange = (key: string, value: string): void => {
    setFields((prevState) => ({ ...prevState, [key]: value }));
  };

  // Handle OTP resend action
  const handleResendOtp = (): void => {
    setFields({ ...fields, otp: "" });
    if (clearOtpRef?.current) {
      clearOtpRef?.current?.updateState([]);
    }
    const payload: IResendOtpPayload = {
      data: { referenceId: fields?.referenceId },
    };
    dispatch(eSignResendOtpAction(payload));
  };

  // Callback function for successful OTP generation
  const eSignGenerateOtpApiSuccessFunction = (data?: GenericObject): void => {
    setFields((prev: InitialOtpState) => ({
      ...prev,
      referenceId: data?.referenceId,
    }));
    setShow(true);
  };

  // Handle OTP generation request
  const generateOtpHandler = (): void => {
    const payload = {
      data: { agreementId: fetchEsignDocSuccessData?.agreementId },
    };
    dispatch(
      eSignGenerateOtpAction(payload, eSignGenerateOtpApiSuccessFunction)
    );
  };

  // Callback function for successful OTP verification
  const eSignVerifyOtpApiSuccessFunction = (): void => {
    setShow(false);
    dispatch(fetchESignDocAction(uniqueKey));
  };

  // Handle OTP verification request
  const validateOtpHandler = (): void => {
    const payload: IVerifyOtpPayload = {
      data: { otp: fields?.otp, referenceId: fields?.referenceId },
    };
    dispatch(eSignVerifyOtpAction(payload, eSignVerifyOtpApiSuccessFunction));
  };

  // Check if OTP submit button should be enabled
  const isOtpSubmitBtnEnabled = (): boolean => fields?.otp?.length !== 6;

  // Handle modal close event
  const handleModalClose = (event: any, reason: string): void => {
    if (reason === "backdropClick") return;
    setFields({ ...fields, otp: "" });
    setShow(false);
  };

  // Loader component to display skeleton while fetching data
  const SkeletonLoader = ({ isEmail }: { isEmail?: boolean }): ReactElement =>
    isEmail ? (
      <Card sx={StyleObject?.emailCard}>
        <Skeleton variant="rectangular" sx={StyleObject?.topHalfBox} />
        <CardContent sx={StyleObject?.bottomHalfBox}>
          <Skeleton
            variant="text"
            width="80%"
            height={40}
            sx={{ marginBottom: 2 }}
          />
        </CardContent>
      </Card>
    ) : (
      <Box sx={StyleObject?.loaderBox}>
        <CircularProgress />
      </Box>
    );

  // Display loader while fetching email or e-sign data
  if (verifyOperatorEmailLoader || fetchEsignDocLoader) {
    return (
      <Box sx={StyleObject?.loaderBox}>
        <SkeletonLoader isEmail={isEmailVerification} />
      </Box>
    );
  }

  // Email verification success message
  if (isEmailVerification) {
    return (
      <Box sx={StyleObject?.emailBox}>
        <Helmet>
          <title>{en?.global?.pageTitle}</title>
          <meta name={en?.global?.title} content={en?.global?.content} />
          <meta
            name={en?.global?.description}
            content={en?.global?.descriptionContent}
          />
        </Helmet>
        <Card sx={StyleObject?.emailCard}>
          <Box sx={StyleObject?.topHalfBox} />
          <CardContent sx={StyleObject?.bottomHalfBox}>
            <Typography variant="h5" align="center" gutterBottom>
              {verifyOperatorEmailData?.statusCode === 422
                ? verifyOperatorEmailData?.errorMessage
                : verifyOperatorEmailData?.message}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    );
  }

  // E-sign verification UI
  if (isEsignVerification) {
    return (
      <Box sx={StyleObject?.eSignBox}>
        <Helmet>
          <title>{en?.global?.pageTitle}</title>
        </Helmet>
        <Box
          sx={StyleObject?.agreementBox}
          dangerouslySetInnerHTML={{
            __html: fetchEsignDocSuccessData?.generatedAgreement,
          }}
        />
        <CustomButton
          onClick={generateOtpHandler}
          variant="contained"
          loading={eSignOtpGenerationLoader}
          sx={StyleObject?.customButtonSx}
          disabled={
            fetchEsignDocSuccessData?.status === operatorESignStatus?.VERIFIED
          }
        >
          {fetchEsignDocSuccessData?.status === operatorESignStatus?.VERIFIED
            ? operator?.agreementVerified
            : operator?.verifyAgreement}
        </CustomButton>

        {/* Otp Modal */}
        {show && (
          <OtpModal
            handleChange={handleChange}
            otpValue={fields?.otp}
            phoneNumber={eSignOtpGenerationData?.phone}
            openModal={show}
            handleModalClose={handleModalClose}
            handleOtpModalSubmit={validateOtpHandler}
            handleResendOtp={handleResendOtp}
            isOtpSubmitBtnEnabled={isOtpSubmitBtnEnabled}
            ref={clearOtpRef}
          />
        )}
      </Box>
    );
  }

  // Wrong URL Fallback
  return <WrongUrl />;
};

export default OperatorVerification;
