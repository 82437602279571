import bangalore_banner from "../img/Freedo-Banner-New.jpg";
import gurgaon_banner from "../img/banner-gurgaon.jpg";

import bangalore_about from "../img/about.png";
import gurgaon_about from "../img/ab-3.png";

import bangalore_blog_1 from "../img/blog-1.png";
import bangalore_blog_2 from "../img/blog-2.png";
import bangalore_blog_3 from "../img/blog-3.png";
import gurgaon_blog_1 from "../img/blog-4.jpg";
import gurgaon_blog_2 from "../img/blog-5.jpg";
import gurgaon_blog_3 from "../img/blog-6.jpg";
import bangalore_faq from "../img/FAQ.png";
import gurgaon_faq from "../img/faq-2.png";
import {
  freeinsurance,
  freeservice,
  govt,
  homeDel,
  lowPrice,
  serviceCenter,
  wideRange,
  helmate,
  payment,
  freedomIcon,
  bike,
  glamour,
  stisFeedackSmall,
  loveFeedbackSmall,
  sadFeedbackSmall,
} from "../../../assets";
import {
  abtDistance,
  payments,
  assistance,
  insuredRide,
  complimentaryHelmet,
  sanitized,
  productSlider,
} from "../../../assets";

import { WEB_URL } from "../../../config/apiUrls";
console.log(WEB_URL, "WEB_URL");
export const locationPageData = [
  {
    cityId: 20,
    pathname: "/bike-rentals-in-jaipur",
    cityName: "Jaipur",
    top_banner: {
      subHeading: "Two Wheeler Rentals",
      heading: `Bike and Scooty Rentals in Jaipur`,
      text: "Get the best two-wheeler rental solution for your travel needs",
      backgroungImg: productSlider,
    },
    service: {
      heading: "Why You Should Choose Our Freedo Services",
      imgOne: govt,
      imgOneText: "Govt. Authorized Two Wheeler Rentals",
      imgTwo: wideRange,
      imgTwoText: "Wide Range of Vehicles",
      imgThree: freeservice,
      imgThreeText: "Free Service and Maintenance",
      imgFour: lowPrice,
      imgFourText: "Guaranteed Lower Prices",
      imgFive: freeinsurance,
      imgFiveText: "Free Insurance",
      imgSix: homeDel,
      imgSixText: "Home Delivery Available",
    },
    mission: {
      subHeading: "Travel Without Hassle",
      heading: "Freedo - Best Bike Rentals In Jaipur",
      text: `By offering its premium range of bike and scooty on rent in Jaipur, Freedo offers smart, affordable and easily accessible mobility options to riders. Choose the right two-wheeler model for you and travel effortlessly throughout the city.`,
      title: "Experience the freedom of a motorcycle rental at Freedo! ",
      description:
        "Wave goodbye to the challenges of ownership and vehicle maintenance. With Freedo, you're free to enjoy the ride without the burdens of owning.",
      imgOne: freedomIcon,
      imgOneText: "Freedom from \n Ownership Hassles",
      imgTwo: helmate,
      imgTwoText: "Complimentary \n Helmet",
      imgThree: payment,
      imgThreeText: "Instant KYC and \n Payments",
      image: gurgaon_about,
    },
    renting_fleet: {
      heading: "Our Renting Fleet in Jaipur",
    },
    faqs: {
      heading: "Is there anything you need to know? We have got your back!",
      img: gurgaon_faq,
      text: `If you have questions about two-wheeler rental or need help with a booking, our team is here to assist you. You will get answers to some of the most frequently asked questions below. Feel free to connect with us if you do not find what you are looking for.`,
      faq: [
        {
          question: `How to take a bike and scooty on rent in Jaipur through our service?`,
          anaswer: `Renting a two-wheeler in Jaipur with us is easy! Simply visit our website and navigate to the "Two-Wheeler Rental" page. Select your preferred vehicle, pick-up and drop-off dates, and complete the booking process. You can also call our customer support team to assist you with the reservation.`,
        },
        {
          question:
            "What types of two-wheelers do we offer for rent in Jaipur?",
          anaswer: `We take pride in offering a wide range of vehicles for rent. You can choose from a range of scooters, motorbikes, etc. All of which are well-maintained, fuel-efficient, and suited to city commuting or exploring the beautiful sights of Jaipur. You get competitive prices for all our rental vehicles, with flexible rental periods and discounts available for longer rentals. Whatever you need, we have the perfect vehicle for you.`,
        },
        {
          question:
            "Do I need to submit any documents to take bike rentals in Jaipur?",
          anaswer: `Yes, for the rental process, you need to submit a valid government-issued ID (such as Aadhar card, driver's license, or passport). After booking, you will receive a written agreement that details your rental terms and conditions.`,
        },
        {
          question: "Can I take bike rentals in Jaipur for a full day?",
          anaswer: `We offer daily rental options for your convenience. If you need a two-wheeler for an entire day, our flexible rental plans cater to your specific requirements. Our rates are highly competitive and you can rest assured that you'll get the best value for your money. We have a wide selection of bicycles to choose from and our friendly staff will be more than happy to help you select the right one. So, come and take advantage of our rental service today!`,
        },
        {
          question:
            "What happens if the rented two-wheeler breaks down during my rental period?",
          anaswer: `In the rare event of a breakdown, please contact our 24/7 customer support immediately. We will arrange for a replacement vehicle or send assistance to get you back on the road as soon as possible.`,
        },
        {
          question:
            "Can I extend the rental duration after booking a two-wheeler?",
          anaswer: `Yes, you can extend your rental period if needed. To do so, get in touch with our customer support team. They will assist you with the extension process and any additional charges that may apply. To confirm the extension, review it carefully and sign it. After that, you can use the rental vehicle as before.`,
        },
        {
          question: "Will I get a helmet along with the two-wheeler rental?",
          anaswer: `Your safety is our top priority. We provide a complimentary helmet with every two-wheeler rental, ensuring a safe and enjoyable ride during your travel time in Jaipur. Follow the safety precautions to make your journey more comfortable and enjoyable.`,
        },
        {
          question:
            "What should I do if the rented two-wheeler gets damaged during my rental period?",
          anaswer: `In case of any damage to the rented vehicle, please inform our support team immediately. Depending on the extent of the damage, you may be liable for repair costs up to the security deposit amount. We recommend thoroughly inspecting the vehicle before taking scooty on rent in Jaipur and adhering to traffic rules and safety guidelines during your ride.`,
        },
        {
          question:
            "What is our cancellation policy for bike rentals in Jaipur?",
          anaswer: `We understand that plans can change. If you need to cancel your reservation, refer to our website or contact our support team for detailed information or terms and conditions on our cancellation policy.`,
        },
      ],
    },
    blogs: {
      heading: "Latest Blog",
      baneers: [
        {
          img: WEB_URL + "/blog-images/Blog_Thumnails_Jaipur.png",
          heading:
            "Budget-Friendly Bike Rentals in Jaipur: A Comprehensive Guide",
          text: ` Explore affordable bike rental options in Jaipur with our comprehensive guide. Discover the best deals for an exciting and economical journey through the Pink City.`,
          link: "blog/budget-friendly-bike-rentals-jaipur",
        },
        {
          img: WEB_URL + "/blog-images/blog-16.jpg",
          heading:
            "Ride Like Royalty: Jaipur's Top 5 Tourist Places Explored on Freedo's Bike Rentals | Freedo",
          text: `aipur is a dream destination for many travel enthusiasts today. Freedo offers the best services for bike hire in Jaipur that will make your travel in the pink city a life remembering experience.`,
          link: "blog/tourist-places-to-visit-in-Jaipur-with-freedo-bike-rentals",
        },
        {
          img: WEB_URL + "/blog-images/blog-3.jpg",
          heading:
            "Safe and Joyful Journeys: Ensuring Child Pillion Riders' Safety with Freedo Bike Rentals",
          text: `Child pillion riders are in focus as per the latest notification of the Ministry of Road Transport and Highways. From Feb 2023, all children ages 4 yrs and below upto nine months who are riding or being carried pillion on a two-wheeler will need to be in safety gear.`,
          link: "blog/safe-joyful-journeys-child-pillion-safety-freedo-bike-rentals",
        },
      ],
    },
    top_footer: {
      jsxSnippet: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Choose Freedo For Bike Rentals In Jaipur
            </h2>
            <p>
              Traveling wherever you want is the best thing in the world.
              Renting a bike or scooter allows you to do that! Exploring
              Jaipur’s rich heritage and its historical past becomes a pleasant
              journey, and a lot simpler with self-drive bikes or rental
              scooters. It’s difficult to drive a car on narrow roads, so opt
              for bike rentals in Jaipur instead! Freedo is a leading
              two-wheeler rental platform that fills the void in the
              transportation sector. By offering an exclusive range of bike on
              rent in Jaipur, we offset the diverse mobility demands of new-age
              travelers.
            </p>
          </div>
        </>
      ),
      jsxDetails: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Choose Freedo For Bike Rentals In Jaipur
            </h2>
            <p>
              Traveling wherever you want is the best thing in the world.
              Renting a bike or scooter allows you to do that! Exploring
              Jaipur’s rich heritage and its historical past becomes a pleasant
              journey, and a lot simpler with self-drive bikes or rental
              scooters. It’s difficult to drive a car on narrow roads, so opt
              for bike rentals in Jaipur instead! Freedo is a leading
              two-wheeler rental platform that fills the void in the
              transportation sector. By offering an exclusive range of bike on
              rent in Jaipur, we offset the diverse mobility demands of new-age
              travelers.
            </p>

            <p>
              Whether you are a daily commuter or a travel enthusiast, you can
              rely on us for your transportation needs. Offering a wide
              selection of bikes and scooters at affordable prices, we promise
              to make your travel an easy, smooth, and budget-friendly
              experience. So, if you are looking for a premium{" "}
              <b>two wheeler rental in Jaipur</b>, choose Freedo's bike and
              scooter rental services.
            </p>

            <h2 class="about-2-head">
              Freedo Rentals - A One-Stop Platform For Taking Bike Or{" "}
              <b>Scooty on Rent In Jaipur</b>
            </h2>
            <div class="row">
              <div class="col-sm-12">
                <div class="card footer-img float-md-end float-sm-none">
                  <img src={bike} class="card-img-top" alt="Xpulse 200" />
                  <div class="card-body mb-2">
                    <h5>Rent a Scooter/Bike</h5>
                    <span>Start at just</span>
                    <h5>899/day</h5>
                  </div>
                </div>
                <p>
                  Freedo Rentals is the one-stop destination to rent bikes and
                  scooters. If you love biking or care about saving the planet,
                  choose us. We can assist you with all your travel plans while
                  saving you time, money, and the environment. With many scooter
                  or <b>bike rentals in Jaipur</b>, Freedo is rapidly making a
                  name for itself. Providing feasible mobility solutions, we are
                  on our way to becoming an innovative bike and scooter rental
                  company in India.
                </p>
                <p>
                  We are committed to offering riders and commuters the most
                  convenient and affordable transportation solution. Bookings
                  can be made online, through our app, or at any of our
                  locations in Jaipur. Just book your favorite two-wheeler on
                  rent at Freedo and forget about hassles like cabs, autos, and
                  waiting in lines for buses or metros. A hassle-free method of
                  transportation is now easily accessible with our premium range
                  of two-wheeler rentals!
                </p>
                <p>
                  Our bike and scooter rental services eliminate the need to
                  rely on public transport or personal vehicles. Here’s what can
                  you expect from us:
                </p>
                <h3>1. Best deals on bike rentals</h3>
                <p className="ps-2">
                  At Freedo Rentals, we offer the most competitive deals on bike
                  hire in Jaipur. On our two-wheeler rental platform, you will
                  find everything from affordable prices to special discounts.
                  Additionally, you get access to various services, such as home
                  delivery, hassle-free booking, a serviced bike, and a helmet.
                </p>
                <h3>2. Access to a premium range of two-wheeler rentals </h3>
                <p className="ps-2">
                  {" "}
                  As a professional two-wheeler rental company in Jaipur, we
                  offer a comprehensive range of bikes to meet the needs of our
                  customers. Every bike we offer is in excellent condition and
                  sanitized for a comfortable and memorable ride. You can easily
                  book a bike online before arriving in Jaipur if you plan to
                  travel from another city. No matter what your travel needs
                  are, we have something to meet them, from a Royal Enfield
                  Classic 350 to a Pleasure Plus scooter.
                </p>
                <h3>3. Expert assistance </h3>
                <p className="ps-2">
                  Choosing the right bike model for your travel journey can be
                  challenging. Everybody needs assistance while booking bikes
                  and scooters online. We at Freedo Rentals have a team of
                  experts who can help you choose the right bike model based on
                  your travel needs.{" "}
                </p>
                <p className="ps-2">
                  As per the customer's requirements, we provide much-needed
                  assistance with <b>two wheeler rental in Jaipur</b>. You are
                  in the right place if you are planning to travel to Jaipur by
                  bike. Regardless of what your concern is, we will solve it
                  with diligence!
                </p>
                <p>
                  In addition to these offerings, we constantly strive to
                  improve our two-wheeler rental services. With these offerings,
                  we bridge the gap between riders on a daily basis and their
                  lack of access to public transportation. Sort all your travel
                  needs with our premium scooter or{" "}
                  <b>bike rental in Jaipur.</b>{" "}
                </p>
              </div>

              <div>
                <h3>What you will get at Freedo:</h3>
                <div class="d-flex flex-wrap align-items-center mt-3 fetured-row">
                  <div class="featured-icon-wrap">
                    <img src={sanitized} />
                    <span>
                      Sanitized <br />
                      Vehicles
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={complimentaryHelmet} />
                    <span>
                      Complimentary <br />
                      Helmet
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={insuredRide} />
                    <span>
                      Fully Insured <br />
                      Ride
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={assistance} />
                    <span>
                      24 x 7 <br />
                      Assistance
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={payments} />
                    <span>
                      Instant & Secure <br />
                      Payments
                    </span>
                  </div>
                </div>
              </div>

              <div class="abt-detail-content">
                <h3>Understanding your bike rental needs</h3>
                <p>
                  The travel needs of every user differ. Especially, when it
                  comes to outdoor tours, the accessibility to smooth and
                  pocket-friendly travel options grows even more. Freedo
                  fulfills these needs with its two-wheeler rental services. As
                  one of the most reputable bike rental companies in Jaipur, we
                  ensure personalized service and high commitment levels for our
                  riders.{" "}
                </p>
                <p>
                  At Freedo Rentals, we offer a variety of bikes designed to
                  meet the transportation needs of every travel enthusiast. We
                  provide two-wheeler rental services at Freedo Rentals, so if
                  you're looking to rent a bike in Jaipur, take one from us. You
                  can enjoy Jaipur's picturesque surroundings without any hassle
                  by renting our well-maintained bikes. Just choose the bike
                  that perfectly suits your travel needs from our wide range of
                  bike collections!{" "}
                </p>
                <h3>
                  Facilities that make us one of the best service providers for
                  booking a bike on rent in Jaipur{" "}
                </h3>
                <p>
                  At Freedo, you can rent a bike or scooter just for an hour, a
                  day, a week, or a month, depending on your travel needs. In
                  fact, you even get the bike for the entire 24 hours if you
                  rent a bike on a daily basis. Get your favorite vehicle on
                  rent, choose a package, and enjoy it for as many days as you
                  like.{" "}
                </p>
                <p>
                  For us, customer satisfaction and ease of travel are top
                  priorities. We put the best foot forward in ensuring these
                  goals. You can ensure these facilities on our two-wheeler
                  rental platform:{" "}
                </p>
                <ul className="list">
                  <li>Sanitized bikes</li>
                  <li>Excellent condition bike</li>
                  <li>Support assistance</li>
                  <li>Professional guidance</li>
                  <li>Pick-up and drop vehicle service</li>
                  <li>Online bike booking </li>
                  <li>Proper documentation</li>
                  <li>Helmets</li>
                </ul>
                <p>
                  These facilities will surely make your bike rental booking
                  easy, smooth, and seamless. To level up your expectations, we
                  keep on coming up with exclusive deals and discounts. Try out
                  our bike rental service for once. You won’t regret your
                  decision. End your search with Bike rentals in Jaipur at
                  Freedo!{" "}
                </p>
                <p>
                  Ditch your vehicle! Take advantage of our bike rental in
                  Jaipur for your next trip to Jaipur and ease your journey.
                  Feel free to reach out to us if you have questions or would
                  like more information about our two-wheeler rental services.
                  Our experienced staff member will explain everything in a
                  clear and informative manner about a quick bike on rent in
                  Jaipur.
                </p>
              </div>
            </div>
          </div>
        </>
      ),
    },
    metaData: {
      title: "Best Scooty & Bike Rentals in Jaipur Starting at Rs. 249",
      description:
        "Freedo Rentals offers scooter & bike rentals in Jaipur starting at Rs. 249. Explore the city with two-wheelers at affordable daily, weekly, and monthly packages from the best-rated two-wheeler rental app.",
      keywords:
        "Bike Rentals in Jaipur, Scooty on rent in Jaipur, Bike on Rent in Jaipur",
    },
  },
  {
    cityId: 14,
    pathname: "/bike-rentals-in-gurgaon",
    cityName: "Gurugram",
    top_banner: {
      subHeading: "Two Wheeler Rentals",
      heading: "Bike and Scooty Rentals In Gurgaon",
      text: "Get the best two-wheeler rental solution for your travel needs",
      backgroungImg: productSlider,
    },
    service: {
      heading: "Why You Should Choose Our Freedo Services",
      imgOne: govt,
      imgOneText: "Govt. Authorized Two Wheeler Rentals",
      imgTwo: wideRange,
      imgTwoText: "Wide Range of Vehicles",
      imgThree: freeservice,
      imgThreeText: "Free Service and Maintenance",
      imgFour: lowPrice,
      imgFourText: "Guaranteed Lower Prices",
      imgFive: freeinsurance,
      imgFiveText: "Free Insurance",
      imgSix: homeDel,
      imgSixText: "Home Delivery Available",
    },
    mission: {
      subHeading: "Travel Without Hassle",
      heading: "Freedo - Best Bike Rentals In Gurgaon",
      text: `By offering its premium range of bike and scooty on rent in Gurgaon, Freedo offers smart, affordable and easily accessible mobility options to riders. Choose the right two-wheeler model for you and travel effortlessly throughout the city.`,
      title: "Experience the freedom of a motorcycle rental at Freedo! ",
      imgOne: freedomIcon,
      imgOneText: "Freedom from \n Ownership Hassles",
      imgTwo: helmate,
      imgTwoText: "Complimentary \n Helmet",
      imgThree: payment,
      imgThreeText: "Instant KYC and \n Payments",
      image: gurgaon_about,
    },
    renting_fleet: {
      heading: "Our Renting Fleet in Gurgaon",
    },
    faqs: {
      heading: "Is there anything you need to know? We have got your back!",
      img: gurgaon_faq,
      text: `If you have questions about two-wheeler rental or need help with a booking, our team is here to assist you. You will get answers to some of the most frequently asked questions below. Feel free to connect with us if you do not find what you are looking for.`,
      faq: [
        {
          question: `What if I don't get the vehicle after booking?`,
          anaswer: `Our bike rentals in Gurgaon are always available in real time, so this is a rare occurrence. Bikes may be involved in incidents from time to time. You will receive an alternate bike in such a case (based on availability, of course). We will refund you 100% of the rental amount in the shortest time if an alternate bike isn't available or if you don't want to rent an alternate bike.
                `,
        },
        {
          question:
            "Are there any chances of cancellation of bike rentals at the last minute? If yes, will I get my refund?",
          anaswer: `It is our goal to make the booking process as hassle-free as possible. While booking bike rentals in Gurgaon, the likelihood of us canceling your reservation is extremely low. On the other hand, if it happens due to an emergency, you will receive a refund of 100% of what you paid. Stay assured! You will get a smooth bike rental booking experience at Freedo.`,
        },
        {
          question: "Is a complementary helmet provided with bike rentals?",
          anaswer: `Whenever you ride a bike, you must wear a helmet. Helmets are an essential bike accessory. We place the highest priority on the safety of our riders at Freedo. With every bike reservation, we provide a complimentary helmet. All models of bike rentals in Gurgaon come with a complimentary helmet, regardless of the model you select. Book your bike rental today!`,
        },
        {
          question: "Are there any penalties if I return the bike late?",
          anaswer: `Freedo believes in offering the best bike rentals in Gurgaon. In the event that the bike is not returned by the due date, you will be charged a penalty. If the bike is returned after the due date, our dealer and other customers scheduling reservations for the bike may be inconvenienced. You can request an extension from our executives before the end of your package if you think you will be late.`,
        },
        {
          question:
            "Do I need to submit the original documents while booking bike rentals in Gurgaon?",
          anaswer: `You must provide an original driving license (a learner's license is not accepted) and any original government identification proof at the time of booking the vehicle. We require these documents when you book our bike rentals in Gurgaon.
                \n
                Our platform allows you to book your favorite two-wheeler rental online as soon as you provide these documents and complete the registration process.`,
        },
        {
          question:
            " What is the location for picking up and dropping off the vehicle?",
          anaswer: ` For bike rentals in Gurgaon, we have multiple pick-up and drop-off locations. If you wish to pick up your vehicle from any location, you must return it to the same location. When booking a bike rental online, you have the option to choose the delivery location for your bike rental. We will deliver the vehicle to your preferred location as soon as possible.`,
        },
        {
          question: "How can I rent bike in Gurgaon at Freedo?",
          anaswer: `At Freedo, you get the best bike or scooter on rent in Gurgaon. Our two-wheeler rental platform gives you access to the latest bike models at affordable rates. All you have to do is complete the registration process at our two-wheeler rental platform. You can then browse the models, select a two-wheeler of your choice, and make an easy online payment, and your bike will be delivered to you. 
                \n
                You can also download our App to book bicycle and scooter rentals quickly and easily. `,
        },
      ],
    },
    blogs: {
      heading: "Latest Blog",
      baneers: [
        {
          img: WEB_URL + "/blog-images/blog-18.jpg",
          heading: "Finding the perfect bike rental company for your next ride",
          text: `Traveling in cars or public transport is a bit of a hassle, especially if you are driving in the busy streets of cities like Delhi. `,
          link: "blog/finding-the-perfect-bike-rental-company-for-your-next-ride",
        },
        {
          img: WEB_URL + "/blog-images/blog-17.jpg",
          heading: "Get Hero MotoCorp Bikes on Rent",
          text: `Want to ride safely across the city without taking any load of vehicle maintenance? Well, take the help of two-wheeler rentals!`,
          link: "blog/get-hero-motoCorp-bikes-on-rent",
        },
        {
          img: WEB_URL + "/blog-images/blog-2.jpg",
          heading:
            "Ride Beyond Boundaries: Freedo's Exclusive Bike Rentals for a Hassle-Free Journey in Delhi NCR",
          text: `Every time you think of traveling to a dream destination or making your daily commute a bit easy, the idea of opting for a two-wheeler rental`,
          link: "blog/bike-rentals-for-a-hassle-free-journey-in-delhi-ncr",
        },
      ],
    },
    top_footer: {
      jsxSnippet: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Freedo Offers Premium Bike Rentals In Gurgaon
            </h2>
            <p>
              Freedo Rentals is a leading two-wheeler rental company that offers
              the latest models of bikes and <b>scooty on rent in Gurgaon.</b>{" "}
              Dedicated to making your travel easy, smooth, and seamless, we
              offer personalized service and assistance when needed. We're the
              right place for you if you're passionate about riding different
              bikes or other two-wheelers! At Freedo, you get competitive
              pricing and top-quality vehicles for an exquisite travel
              experience.
            </p>
          </div>
        </>
      ),
      jsxDetails: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Freedo Offers Premium Bike Rentals In Gurgaon{" "}
            </h2>
            <p>
              Freedo Rentals is a leading two-wheeler rental company that offers
              the latest models of bikes and <b>scooty on rent in Gurgaon.</b>{" "}
              Dedicated to making your travel easy, smooth, and seamless, we
              offer personalized service and assistance when needed. We're the
              right place for you if you're passionate about riding different
              bikes or other two-wheelers! At Freedo, you get competitive
              pricing and top-quality vehicles for an exquisite travel
              experience.
            </p>

            <p>
              Our wide range of scooters, bikes, and motorcycles will fulfill
              every requirement and specification. We can fulfill your distant
              dreams of hassle-free journeys with a customer-oriented approach.
              Get a bike or scooter on an hourly, daily, weekly, or monthly
              rental plan. With our daily bike rental plans, you even get the
              bike for 24 hours. Hire your favorite vehicles on rent, choose a
              package, and enjoy a ride for as many days as you want.
            </p>

            <h2 class="about-2-head">
              Book your favorite <b>scooty on rent in Gurgaon</b> at Freedo!
            </h2>
            <div class="row">
              <div class="col-sm-12">
                <div class="card footer-img float-md-end float-sm-none">
                  <img src={bike} class="card-img-top" alt="Xpulse 200" />
                  <div class="card-body mb-2">
                    <h5>Rent a Scooter/Bike</h5>
                    <span>Start at just</span>
                    <h5>499/day</h5>
                  </div>
                </div>
                <p>
                  Trips are what riders live for. We follow this philosophy in
                  our business. We provide the latest range of two-wheelers on
                  rent to fulfill your desire for convenient and affordable
                  travel. Since customer satisfaction is our top priority, we
                  constantly upgrade our online two-wheeler rental store to
                  provide our consumers with the most recent vehicles. Rent a
                  vehicle of choice and embark on the trip you have always
                  dreamed of.
                </p>
                <p>
                  If you are looking for premium <b>bike rentals in Gurgaon</b>,
                  reach out to Freedo Rentals. It will meet your expectations.
                  We have gained customers' trust and reliance through our
                  incredible deals and perfect offers on two-wheeler rentals.{" "}
                </p>
                <h3>
                  <b>Rent scooty in Gurgaon</b> and ease your travel hassle{" "}
                </h3>
                <p>
                  The travel demands of commuters are diversifying day by day.
                  They look for a smart, affordable, and accessible mobility
                  option to make their travel plans truly memorable experiences.
                </p>
                <p>
                  Freedo makes this dream possible with its diverse range of{" "}
                  <b>scooty on rent in Gurgaon</b>. We are committed to ensuring
                  our customers are satisfied with our bike and scooter
                  services. With our personalized service, we have built a
                  reputation as the most reliable bike and scooter rental
                  company.
                </p>
              </div>

              <div>
                <h3>What you will get at Freedo:</h3>
                <div class="d-flex flex-wrap align-items-center mt-3 fetured-row">
                  <div class="featured-icon-wrap">
                    <img src={sanitized} />
                    <span>
                      Sanitized <br />
                      Vehicles
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={complimentaryHelmet} />
                    <span>
                      Complimentary <br />
                      Helmet
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={insuredRide} />
                    <span>
                      Fully Insured <br />
                      Ride
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={assistance} />
                    <span>
                      24 x 7 <br />
                      Assistance
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={payments} />
                    <span>
                      Instant & Secure <br />
                      Payments
                    </span>
                  </div>
                </div>
              </div>

              <div class="abt-detail-content">
                <p>
                  Additionally, we contribute to a sustainable future by
                  offering a range of self-driving two-wheelers for rent that
                  are non-exclusive. We excel at motorcycle, car, and bicycle
                  rental services across the country. With our bike and scooter
                  rental services, there is no need to depend on public
                  transport or a personal vehicle. Here at Freedo, motorcycle
                  enthusiasts and roadies alike will find a long-desired
                  getaway.
                </p>
                <h3>
                  Why are Freedo<b> bike rentals in Gurgaon</b> a better commute
                  option?{" "}
                </h3>
                <p>
                  It is not possible to travel with your personal vehicle
                  everywhere. The on-time maintenance and servicing of these
                  vehicles are one of the major concerns. Nowadays, renting a
                  bike or scooter makes so much sense. In addition to their
                  convenience, they are an inexpensive, seamless, and easy way
                  to commute.
                </p>
                <p>
                  Among urban and suburban residents, bike and scooter rentals
                  are considered the most efficient mode of transportation. In
                  addition to not consuming fuel, bicycles leave no
                  environmental footprint. Also, they can significantly cut down
                  on urban pollution. In fact, driving a bike rental is a
                  wonderful way to beat stress by going through twisties, while
                  listening to your favorite music in the background. When it
                  comes to a cost-effective commute, we have your needs covered.
                  We offer bike rental options that are less expensive for short
                  commutes to the office or short rides around town. Rather than
                  paying more for cabs, why not rent a bike at a 10x cheaper
                  rate?
                </p>
                <p>
                  At Freedo, you can <b>rent scooty in Gurgaon</b> for your
                  dream destination or everyday travel. Our range of bikes
                  includes Pleasure Plus, Maestro Edge 110, Passion Pro/Xpro,
                  Splendor I Smart, Glamour, Splendor+, Super Splendor, Maestro
                  Edge 125, Destini 125, Xtreme 200S, Xtreme 160R, Xpulse 200,
                  Xpulse 200T, HF Deluxe, Classic 350, Electric Scooter,
                  Pleasure Plus Xtec, Xpulse 200 4V, Xtreme 160 R Stealth and
                  many more to give you a choice to pick your favorite bike. You
                  can select a two-wheeler rental depending on your budget and
                  preferences.
                </p>
                <h3>
                  Ensure seamless travel within a city with Freedo{" "}
                  <b>bike rentals in Gurgaon</b>
                </h3>
                <p>
                  There's nothing better than the freedom to travel wherever you
                  want. Renting a bike or scooter gives you that opportunity!
                  Using Freedo <b>bike rentals in Gurgaon</b>, you can explore
                  the city with freedom, flexibility, and comfort. In other
                  words, renting a two-wheeler rental is always a great idea if
                  you want to explore uncharted territory and go off the beaten
                  path.
                </p>
                <h3> Here are some of the things you can expect from us:</h3>
                <h3>1. Accessibility to a wide range of two-wheelers </h3>
                <p>
                  Renting a two-wheeler means riding on new wheels almost every
                  time. You don't have to worry about maintaining your vehicle
                  or paying EMIs. You get to try something new. Our extensive
                  selection of exclusive bikes, cars, and bicycles allows riders
                  to select the right bike, car, or bicycle that meets their
                  budget.
                </p>
                <h3>2. Flexibility to pick the vehicle of your choice </h3>
                <p>
                  Traveling by bike is convenient, so you can rent one and take
                  a more straightforward route. As a bonus, it's always fun to
                  see your riding buddies' faces when you roll in on a new set
                  of wheels. Take a bike or <b>scooty on rent in Gurgaon</b> and
                  head out to your desired location at any time without any care
                  or worry about the cost of maintenance.
                </p>
                <h3>3. Budget-friendly travel option </h3>
                <p>
                  To travel within a budget, you need to invest in something
                  that won't cost you a fortune. It is more cost-effective to
                  rent a scooter or a bike instead of purchasing one. This makes
                  traveling a lot easier! Freedo Rentals ensures this goal by
                  offering an impressive range of two-wheelers on rent. You just
                  have to book a bike of your choice, make an online payment,
                  and voila, the vehicle will be delivered to your preferred
                  location on time.
                </p>
                <h3>
                  Fulfill your travel demands by hiring the best{" "}
                  <b>bike rentals in Gurgaon</b>!{" "}
                </h3>
                <p>
                  Freedo fulfills the mobility demand of travelers these days
                  with its range of two-wheeler rentals. By maintaining the
                  highest professionalism and quality, we meet your mobility
                  needs with expertise! We have multiple locations in a city.
                  Therefore, you can search for a rental scooty near me on
                  search engines or your map application if you want to find a
                  nearby bike.
                </p>
                <p>
                  You can now book bikes hassle-free at your fingertips. Our
                  website is quite user-friendly to{" "}
                  <b>rent scooty in Gurgaon</b>. With just a few clicks, you can
                  book your favorite two-wheeler, a Scooty, or a Bullet. At a
                  fraction of the cost, you can access the latest bike models on
                  the market. Ditch your regular vehicle! Rent a bike at Freedo
                  Rentals and make your travel easy, smooth, and convenient!
                </p>
                <p>
                  Our customers have shared beautiful stories about their
                  rentals with us throughout our history. With Freedo Rentals,
                  you can rent a bike with confidence. We have a lot more in
                  store in the future. Just stay tuned to our latest offers!
                </p>
                <p>
                  In case of any queries or information know-how for two-wheeler
                  rental booking, feel free to reach out to our team. We will
                  try our best to sort them out.
                </p>
              </div>
            </div>
          </div>
        </>
      ),
    },
    metaData: {
      title: "Best Scooty & Bike Rentals in Gurgaon Starting at Rs. 399",
      description:
        "Freedo Rentals offers scooter & bike rentals in Gurgaon starting at Rs. 399. Explore the city with two-wheelers at affordable daily, weekly, and monthly packages from the best-rated two-wheeler rental app.",
      keywords:
        "Bike Rentals in Gurgaon, Scooty on rent in Gurgaon, Bike on Rent in Gurgaon",
    },
  },
  {
    cityId: 7,
    pathname: "/bike-rentals-in-bangalore",
    cityName: "Bengaluru",
    top_banner: {
      subHeading: "Two Wheeler Rentals",
      heading: " Bike and Scooter Rentals in Bangalore",
      text: "Get the best two-wheeler rental solution for your travel needs",
      backgroungImg: productSlider,
    },
    service: {
      heading: "Why You Should Choose Our Freedo Services",
      imgOne: govt,
      imgOneText: "Govt. Authorized Two Wheeler Rentals",
      imgTwo: wideRange,
      imgTwoText: "Wide Range of Vehicles",
      imgThree: freeservice,
      imgThreeText: "Free Service and Maintenance",
      imgFour: lowPrice,
      imgFourText: "Guaranteed Lower Prices",
      imgFive: freeinsurance,
      imgFiveText: "Free Insurance",
      imgSix: homeDel,
      imgSixText: "Home Delivery Available",
    },
    mission: {
      subHeading: "Enjoy Hassle-Free Travel",
      heading: "Freedo - Best Bike And Scooter Rentals In Bangalore",
      text: `Freedo introduces smart, affordable and easily accessible mobility options by offering its premium
        range of two-wheeler on rent in Bangalore. Travel effortlessly within the city by choosing
        the model of your choice.`,
      title: "Rent a motorcycle and enjoy the freedom it brings!",
      imgOne: freedomIcon,
      imgOneText: "Freedom from \n Ownership Hassles",
      imgTwo: helmate,
      imgTwoText: "Complimentary \n Helmet",
      imgThree: payment,
      imgThreeText: "Instant KYC and \n Payments",
      image: bangalore_about,
    },
    renting_fleet: {
      heading: "Our Renting Fleet in Bangalore",
    },
    faqs: {
      heading: "Do you have questions? We've got you covered!",
      img: bangalore_faq,
      text: `Whether you need help with a two-wheeler rental booking or have a question, our team is here to
        help you. See some of the most frequently asked questions below. If you still cannot find what you
        need, please let us know.`,
      faq: [
        {
          question: "How to book our bike rentals in Bangalore?",
          anaswer: `Well, you have landed at the right place for booking the best bike rentals in
                Bangalore. Our two-wheeler rental platform gives you access to the latest range of
                bike models at affordable rates. Just complete the registration process. Once done, go
                through the models, select a bike of your choice, and make an easy online payment, and
                voila, your two-wheeler will be delivered to your preferred location.\n
                For faster and easier booking bike and scooter rentals, you can download our App. Please
                              feel free to reach out to our customer support team if you have any questions or concerns
                              during the bike rental booking process.
                `,
        },
        {
          question: "Do Freedo Rentals give helmets with vehicles?",
          anaswer: ` For Freedo Rentals, the safety and security of its riders matter the most. In fact, we are
                known for our best-in-class safety standards in our two-wheeler rental services. You will
                get a complimentary helmet with every bike and scooter rental. However, all other
                accessories and gear must be purchased independently by the rider.\n

                Keeping the COVID situation in mind, we take necessary precautions while delivering
                scooter and bike rentals in Bangalore. You will get a safe and sanitized vehicle at
                every booking! Enjoy your ride within the city hassle-free with Freedo two-wheeler rental
                services. Connect with us for further queries.`,
        },
        {
          question:
            "What are the documents required for online two-wheeler rental booking?",
          anaswer: `Booking bike and scooter rentals at Freedo is quite easy. All you have to do is provide
                your original driving license (a learner license is not applicable) and submit any
                original government ID proof by the end of the booking.
                best bike
                rentals in Bangalore, make sure you have these documents. \n

                Once you provide these documents and complete the registration procedure, you can continue
                with the online booking of your favorite two-wheeler rental on our platform. In case of
                any queries, you can connect with us. Our customer support team will take all your
                concerns with expertise.`,
        },
        {
          question:
            "Why is Freedo the best platform to book 2-wheeler rentals in Bangalore?",
          anaswer: (
            <>
              {" "}
              Freedo offers a smart, affordable, and accessible mobility option
              to its riders by coming with a wide range of two-wheeler rentals.
              On this platform, you get access to a variety of bikes like
              Classic 350, Xpulse 200, Xtreme 160R Stealth, and a lot more. With
              Freedo bike and scooter rentals in Bangalore, you get:
              <ul style={{ margin: "20px" }}>
                <li>The Freedom to choose the bike of your choice</li>
                <li>No more hassles of ownership of the vehicle</li>
                <li>
                  Free monthly service and maintenance with doorstep vehicle
                  delivery{" "}
                </li>
                <li>Affordable packages on two-wheeler rentals </li>
              </ul>
              If you want to ease your travel journey, you must book your bike
              rentals at Freedo!
            </>
          ),
        },
        {
          question:
            "What locations are available for picking up and dropping off the vehicle?",
          anaswer: `We have multiple locations for the pick-and-drop facility for bike rentals in
                Bangalore. Your vehicle can be picked up from any location you prefer, but it must
                be returned to the same location as it was picked up. \n

                If you book a bike rental online, just select the preferred location for delivery of your
                bike rental while making payment. The vehicle will be delivered to you at the soonest time
                possible. We would suggest you select a pick up location according to your own
                convenience. If you still have queries, you can reach out to our customer support team for
                better guidance.`,
        },
        {
          question: "What type of two-wheelers does Freedo offers?",
          anaswer: `Freedo comes with an impressive range of bike and scooter rentals. On our two-wheeler
                rental platform, you get Maestro Edge 110, Destini 125, Xtreme 200S, HF Deluxe, Pleasure
                Plus Xtec, Xtreme 160R Stealth, Glamour, Splendor I smart, Classic 350, and many other
                models. These two-wheelers models can cater to the diverse mobility demands of today's
                travel enthusiasts.
                \n

                You can choose the model based on your travel demands and ease your journey to a great
                extent. Explore our range of two-wheelers, pick the bike model of your choice, make an
                online payment, and enjoy your ride within the city. What are you waiting for? Book your
                bike rental now!`,
        },
        {
          question: "What is the cost of our two-wheeler rental services?",
          anaswer: `The cost of our two-wheeler rentals depends from model to model. Some bike models are
                available at low-cost. While some may be on a higher rental price. We provide hourly,
                daily, weekly,
                or monthly bike rentals in Bangalore.A 24-hour rental plan is also
                available!\n

                Therefore, before booking a bike rental, you must explore our range of two-wheelers and
                choose the model that fits your travel demands and budget. Pick the vehicle of your
                choice, select the package, and enjoy the ride for as many days as you want. In case of
                further assistance, connect with our customer support team.`,
        },
      ],
    },
    blogs: {
      heading: "Latest Blog",
      baneers: [
        {
          img: WEB_URL + "/blog-images/blog-11.jpg",
          heading:
            "Ride into Nature's Embrace: Unveiling the Best Hill Stations via Bike Rentals in Bangalore",
          text: `Bike trips are a dream of every traveler. What can be a better ride than exploring mother nature and experiencing beautiful scenarios of hill stations?! It’s the reason why the demand for two-wheelers is continuously rising among riders.`,
          link: "blog/best-hill-stations-to-visit-by-renting-a-bike-in-bangalore",
        },
        {
          img: WEB_URL + "/blog-images/blog-10.jpg",
          heading:
            "Unleash the Thrill: Bangalore Bike Rentals for the Ultimate Road Trip Adventure",
          text: `The bike or any two-wheeler ride experience creates the best travel memories. The vibe hits
            differently when you feel the beauty of Bangalore`,
          link: "blog/bangalore-bike-rentals-adventures",
        },
        {
          img: WEB_URL + "/blog-images/BlogThumnailsBangalore-02.jpg",
          heading:
            "Explore Bangalore's Scooty Charm: Rent for a Fun RideUnleash the Thrill: Bangalore Bike Rentals for the Ultimate Road Trip Adventure",
          text: ` Discover the joy of cruising through Bangalore on a rented scooty. Find the best rental spots for an exciting and carefree city exploration.`,
          link: "blog/rent-scooty-in-bangalore-for-fun",
        },
      ],
    },
    top_footer: {
      jsxSnippet: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Freedo Scooter and Bike Rentals in Bangalore
            </h2>
            <p>
              India's third most populous city is Bangalore, the state capital
              of Karnataka. This cosmopolitan city is also known as the 'IT
              Capital' and serves a wide range of people from diverse
              backgrounds. Aside from its exuberance and busy lifestyle, the
              city is also known for its high traffic. Therefore, the concept of
              renting two-wheelers makes so much sense. If you are on the road,
              moving through the gaps of what can be an hour-long traffic,you
              will be glad that you rented a bike/scooter. It's a reasonable and
              exciting way to commute in the city!
            </p>
          </div>
        </>
      ),
      jsxDetails: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Freedo Scooter and Bike Rentals in Bangalore
            </h2>
            <p>
              India's third most populous city is Bangalore, the state capital
              of Karnataka. This cosmopolitan city is also known as the 'IT
              Capital' and serves a wide range of people from diverse
              backgrounds. Aside from its exuberance and busy lifestyle, the
              city is also known for its high traffic. Therefore, the concept of
              renting two-wheelers makes so much sense. If you are on the road,
              moving through the gaps of what can be an hour-long traffic,you
              will be glad that you rented a bike/scooter. It's a reasonable and
              exciting way to commute in the city!
            </p>

            <p>
              With Freedo Monthly Bike Rental in Bangalore, you will be able to
              ride throughout the city at a much lower cost. In addition, you
              won't have to deal with the headaches that come with owning a
              bike. Whether you are heading out on a small trip or want to
              venture out for a long ride, our range of two-wheeler rentals will
              perfectly suit your travel needs.
            </p>
            <p>
              For ardent riders, bike or scooter rental in Bangalore is a
              blessing. Several different types of bikes are available, from
              economical models to medium-powered bikes to cruisers to seriously
              powerful superbikes like the Classic 350.
            </p>
            <p>
              Looking for the best Bike Rental Apps in Bangalore? Freedo is the
              right choice for all your commutation needs!
            </p>

            <h2 class="about-2-head">
              Hire a two-wheeler on rent in Bangalore with confidence at Freedo!
            </h2>
            <div class="row">
              <div class="col-sm-12">
                <div class="card footer-img float-md-end float-sm-none">
                  <img src={bike} class="card-img-top" alt="Xpulse 200" />
                  <div class="card-body mb-2">
                    <h5>Rent a Scooter/Bike</h5>
                    <span>Start at just</span>
                    <h5>749/day</h5>
                  </div>
                </div>
                <p>
                  Taking a bike ride or riding a two-wheeler is the most fun way
                  to create travel memories. You get a completely different vibe
                  when you see the scenic views of Bangalore and feel its
                  beauty. If you want to explore the best of Bangalore, a
                  scooter rental in Bangalore is all that you need.
                </p>
                <p>
                  Two-wheeler rentals are an easy, hassle-free, and affordable
                  alternative to owning a vehicle, as they do not require huge
                  investments. Aside from that, you won't have to worry about
                  maintenance and insurance. Whenever you want, you can travel
                  anywhere without being dependent on anyone! We at Freedo
                  Rentals endeavor to deliver the best to the customer and serve
                  beyond expectation by offering Monthly Bike Rental in
                  Bangalore.
                </p>
                <p>
                  Wouldn't it be nice to be free of Bangalore's daily traffic
                  and just travel wherever you want? Visiting the Freedo website
                  or picking up a bike from our nearest two-wheeler rental store
                  would be a smart place to start! As a company, we work hard to
                  make travel safer and more convenient. In comparison to costly
                  cab rides and taxis, our scooter rental in Bangalore provides
                  you with exciting packages and additional privileges.
                </p>
              </div>
              <div>
                <h3>What you will get at Freedo:</h3>
                <div class="d-flex flex-wrap align-items-center mt-3 fetured-row">
                  <div class="featured-icon-wrap">
                    <img src={sanitized} />
                    <span>
                      Sanitized <br />
                      Vehicles
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={complimentaryHelmet} />
                    <span>
                      Complimentary <br />
                      Helmet
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={insuredRide} />
                    <span>
                      Fully Insured <br />
                      Ride
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={assistance} />
                    <span>
                      24 x 7 <br />
                      Assistance
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={payments} />
                    <span>
                      Instant & Secure <br />
                      Payments
                    </span>
                  </div>
                </div>
              </div>

              <div class="abt-detail-content">
                <p>
                  In addition to these facilities, we offer customer-friendly,
                  passionate, and honest bike rentals in Bangalore with minimal
                  processing steps, easy document verification, and convenient
                  pick-ups and drops. Changing riders' perceptions and promoting
                  their true passion is our goal.
                </p>
                <h2>Benefits of taking Freedo bike rentals in Bangalore!</h2>
                <p>
                  Bangalore is one of the best cities in the world for
                  travelers. Two-wheelers are the most convenient way to explore
                  the coolest places here! Taking scooters or bikes for rent in
                  Bangalore doesn't require a lot of money. Freedo two-wheeler
                  rentals will make your travel easy, smooth, and affordable.
                  Choose this travel option and tap these benefits:
                </p>

                <h2>Flexibility to travel wherever you want</h2>
                <p>
                  No matter what your travel plans are, a bike is a reliable
                  travel companion, whether you want to take the same route
                  every day or explore the city more. Nevertheless, not everyone
                  can afford a two-wheeler. These problems can be solved with
                  the best bike rentals in Bangalore.
                </p>
                <p>
                  In addition, Freedo has one of the best bike rental apps in
                  Bangalore where you can rent a bike and enjoy riding in the
                  city without having to worry about ownership. You don't even
                  have to worry about maintenance or pending EMIs.{" "}
                </p>

                <h2> A wide range of two-wheeler rentals </h2>
                <p>
                  Getting a scooter rental in Bangalore saves you from the
                  setbacks of vehicle maintenance. From Pleasure Plus to Royal
                  Enfield Classic 350, you get access to a variety of bikes at
                  Freedo.
                </p>
                <p>
                  We offer hourly, daily, weekly, and monthly bike rental in
                  Bangalore. You can even opt for a 24-hour rental plan!
                  According to your budget and preferences, you can choose
                  high-quality and well-maintained bikes. Just pick your
                  favorite vehicle, select a package, and enjoy the ride for as
                  many days as you want.
                </p>

                <h2>Budget-friendly travel within the city</h2>
                <p>
                  Traveling outside your city requires you to take care of your
                  own vehicle. When you're heading out on road trips, it's a
                  wise idea to take a two-wheeler rent in Bangalore. The cost is
                  lower than owning one. A two-wheeler rental offers you the
                  opportunity to try something new in the city, whether you want
                  to experience the top attractions of Bangalore or commute to
                  work each day.
                </p>
                <p>
                  What’s the point of investing your hard-earned money in a bike
                  or scooter that will lose its value in a few years? Explore
                  the must-see places in the city hassle-free with a scooter
                  rental in Bangalore!
                </p>

                <h2>
                  Connect with Freedo to get the best bike rentals in Bangalore
                </h2>
                <p>
                  There is a lot to see and do in the city for travelers. You
                  can make the most of your next Bangalore road trip if you know
                  a few helpful places and have the right vehicle. You are
                  probably making the right decision if you plan on renting a
                  bike or scooter while on vacation.
                </p>
                <p>
                  You’ll have a lot of fun renting a two-wheeler because it’s an
                  easy, convenient, and stress-free travel option. Freedo
                  Rentals allows you to get up and move whenever you want,
                  whether it's for everyday travel or a road trip. With our
                  bike/scooter rental in Bangalore, you can explore this city
                  without relying on anyone else
                </p>
                <p>
                  For seamless or faster two-wheeler rental booking, we come
                  with bike rental apps in Bangalore. Download it to get
                  exclusive deals!
                </p>
                <p>
                  Give our Bike Rentals in Bangalore a try. Send us an email,
                  call us, or use our website to submit an inquiry. Creating a
                  safe, passionate, and happy riding community is what we love
                  to do!{" "}
                </p>
                <p>
                  For Monthly Bike Rental in Bangalore, contact Freedo today!
                </p>
              </div>
            </div>
          </div>
        </>
      ),
    },
    metaData: {
      title: "Best Scooty & Bike Rentals in Bangalore Starting at Rs. 299",
      description:
        "Freedo Rentals offers scooter & bike rentals in Bangalore starting at Rs. 299. Explore the city with two-wheelers at affordable daily, weekly, and monthly packages from the best-rated two-wheeler rental app.",
      keywords:
        "Bike Rentals in Bangalore, Scooty on rent in Bangalore, Bike on Rent in Bangalore",
    },
  },
  {
    cityId: 24,
    pathname: "/bike-rentals-in-vadodara",
    cityName: "Vadodara",
    top_banner: {
      subHeading: "Two Wheeler Rentals",
      heading: `Bike and Scooty Rentals in Vadodara`,
      text: "Get the best two-wheeler rental solution for your travel needs",
      backgroungImg: productSlider,
    },
    service: {
      heading: "Why You Should Choose Our Freedo Services",
      imgOne: govt,
      imgOneText: "Govt. Authorized Two Wheeler Rentals",
      imgTwo: wideRange,
      imgTwoText: "Wide Range of Vehicles",
      imgThree: freeservice,
      imgThreeText: "Free Service and Maintenance",
      imgFour: lowPrice,
      imgFourText: "Guaranteed Lower Prices",
      imgFive: freeinsurance,
      imgFiveText: "Free Insurance",
      imgSix: homeDel,
      imgSixText: "Home Delivery Available",
    },
    mission: {
      subHeading: "Travel Without Hassle",
      heading: "Freedo - Best Bike Rentals In Vadodara",
      text: `Get to know the city like a local with Freedo's premium Bike Rentals in Vadodara. We aim to redefine your city exploration with affordable, stylish, and convenient mobility solutions.`,
      title: "Experience the freedom of a motorcycle rental at Freedo!",
      description:
        "Wave goodbye to the challenges of ownership and vehicle maintenance. With Freedo, you're free to enjoy the ride without the burdens of owning.",
      imgOne: freedomIcon,
      imgOneText: "Freedom from Ownership Hassles",
      imgTwo: helmate,
      imgTwoText: "Complimentary Helmet",
      imgThree: payment,
      imgThreeText: "Instant KYC and Payments",
      image: gurgaon_about,
    },
    renting_fleet: {
      heading: "Our Renting Fleet in Vadodara",
    },
    faqs: {
      heading: "Is there anything you need to know? We have got your back!",
      img: gurgaon_faq,
      text: `If you have questions about two-wheeler rental or need help with a booking, our team is here to assist you. You will get answers to some of the most frequently asked questions below. Feel free to connect with us if you do not find what you are looking for.`,
      faq: [
        {
          question: `How do I rent a two-wheeler in Vadodara with Freedo?`,
          anaswer: `Getting a scooty on rent in Vadodara with Freedo is a simple process. Visit our website or download our app. Browse our diverse range of vehicles. Then select your preferred ride. Specify the rental duration and confirm your booking. You'll receive a booking confirmation along with pickup instructions. After your rental period, return the vehicle to the designated drop-off point. It's that easy!
          `,
        },
        {
          question: "What types of two-wheelers can I rent in Vadodara?",
          anaswer: `We offer a range of options to match your travel needs. Our fleet includes traditional bikes on rent in Vadodara, nimble scooters for easy city navigation, and eco-friendly electric bikes in Vadodara. Our dedicated team is also available to provide any necessary assistance.`,
        },
        {
          question: "Is it possible to rent bikes in Vadodara?",
          anaswer: `Absolutely! Freedo provides bike rentals in Vadodara, allowing you to experience the convenience and eco-friendliness of electric mobility while exploring the city. Safety is paramount, and we supply helmets and other protective gear with our bike on rent in Vadodara. This is to ensure your well-being. Join us in contributing to a cleaner Vadodara!
          `,
        },
        {
          question: "Are your rental rates affordable in Vadodara?",
          anaswer: `Yes, our rental rates are competitively priced and designed to be budget-friendly. You can find detailed pricing information for bike and scooter rentals on our website or app. We offer discounts for longer rental durations and specific payment methods. Additionally, we have flexible payment plans to cater to your specific needs. Feel free to contact us for any questions or to discuss your rental requirements.`,
        },
        {
          question:
            "What documents do I need to rent a two-wheeler in Vadodara?",
          anaswer: `Typically, you'll need a valid driver's license, a government-issued ID, and, in some cases, an Aadhar card or passport to rent a two-wheeler in Vadodara. If you have any queries, our customer support team is available to assist.`,
        },
        {
          question:
            "Do you provide helmets with two-wheeler rentals in Vadodara?",
          anaswer: `Safety is a priority at Freedo. We include complimentary helmets with every rental to ensure your safety while riding. Furthermore, our vehicles undergo regular servicing and inspections to maintain top-notch performance and safety standards.`,
        },
        {
          question:
            "How can I make payments for my bike and scooter rentals in Vadodara?",
          anaswer: `
          We offer convenient payment options, including online payment through our app or website. We accept various payment methods to ensure a seamless experience. Don't hesitate – to book your scooty and bike on rent in Vadodara with Freedo today!`,
        },
      ],
    },
    blogs: {
      heading: "Latest Blog",
      baneers: [
        {
          img: WEB_URL + "/blog-images/blog-18.jpg",
          heading: "Finding the perfect bike rental company for your next ride",
          text: `Traveling in cars or public transport is a bit of a hassle, especially if you are driving in the busy streets of cities like Delhi. `,
          link: "blog/finding-the-perfect-bike-rental-company-for-your-next-ride",
        },
        {
          img: WEB_URL + "/blog-images/blog-6.jpg",
          heading:
            "Gear Up for Adventure: A Comprehensive Guide to Solo Bike Trips and Exclusive Bike Rental Tips",
          text: `Taking a solo bike trip on a motorcycle towards a distant place without a care in the world is a dream many carry in their heart. Bikers who have taken such solo bike trips say it is a heavenly feeling that everyone must experience at least once in their lifetime. Let’s share with you some emotions that you may feel in such a life-changing trip`,
          link: "blog/solo-bike-trips-guide",
        },
        {
          img: WEB_URL + "/blog-images/blog-3.jpg",
          heading:
            "Safe and Joyful Journeys: Ensuring Child Pillion Riders' Safety with Freedo Bike Rentals",
          text: `Child pillion riders are in focus as per the latest notification of the Ministry of Road Transport and Highways. From Feb 2023, all children ages 4 yrs and below upto nine months who are riding or being carried pillion on a two-wheeler will need to be in safety gear.`,
          link: "blog/safe-joyful-journeys-child-pillion-safety-freedo-bike-rentals",
        },
      ],
    },
    top_footer: {
      jsxSnippet: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Discover Vadodara with Freedo's Bike and Scooter Rentals
            </h2>
            <p>
              Vadodara, a city known for its rich heritage and vibrant
              lifestyle, invites you to explore its streets on our
              well-maintained bikes. From iconic landmarks to hidden gems, our{" "}
              <b>bike rentals in Vadodara</b> give you the freedom to experience
              Vadodara at your own pace.
            </p>
          </div>
        </>
      ),
      jsxDetails: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Discover Vadodara with Freedo's Bike and Scooter Rentals
            </h2>
            <p>
              Vadodara, a city known for its rich heritage and vibrant
              lifestyle, invites you to explore its streets on our
              well-maintained bikes. From iconic landmarks to hidden gems, our{" "}
              <b>bike rentals in Vadodara</b> give you the freedom to experience
              Vadodara at your own pace.
            </p>

            <p>
              Freedo offers premium bikes and <b>scooty on rent in Vadodara.</b>{" "}
              It is a convenient and eco-friendly way to explore the city. Glide
              through Vadodara's attractions, enjoy its beauty, and contribute
              to a cleaner environment.
            </p>

            <p>Your Adventure, Your Way</p>

            <p>
              In a city filled with stories, a two-wheeler becomes your key to
              unlock these narratives. Freedo's{" "}
              <b>"Two-Wheeler Rentals in Vadodara"</b> service empowers you to
              curate your journey with freedom, flexibility, and authenticity.
            </p>

            <p>
              Vadodara's charm lies not only in its historical landmarks but
              also in its narrow alleys, local markets, and hidden
              neighborhoods. Choose a classic <b>bike on rent in Vadodara</b>{" "}
              for a leisurely ride, a nimble scooter to breeze through traffic,
              or an electric scooter for an eco-conscious adventure – the choice
              is yours.
            </p>

            <p>
              We're committed to environmental sustainability. Embrace
              eco-conscious travel with our <b>rental bikes in Vadodara.</b>{" "}
              Effortless, sustainable, and innovative – experience Vadodara's
              charm the green way.
            </p>

            <p>
              You're not bound by schedules or predefined routes with a rented
              two-wheeler. Embark on detours, discover hidden gems, and immerse
              yourself in the authenticity of the city.
            </p>

            <h2 class="about-2-head">
              Why Choose Freedo's Bike Rentals in Vadodara?
            </h2>
            <p>
              If you're looking to escape Vadodara's traffic and dive into its
              essence, look no further! Freedo is your gateway to an enhanced
              travel experience.
            </p>
            <p>
              <b>Endless Choices: </b>From budget-friendly models to cruisers,
              our fleet caters to all preferences.
            </p>
            <p>
              <b>No Ownership Worries: </b>Enjoy the ride without the stress of
              ownership, maintenance, or insurance.
            </p>
            <p>
              <b>Flexible Rental Plans: </b>Whether hourly, daily, or weekly,
              our plans adapt to your travel needs.
            </p>
            <p>
              <b>Budget-Friendly Adventure: </b>Affordable rates for an
              exhilarating experience within your budget.
            </p>
            <p>
              <b>Unleash Freedom: </b>Ride whenever and wherever, without being
              bound by schedules.
            </p>
            <p>
              <b>Community-Driven: </b>Connect with fellow riders, explore
              together, and share experiences.
            </p>
            <p>
              <b>Expert Support: </b>Our dedicated customer support ensures your
              safety and satisfaction.
            </p>

            <h2 class="about-2-head">
              Book Your Bike and Scooter Rentals in Vadodara at Freedo Today!
            </h2>
            <div class="row">
              <div class="col-sm-12">
                <div class="card footer-img float-md-end float-sm-none">
                  <img src={bike} class="card-img-top" alt="Xpulse 200" />
                  <div class="card-body mb-2">
                    <h5>Rent a Scooter/Bike</h5>
                    <span>Start at just</span>
                    <h5>449/day</h5>
                  </div>
                </div>
                <p>
                  Vadodara offers a wealth of attractions and activities waiting
                  to be explored by travelers. Opting for a bike or{" "}
                  <b>scooty on rent in Vadodara</b> is undoubtedly a smart move
                  that can significantly enhance your journey.
                </p>

                <p>
                  With our scooter and <b>bike rentals in Vadodara,</b> your
                  exploration knows no bounds. Free from the limitations of
                  public transport schedules or the rigidity of guided tours,
                  you have the autonomy to uncover the city's hidden treasures
                  at your leisure.
                </p>

                <p>
                  Ready to redefine your city exploration? Connect with Freedo
                  for the ultimate two-wheeler rental experience. Book now and
                  set your spirits free. You can download our application for
                  exclusive offers or reach out to us via email, phone, or our
                  website for a seamless reservation process.
                </p>
              </div>

              <div>
                <h3>What you will get at Freedo:</h3>
                <div class="d-flex flex-wrap align-items-center mt-3 fetured-row">
                  <div class="featured-icon-wrap">
                    <img src={sanitized} />
                    <span>
                      Sanitized <br />
                      Vehicles
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={complimentaryHelmet} />
                    <span>
                      Complimentary <br />
                      Helmet
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={insuredRide} />
                    <span>
                      Fully Insured <br />
                      Ride
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={assistance} />
                    <span>
                      24 x 7 <br />
                      Assistance
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={payments} />
                    <span>
                      Instant & Secure <br />
                      Payments
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    metaData: {
      title: "Best Scooty & Bike Rentals in Vadodara Starting at Rs. 349",
      description:
        "Freedo Rentals offers scooter & bike rentals in Vadodara starting at Rs. 349. Explore the city with two-wheelers at affordable daily, weekly, and monthly packages from the best-rated two-wheeler rental app.",
      keywords:
        "Bike Rentals in Vadodara, Scooty on rent in Vadodara, Bike on Rent in Vadodara",
    },
  },
  {
    cityId: 4,
    pathname: "/bike-rentals-in-delhi",
    cityName: "Delhi",
    top_banner: {
      subHeading: "Two Wheeler Rentals",
      heading: `Bike and Scooty Rentals in Delhi`,
      text: "Get the best two-wheeler rental solution for your travel needs",
      backgroungImg: productSlider,
    },
    service: {
      heading: "Why You Should Choose Our Freedo Services",
      imgOne: govt,
      imgOneText: "Govt. Authorized Two Wheeler Rentals",
      imgTwo: wideRange,
      imgTwoText: "Wide Range of Vehicles",
      imgThree: freeservice,
      imgThreeText: "Free Service and Maintenance",
      imgFour: lowPrice,
      imgFourText: "Guaranteed Lower Prices",
      imgFive: freeinsurance,
      imgFiveText: "Free Insurance",
      imgSix: homeDel,
      imgSixText: "Home Delivery Available",
    },
    mission: {
      subHeading: "Travel Without Hassle",
      heading: "Freedo - Best Bike Rentals In Delhi ",
      text: `Discover the city like a local with Freedo's top-notch Bike and Scooter Rentals in Delhi. We are here to redefine mobility, offering you smart, affordable and stylish options to make your city travel hassle-free.`,
      title: "Experience the freedom of a motorcycle rental at Freedo!",
      description:
        "Wave goodbye to the challenges of ownership and vehicle maintenance. With Freedo, you're free to enjoy the ride without the burdens of owning.",
      imgOne: freedomIcon,
      imgOneText: "Freedom from Ownership Hassles",
      imgTwo: helmate,
      imgTwoText: "Complimentary Helmet",
      imgThree: payment,
      imgThreeText: "Instant KYC and Payments",
      image: gurgaon_about,
    },
    renting_fleet: {
      heading: "Our Renting Fleet in Delhi",
    },
    faqs: {
      heading:
        "Do you have any questions for us? Let us walk you through these FAQs!",
      img: gurgaon_faq,
      text: `No matter what kind of question you may have or what type of assistance you require, our team of experts will be happy to help you. Please have a look at some of the frequently asked questions regarding our two-wheeler rental services. Let us know if you have still not found what you are looking for.`,
      faq: [
        {
          question: `How can I take a two-wheeler for rent in Delhi with Freedo?`,
          anaswer: `If you want a two-wheeler on rent in Delhi, Freedo offers the best services! Simply visit our website or download our app, browse our selection, choose your preferred vehicle, select your rental duration, and make the booking. You will then receive a confirmation of the booking, as well as directions on where to pick up the two-wheeler. After you are done with your rental, simply drop off the vehicle at the designated location. It's that easy!
          `,
        },
        {
          question: "What types of vehicles do we offer for rent in Delhi?",
          anaswer: `We offer various options to cater to your travel preferences. Our fleet includes traditional scooters, bikes, for easy maneuverability, and even eco-friendly rental bikes in Delhi. Furthermore, we also have a professional team available to provide you with any help you might need at any time.`,
        },
        {
          question: "Can I rent bikes in Delhi?",
          anaswer: `Absolutely! Freedo offers bike rentals in Delhi. Experience the convenience and eco-friendliness of electric mobility while exploring the city. Ride in complete safety with our helmets and other protective gear. Join us and help reduce Delhi's carbon footprint!
          `,
        },
        {
          question: "Are the rental rates affordable?",
          anaswer: `Yes, our rental rates are competitively priced and designed to be budget-friendly. You can find detailed pricing information for bike and scooter rentals on our website or app. We offer discounts for longer rental durations and specific payment methods. Additionally, we have flexible payment plans to cater to your specific needs. Feel free to contact us for any questions or to discuss your rental requirements.`,
        },
        {
          question: "What documents do I need to rent a two-wheeler in Delhi?",
          anaswer: `To avail of a scooter rental in Delhi you will typically need a valid driver's license, a government-issued ID, and in some cases, an Aadhar card or passport. In case you have any queries, you can connect with our customer support team.`,
        },
        {
          question: "Do you provide helmets with two-wheeler rentals?",
          anaswer: `Safety is a priority at Freedo Services. With each scooty on rent in Delhi, we provide complimentary helmets with every two-wheeler rental to ensure your safety while riding. Additionally, our vehicles are regularly serviced and inspected to ensure top-notch performance and safety.`,
        },
        {
          question:
            "How do I make payments for my rental bikes and scooters in Delhi?",
          anaswer: `
          We offer convenient payment options, including online payment through our app or website. We accept various payment methods to offer a good experience to our customers. Don't wait! Book your bike and scooter rental in Delhi at Freedo today!`,
        },
      ],
    },
    blogs: {
      heading: "Latest Blog",
      baneers: [
        {
          img: WEB_URL + "/blog-images/blog-13.jpg",
          heading:
            "Unveil Delhi's Secrets on These Must-Try Weekend Trails with Freedo",
          text: `Delhi is a place of tall buildings, skyscrapers, ancient monuments, narrow lanes, broad roads & everything else you can desirably think of! The capital of India has to offer in terms of exploration. If you are looking forward to taking off the stress this weekend, you must try out these bike trails and explore these gram-worthy locations in Delhi NCR.`,
          link: "blog/best-weekend-bike-trails-near-delhi-NCR",
        },
        {
          img: WEB_URL + "/blog-images/blog-7.jpg",
          heading:
            "Explore 5 Epic Bike Road Trip Destinations Near Delhi-NCR with Freedo Bike Rentals",
          text: `No doubt that the best way to feel refreshed and relaxed is a road trip. You can just hop on your bike or car and go wherever the eternal road takes you. Spontaneous weekend road trips are always fun and best to explore new places with your friends and colleagues. If you are a bike road trip lover living near Delhi-NCR, you are at the best place because there are several places you can visit anytime`,
          link: "blog/Bike-road-trips-near-Delhi-NCR-with-freedo-bike-rentals",
        },
        {
          img: WEB_URL + "/blog-images/blog-9.jpg",
          heading:
            "Monsoon Magic: Explore the Best Getaways from Delhi with Freedo Rentals!",
          text: `Are you looking to enjoy this monsoon season away from the chaos of Delhi? Then why not hit the road and explore some of the best places to visit from Delhi during monsoon. From picturesque mountain routes to idyllic coastal drives, there's something for everyone. So grab your rain gear and get ready for an adventure!`,
          link: "blog/delhi-monsoon-getaways-with-freedo-rentals",
        },
      ],
    },
    top_footer: {
      jsxSnippet: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Experience Urban Exploration with Freedo's Premium Bike and
              Scooter Rentals in Delhi
            </h2>
            <p>
              Delhi, India's bustling capital, welcomes you to embark on a
              two-wheeler journey like no other. Known for its diverse culture
              and dynamic lifestyle, Delhi's roadways can often be a challenge.
              The city's vastness comes alive as you cruise through its lanes on
              our meticulously maintained bikes. From iconic landmarks to hidden
              gems, bike rentals Delhi empower you to explore at your own pace
            </p>
          </div>
        </>
      ),
      jsxDetails: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Experience Urban Exploration with Freedo's Premium Bike and
              Scooter Rentals in Delhi
            </h2>
            <p>
              Delhi, India's bustling capital, welcomes you to embark on a
              two-wheeler journey like no other. Known for its diverse culture
              and dynamic lifestyle, Delhi's roadways can often be a challenge.
              The city's vastness comes alive as you cruise through its lanes on
              our meticulously maintained bikes. From iconic landmarks to hidden
              gems, bike rentals Delhi empower you to explore at your own pace
            </p>

            <p>
              Freedo's presence in the city brings you a practical and exciting
              solution – premium bike and scooter rentals in Delhi. It provides
              convenience, maneuverability, and a swift way to navigate through
              the city's vibrant streets. Glide through Delhi's attractions,
              enjoying both the city's beauty and contributing to a cleaner
              environment.
            </p>

            <h2 class="about-2-head">
              Two-Wheeler on Rent in Delhi: Your Journey, Your Way
            </h2>
            <p>
              In a city where every corner has a story to tell, a two-wheeler
              becomes your key to unlocking these narratives. Freedo understands
              that every traveler seeks a unique connection with the places they
              visit, and what better way to achieve that than by crafting your
              route, your stops, and your pace?!
            </p>

            <p>
              Whether you prefer the nimble scooty on rent in Delhi or the
              powerful bike hire Delhi, we have got your ride covered. Navigate
              the city with ease and flexibility, choosing from our diverse
              fleet of scooter rental Delhi options. Embrace the freedom of
              exploring Delhi on a Two-wheeler on Rent In Delhi, tailored to
              your journey.
            </p>

            <p>
              When it comes to experiencing Delhi's dynamic charm, there's no
              better way to do it than on two wheels. Freedo's "two-wheeler on
              rent in Delhi" service empowers you to curate your journey,
              infused with freedom, flexibility, and authenticity.
            </p>
            <p>
              Delhi's allure lies not only in its iconic monuments but also in
              its narrow alleys, local markets, and hidden neighborhoods. With
              Freedo's services of scooter rental Delhi, you become the
              architect of your exploration. Choose a classic bike for a
              leisurely cruise, a nimble scooter to weave through traffic, or a
              scooter for a sustainable adventure – the choice is yours.
            </p>
            <p>
              We also consider environmental concerns and put in our best
              efforts to meet our sustainability goals. Embrace eco-conscious
              travel with our bike hire in Delhi. Cruise silently through
              Delhi's lanes, reducing your carbon footprint while enjoying the
              city's beauty. Effortless, sustainable, and innovative –
              experience Delhi's charm the green way.
            </p>
            <p>
              With a scooty on rent in Delhi, you are not bound by schedules or
              predefined routes. Embark on detours, discover hidden cafes, and
              immerse yourself in the authenticity of the city. Feel the thrill
              of the unknown, guided solely by your curiosity.
            </p>
            <p>
              Reserve your ride, step into the realm of possibilities, and
              embrace the lively rhythm of streets with Freedo-bike rentals
              Delhi. Your journey, your way – begin it with our bike rental
              services!
            </p>

            <h2 class="about-2-head">
              Why Choose Freedo's Bike Rentals Delhi?
            </h2>
            <p>
              If you want to escape Delhi's traffic and dive into its essence,
              look no further than Freedo Rentals. Freedo is your gateway to an
              enhanced travel experience with top-notch bike hire services in
              Delhi. Whether you are a solo explorer or with friends, our bike
              rentals elevate your journey.
            </p>
            <p>
              <b>Endless Choices: </b>From budget-friendly models to cruisers,
              our fleet caters to all preferences.
            </p>
            <p>
              <b>No Ownership Worries: </b>Enjoy the ride without the stress of
              ownership, maintenance, or insurance.
            </p>
            <p>
              <b>Flexible Rental Plans: </b>Whether hourly, daily, or weekly,
              our plans adapt to your travel needs.
            </p>
            <p>
              <b>Budget-Friendly Adventure: </b>Affordable rates for an
              exhilarating experience within your budget.
            </p>
            <p>
              <b>Unleash Freedom: </b>Ride whenever and wherever, without being
              bound by schedules.
            </p>
            <p>
              <b>Community-Driven: </b>Connect with fellow riders, explore
              together, and share experiences.
            </p>
            <p>
              <b>Expert Support: </b>Our dedicated customer support ensures your
              safety and satisfaction.
            </p>

            <h2 class="about-2-head">
              Book Your Bike And Scooter Rentals in Delhi at Freedo today!
            </h2>
            <div class="row">
              <div class="col-sm-12">
                <div class="card footer-img float-md-end float-sm-none">
                  <img src={bike} class="card-img-top" alt="Xpulse 200" />
                  <div class="card-body mb-2">
                    <h5>Rent a Scooter/Bike</h5>
                    <span>Start at just</span>
                    <h5>499/day</h5>
                  </div>
                </div>
                <p>
                  Delhi offers a plethora of attractions and activities waiting
                  to be explored by travelers. Opting for bike or scooter
                  rentals is undoubtedly a smart move that can significantly
                  enhance your journey.
                </p>

                <p>
                  Experience the freedom of the road with Freedo! Book your bike
                  and scooter rentals in Delhi effortlessly. Whether you are
                  looking for a thrilling ride or a convenient way to navigate
                  the city, Freedo has you covered. Choose from our diverse
                  fleet of two-wheelers, including bike rentals in Delhi and
                  scooty on rent in Delhi.
                </p>

                <p>
                  With our service of bike and scooter rentals in Delhi, your
                  exploration knows no bounds. Free from the limitations of
                  public transport schedules or the rigidity of guided tours,
                  you have the autonomy to uncover the city's hidden treasures
                  at your leisure.
                </p>
                <p>
                  Ready to redefine your city exploration? Connect with Freedo’s
                  service of bike hire in Delhi for the ultimate two-wheeler
                  rental experience. Feel the wind, soak in the sights, and
                  embrace the essence of Delhi like never before. Book now and
                  set your spirits free!
                </p>
                <p>
                  Enjoy the convenience of bike hire in Delhi for a day or more.
                  Freedo makes scooter rental in Delhi a breeze, ensuring you
                  have the perfect two-wheeler on rent in Delhi for your
                  journey. Embrace the joy of riding with Freedo today.
                </p>
                <p>
                  To ensure a smooth and swift process of reserving your
                  two-wheeler, you can also opt to download our application. By
                  doing so, you will gain access to exclusive offers on our
                  two-wheeler rentals. If you prefer, you can reach out to us by
                  email, give us a call, or utilize our website to submit your
                  inquiry.
                </p>
              </div>

              <div>
                <h3>What you will get at Freedo:</h3>
                <div class="d-flex flex-wrap align-items-center mt-3 fetured-row">
                  <div class="featured-icon-wrap">
                    <img src={sanitized} />
                    <span>
                      Sanitized <br />
                      Vehicles
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={complimentaryHelmet} />
                    <span>
                      Complimentary <br />
                      Helmet
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={insuredRide} />
                    <span>
                      Fully Insured <br />
                      Ride
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={assistance} />
                    <span>
                      24 x 7 <br />
                      Assistance
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={payments} />
                    <span>
                      Instant & Secure <br />
                      Payments
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    metaData: {
      title: "Best Scooty & Bike Rentals in Delhi Starting at Rs. 349",
      description:
        "Freedo Rentals offers scooter & bike rentals in Delhi starting at Rs. 349. Explore the city with two-wheelers at affordable daily, weekly, and monthly packages from the best-rated two-wheeler rental app.",
      keywords:
        "Bike Rentals in Delhi, Scooty on rent in Delhi, Bike on Rent in Delhi",
    },
  },
  {
    cityId: 4,
    pathname: "/bike-rentals-in-noida",
    cityName: "Noida",
    top_banner: {
      subHeading: "Two Wheeler Rentals",
      heading: `Bike and Scooty Rentals in Noida`,
      text: "Get the best two-wheeler rental solution for your travel needs.",
      backgroungImg: productSlider,
    },
    service: {
      heading: "Why You Should Choose Our Freedo Services",
      imgOne: govt,
      imgOneText: "Govt. Authorized Two Wheeler Rentals",
      imgTwo: wideRange,
      imgTwoText: "Wide Range of Vehicles",
      imgThree: freeservice,
      imgThreeText: "Free Service and Maintenance",
      imgFour: lowPrice,
      imgFourText: "Guaranteed Lower Prices",
      imgFive: freeinsurance,
      imgFiveText: "Free Insurance",
      imgSix: homeDel,
      imgSixText: "Home Delivery Available",
    },
    mission: {
      subHeading: "Travel Without Hassle",
      heading: "Freedo - Best Bike Rentals In Noida",
      text: `Immerse in the charm of the city with Freedo Rentals. Our premium Bike and Scooty Rentals in Noida are the best choice for you. Redefining mobility, we provide intelligent, budget-friendly and stylish alternatives. We enhance your urban travel experience.`,
      title: "Experience the freedom of a motorcycle rental at Freedo!",
      description:
        "Wave goodbye to the challenges of ownership and vehicle maintenance. With Freedo, you're free to enjoy the ride without the burdens of owning.",
      imgOne: freedomIcon,
      imgOneText: "Freedom from Ownership Hassles",
      imgTwo: helmate,
      imgTwoText: "Complimentary Helmet",
      imgThree: payment,
      imgThreeText: "Instant KYC and Payments",
      image: gurgaon_about,
    },
    renting_fleet: {
      heading: "Our Renting Fleet in Noida",
    },
    faqs: {
      heading: "Is there anything you need to know? We have got your back!",
      img: gurgaon_faq,
      text: `If you have questions about two-wheeler rental or need help with a booking, our team is here to assist you. You will get answers to some of the most frequently asked questions below. Feel free to connect with us if you do not find what you are looking for.`,
      faq: [
        {
          question: `How do I rent a bike or scooty with Freedo Rentals in Noida?`,
          anaswer: `Freedo aims to provide a hassle-free experience when it comes to scooty rentals in Noida. We give an easy to book process. To book a scooty or bike from Freedo, you simply have to visit our website or download our app. You can then select the bike or scooty of your choice, and also choose the length of the rental. We have end-to-end customer assistance in case you have any query.`,
        },
        {
          question:
            "What types of bikes and scooters do Freedo Rentals offer in Noida?",
          anaswer: `To ensure a satisfactory customer experience, Freedo provides a wide selection of bikes and scooters. Whether you are searching for a modern-day scooty or a high-tech bike, Freedo has an option that can cater to your choice. At Freedo, our goal is to offer a wide range of scooty on rent in Noida that can fit all requirements and taste.`,
        },
        {
          question:
            "Is there a minimum rental duration with Freedo Rentals in Noida?",
          anaswer: `Freedo Rentals acknowledges that your requirements could differ. We provide variable rental periods as a result. Our rental choices are customized to fit your schedule, from daily to quarterly packages as per your need.
          `,
        },
        {
          question:
            "Can I get a Scooty On Rent In Noida delivered to my doorstep?",
          anaswer: `Of course! In Noida, Freedo Rentals offers the convenience of home delivery. With us, you can have a convenient ride at your doorstep. Our aim is to make scooty rentals a hassle-free experience by saving your time and efforts.`,
        },
        {
          question:
            "How does Freedo Rentals ensure the safety and cleanliness of their vehicles in Noida?",
          anaswer: `At Freedo, our motto is ‘your security is our security’. To ensure the same, we provide you guaranteed assurance and comfort. All our scooters and bikes are regularly maintened and cleaned to ensure that our customers have a smooth experience while they are on the roads.`,
        },
        {
          question:
            "What sets Freedo Rentals apart from other bike rental services in Noida?",
          anaswer: `Safety, Customer-satisfaction and User centric philosophy sets Freedo Rentals apart. From the time you choose us to rent a scooty in Noida till the end of your ride, we ensure that you have smooth service.`,
        },
        {
          question:
            "Are there any hidden charges when renting a bike or scooty in Noida with Freedo?",
          anaswer: `Price transparency is important to Freedo Rentals. Rental options provided by us come with no additional fees. We have a simple and transparent process to ensure that booking goes smoothly.`,
        },
        {
          question:
            "Can I take a Scooty On Rent In Noida from Freedo Rentals without a driving license?",
          anaswer: `If you choose Freedo to rent a scooty in Noida, our team will need a valid driver’s license to ensure driver’s safety as well as to stay compliant with the law.`,
        },
        {
          question:
            "What happens in case of a breakdown or emergency during the rental period with Freedo Rentals in Noida?",
          anaswer: `For any kind of emergency breakdowns, we have a committed team of staff who are always on the go to help the customers. You can simply call us to connect with our round-the-clock helpline and our support team will quickly assist you with required steps needed to fix the issue.`,
        },
        {
          question:
            "How can I provide feedback or share my experience with Freedo Rentals in Noida?",
          anaswer: `Your opinions are important to Freedo Rentals. You can contact our customer service by using our website, app, or other means to share your experience. Your input enables us to improve our offerings and guarantees that every Noida user receives a consistently better experience.`,
        },
      ],
    },
    blogs: {
      heading: "Latest Blog",
      baneers: [
        {
          img: WEB_URL + "/blog-images/blog-18.jpg",
          heading: "Finding the perfect bike rental company for your next ride",
          text: `Traveling in cars or public transport is a bit of a hassle, especially if you are driving in the busy streets of cities like Delhi. `,
          link: "blog/finding-the-perfect-bike-rental-company-for-your-next-ride",
        },
        {
          img: WEB_URL + "/blog-images/blog-6.jpg",
          heading:
            "Gear Up for Adventure: A Comprehensive Guide to Solo Bike Trips and Exclusive Bike Rental Tips",
          text: `Taking a solo bike trip on a motorcycle towards a distant place without a care in the world is a dream many carry in their heart. Bikers who have taken such solo bike trips say it is a heavenly feeling that everyone must experience at least once in their lifetime. Let’s share with you some emotions that you may feel in such a life-changing trip`,
          link: "blog/solo-bike-trips-guide",
        },
        {
          img: WEB_URL + "/blog-images/blog-3.jpg",
          heading:
            "Safe and Joyful Journeys: Ensuring Child Pillion Riders' Safety with Freedo Bike Rentals",
          text: `Child pillion riders are in focus as per the latest notification of the Ministry of Road Transport and Highways. From Feb 2023, all children ages 4 yrs and below upto nine months who are riding or being carried pillion on a two-wheeler will need to be in safety gear.`,
          link: "blog/safe-joyful-journeys-child-pillion-safety-freedo-bike-rentals",
        },
      ],
    },
    top_footer: {
      jsxSnippet: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Discover the Thrill of Urban Exploration with Premier Bike Rentals
              In Noida
            </h2>
            <p>
              It’s always a pleasure to have a smooth ride on the roads Noida
              offers. With lush greenery all over, one cannot resist having a
              long scooty or a bike ride. Its roadways offer a thrilling
              challenge. With our well-maintained bikes, cruise through the
              city's roads and discover its vastness. With the help of our bike
              rental Noida, you can discover everything at your speed, from
              famous sites to undiscovered treasures. Get premium bike and
              scooter rentals in Noida which meets the demand for innovative and
              useful transportation options. Experience the ease, flexibility,
              and quick navigation of the city's bustling streets. Cruise around
              Noida's attractions, taking in the beauty of the city and helping
              to keep the environment cleaner as you go.
            </p>
          </div>
        </>
      ),
      jsxDetails: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Discover the Thrill of Urban Exploration with Premier Bike Rentals
              In Noida
            </h2>
            <p>
              It’s always a pleasure to have a smooth ride on the roads Noida
              offers. With lush greenery all over, one cannot resist having a
              long scooty or a bike ride. Its roadways offer a thrilling
              challenge. With our well-maintained bikes, cruise through the
              city's roads and discover its vastness. With the help of our bike
              rental Noida, you can discover everything at your speed, from
              famous sites to undiscovered treasures. Get premium bike and
              scooter rentals in Noida which meets the demand for innovative and
              useful transportation options. Experience the ease, flexibility,
              and quick navigation of the city's bustling streets. Cruise around
              Noida's attractions, taking in the beauty of the city and helping
              to keep the environment cleaner as you go.
            </p>

            <h2 class="about-2-head">
              Scooty Rentals in Noida: Tailor Your Journey to Your Liking
            </h2>
            <p>
              A two-wheeler becomes your key to uncovering the stories hidden
              around every corner of a city full of unique stories. Freedo is
              aware that every tourist aims to establish a unique bond with the
              locations they visit. How about planning your route, your pauses,
              and your pace to do that?
            </p>
            <p>
              We can accommodate your transportation needs, whether you like the
              strength of a bike rental in Noida or the agility of a scooty on
              rent in Noida. Easily navigate the city by selecting from our wide
              selection of Noida scooter rentals. enjoy the freedom to ride a
              two-wheeler that is customized for your trip and discover Noida.
            </p>
            <p>
              There is no better way to take in Noida's dynamic appeal than
              while travelling on two wheels.
            </p>
            <h2 class="about-2-head">Scooty On Rent in Noida</h2>
            <p>
              Noida's charm extends beyond its iconic landmarks to its narrow
              alleys, local markets, and hidden neighbourhoods. With Freedo's
              bike rental services in Noida, you become the architect of your
              exploration. Choose a classic bike for a leisurely cruise, an
              elegant scooter to navigate through traffic, or an eco-friendly
              scooter for a sustainable adventure – the choice is yours.
            </p>
            <p>
              We prioritize environmental concerns and make significant efforts
              to meet our sustainability goals. Embrace eco-conscious travel
              with our bike hire in Noida. Reduce your carbon footprint while
              enjoying the city's beauty.
            </p>
            <p>
              Effortless, sustainable, and innovative – experience Noida's charm
              the green way. With a scooty on rent in Noida, you are not bound
              by schedules or predefined routes. Embark on detours, discover
              hidden gems, and immerse yourself in the authenticity of the city.
              Feel the thrill of the unknown, guided solely by your curiosity.
            </p>
            <p>
              Secure your ride, step into the realm of possibilities. Embrace
              the lively rhythm of streets with Freedo's bike rentals in Noida.
              Your journey, your way – commence it with our bike rental
              services!
            </p>

            <h2 class="about-2-head">
              Why Opt for Freedo's Bike Rentals in Noida?
            </h2>
            <p>
              For those looking to escape Noida's traffic and immerse themselves
              in its essence, Freedo Rentals is the ultimate choice. Freedo
              opens the door to an enriched travel experience with top-notch
              bike hire services in Noida. Whether you are a solo explorer or
              with friends, our bike rentals elevate your journey.
            </p>
            <p>
              <b>Diverse Options: </b>From budget-friendly models to cruisers,
              our fleet caters to all preferences.
            </p>
            <p>
              <b>Ownership Hassle-Free: </b>Enjoy the ride without the stress of
              ownership, maintenance, or insurance.
            </p>
            <p>
              <b>Flexible Rental Plans: </b>Whether hourly, daily, or weekly,
              our plans adapt to your travel needs.
            </p>
            <p>
              <b>Budget-Friendly Adventure: </b>Experience the thrill within
              your budget with our affordable rates.
            </p>
            <p>
              <b>Unleash Freedom: </b>Ride whenever and wherever, without being
              bound by schedules.
            </p>
            <p>
              <b>Community-Driven: </b>Connect with fellow riders, explore
              together, and share experiences.
            </p>
            <p>
              <b>Expert Support: </b>Our dedicated customer support ensures your
              safety is our priority.
            </p>
            <h2 class="about-2-head">
              Book Your Scooty and Bike Rentals in Noida at Freedo Today!
            </h2>
            <div class="row">
              <div class="col-sm-12">
                <div class="card footer-img float-md-end float-sm-none">
                  <img src={bike} class="card-img-top" alt="Xpulse 200" />
                  <div class="card-body mb-2">
                    <h5>Rent a Scooter/Bike</h5>
                    <span>Start at just</span>
                    <h5>499/day</h5>
                  </div>
                </div>
                <p>
                  Travelers are invited to discover the multitude of sights and
                  activities that Noida has to offer. Hiring a bike or scooter
                  is a wise choice to improve your trip considerably. With
                  Freedo, enjoy the independence of the road! Make easy
                  reservations for your scooter and bike rentals in Noida.
                  Freedo provides an easy and quick way to get around the city
                  and exhilarating rides. Please select from our wide selection
                  of two-wheelers, which includes Noida bike and scooter
                  rentals.
                </p>

                <p>
                  Ready to redefine your city exploration? Connect with Freedo's
                  Scooty and Bike rentals in Noida for the ultimate two-wheeler
                  rental experience. Feel the wind, soak in the sights, and
                  embrace the essence of Noida like never before. Book now and
                  set your spirits free!
                </p>

                <p>
                  Enjoy the convenience of bike hire in Noida for a day or more.
                  Freedo makes scooter rental in Noida a breeze, ensuring you
                  have the perfect two-wheeler on rent in Noida for your
                  journey. Embrace the joy of riding with Freedo today.
                </p>

                <p>
                  To ensure a smooth and swift process of reserving your
                  two-wheeler, you can also opt to download our application.
                  Gain access to exclusive offers on our two-wheeler rentals by
                  doing so. If you prefer, you can reach out to us by email,
                  give us a call, or utilize our website to submit your inquiry.
                </p>
              </div>

              <div>
                <h3>What you will get at Freedo:</h3>
                <div class="d-flex flex-wrap align-items-center mt-3 fetured-row">
                  <div class="featured-icon-wrap">
                    <img src={sanitized} />
                    <span>
                      Sanitized <br />
                      Vehicles
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={complimentaryHelmet} />
                    <span>
                      Complimentary <br />
                      Helmet
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={insuredRide} />
                    <span>
                      Fully Insured <br />
                      Ride
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={assistance} />
                    <span>
                      24 x 7 <br />
                      Assistance
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={payments} />
                    <span>
                      Instant & Secure <br />
                      Payments
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    metaData: {
      title: "Best Scooty & Bike Rentals in Noida Starting at Rs. 349",
      description:
        "Freedo Rentals offers scooter & bike rentals in Noida starting at Rs. 349. Explore the city with two-wheelers at affordable daily, weekly, and monthly packages from the best-rated two-wheeler rental app.",
      keywords:
        "Bike Rentals in Noida, Scooty on rent in Noida, Bike on Rent in Noida",
    },
  },
  {
    cityId: 4,
    pathname: "/bike-rentals-in-ghaziabad",
    cityName: "Ghaziabad",
    top_banner: {
      subHeading: "Two Wheeler Rentals",
      heading: `Bike and Scooty Rentals in Ghaziabad`,
      text: "Get the best two-wheeler rental solution for your travel needs.",
      backgroungImg: productSlider,
    },
    service: {
      heading: "Why You Should Choose Our Freedo Services",
      imgOne: govt,
      imgOneText: "Govt. Authorized Two Wheeler Rentals",
      imgTwo: wideRange,
      imgTwoText: "Wide Range of Vehicles",
      imgThree: freeservice,
      imgThreeText: "Free Service and Maintenance",
      imgFour: lowPrice,
      imgFourText: "Guaranteed Lower Prices",
      imgFive: freeinsurance,
      imgFiveText: "Free Insurance",
      imgSix: homeDel,
      imgSixText: "Home Delivery Available",
    },
    mission: {
      subHeading: "Travel Without Hassle",
      heading: "Freedo - Best Bike Rentals In Ghaziabad",
      text: `Unwrap the charm of the city with Freedo's premium Bike Rental in Ghaziabad. We're here to revolutionize your commute, providing intelligent, budget-friendly and stylish options to make your urban travel a breeze.`,
      title: "Experience the freedom of a motorcycle rental at Freedo!",
      description:
        "Wave goodbye to the challenges of ownership and vehicle maintenance. With Freedo, you're free to enjoy the ride without the burdens of owning.",
      imgOne: freedomIcon,
      imgOneText: "Freedom from Ownership Hassles",
      imgTwo: helmate,
      imgTwoText: "Complimentary Helmet ",
      imgThree: payment,
      imgThreeText: "Instant KYC and Payments",
      image: gurgaon_about,
    },
    renting_fleet: {
      heading: "Our Renting Fleet in Vadodara",
    },
    faqs: {
      heading: "Is there anything you need to know? We have got your back!",
      img: gurgaon_faq,
      text: `If you have questions about two-wheeler rental or need help with a booking, our team is here to assist you. You will get answers to some of the most frequently asked questions below. Feel free to connect with us if you do not find what you are looking for.`,
      faq: [
        {
          question: `How can I rent a bike in Ghaziabad with Freedo?`,
          anaswer: ` Renting with Freedo in Ghaziabad is simple! Visit our website or app, select your preferred ride, choose the rental duration, and provide the necessary details. Complete the booking, and voila, Bike Rental Ghaziabad is ready to roll.`,
        },
        {
          question:
            "Can I choose the vehicles from Freedo rentals in Ghaziabad?",
          anaswer: `Absolutely! We share you the list of available bikes and scooty and you can definitely take your pick. We have a huge range of vehicles to provide on rent.`,
        },
        {
          question:
            " How does Freedo ensure the safety of its vehicles in Ghaziabad?",
          anaswer: `Your safety is our priority. Before every Bike Rental in Ghaziabad, our vehicles undergo thorough sanitization and maintenance checks. We ensure that you get a clean, well-maintained ride for a secure and worry-free journey.
          `,
        },
        {
          question:
            " Can I rent a scooter for a short ride within Ghaziabad with Freedo?",
          anaswer: ` Yes, you can easily rent a scooter for a short ride within Ghaziabad with Freedo. They offer convenient and affordable scooter rental services, making it a hassle-free experience for local exploration. Enjoy the freedom of a short ride with Freedo in Ghaziabad!`,
        },
        {
          question:
            " Are there different types of bikes available for rent in Ghaziabad?",
          anaswer: `Yes, Freedo offers a variety of bikes and scooty on rent in Ghaziabad. From sporty bikes to easy-to-handle scooters, you can choose the ride that suits your style and preferences.`,
        },
        {
          question:
            "What happens if the rented bike faces issues during my journey in Ghaziabad?",
          anaswer: `Don't worry! In case of any issues, contact our 24/7 customer support, and we'll swiftly assist you. We aim to ensure a smooth and trouble-free experience for all our users in Ghaziabad.`,
        },
        {
          question: "Can I extend my rental period with Freedo in Ghaziabad?",
          anaswer: `Yes, you can! If you find yourself wanting to extend your ride, simply log in to your account on the app or website and choose the extension option. Enjoy the flexibility to extend your journey with Freedo in Ghaziabad. Book your Rent A Bike In Ghaziabad today!`,
        },
        {
          question:
            "What documents do I need to provide for bike rental in Ghaziabad?",
          anaswer: `To rent a bike with Freedo in Ghaziabad, you'll need a valid government-issued ID, such as an Aadhar card or driver's license. Ensure you have these handy during the booking process for a seamless experience.`,
        },
        {
          question: "What is the condition of your rented bikes in Ghaziabad?",
          anaswer: `Freedo ensures bikes are well-maintained. We execute bike evaluation after each ride. Timely maintenance is the key to a smooth ride.`,
        },
        {
          question:
            "What makes Freedo different from other bike rental services in Ghaziabad?",
          anaswer: `Freedo stands out for its commitment to hassle-free mobility. With sanitized vehicles, easy booking, and prompt customer support, we prioritize your convenience and safety, making us your trusted partner for two-wheeler rentals in Ghaziabad.`,
        },
      ],
    },
    blogs: {
      heading: "Latest Blog",
      baneers: [
        {
          img: WEB_URL + "/blog-images/blog-7.jpg",
          heading:
            "Explore 5 Epic Bike Road Trip Destinations Near Delhi-NCR with Freedo Bike Rentals",
          text: `No doubt that the best way to feel refreshed and relaxed is a road trip. You can just hop on your bike or car and go wherever the eternal road takes you. Spontaneous weekend road trips are always fun and best to explore new places with your friends and colleagues. If you are a bike road trip lover living near Delhi-NCR, you are at the best place because there are several places you can visit anytime`,
          link: "blog/Bike-road-trips-near-Delhi-NCR-with-freedo-bike-rentals",
        },
        {
          img: WEB_URL + "/blog-images/blog-6.jpg",
          heading:
            "Gear Up for Adventure: A Comprehensive Guide to Solo Bike Trips and Exclusive Bike Rental Tips",
          text: `Taking a solo bike trip on a motorcycle towards a distant place without a care in the world is a dream many carry in their heart. Bikers who have taken such solo bike trips say it is a heavenly feeling that everyone must experience at least once in their lifetime. Let’s share with you some emotions that you may feel in such a life-changing trip`,
          link: "blog/solo-bike-trips-guide",
        },
        {
          img: WEB_URL + "/blog-images/blog-2.jpg",
          heading:
            "Ride Beyond Boundaries: Freedo's Exclusive Bike Rentals for a Hassle-Free Journey in Delhi NCR",
          text: `Every time you think of traveling to a dream destination or making your daily commute a bit easy, the idea of opting for a two-wheeler rental service seems a viable solution. Isn’t it?? This mode of transport reduces dependence on a personal vehicle or public transport. You can go to as many places as you want without taking any pain of ownership and maintenance of the vehicle.`,
          link: "blog/bike-rentals-for-a-hassle-free-journey-in-delhi-ncr",
        },
      ],
    },
    top_footer: {
      jsxSnippet: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Discover Ghaziabad Anew with Freedo's Exclusive Bike and Scooter
              Rentals
            </h2>
            <p>
              Ghaziabad calls you to start on an unmatched two-wheeler
              adventure. Renowned for its lively culture and dynamic lifestyle,
              navigating Ghaziabad's roads promises an exhilarating experience.
              Feel the heartbeat of the city as you cruise through its streets
              on our meticulously maintained bikes and Scooty on rent in
              Ghaziabad. From hidden marvels to iconic landmarks, Ghaziabad bike
              rentals empower you to explore at your own rhythm.
            </p>
          </div>
        </>
      ),
      jsxDetails: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Discover Ghaziabad Anew with Freedo's Exclusive Bike and Scooter
              Rentals
            </h2>
            <p>
              Ghaziabad calls you to start on an unmatched two-wheeler
              adventure. Renowned for its lively culture and dynamic lifestyle,
              navigating Ghaziabad's roads promises an exhilarating experience.
              Feel the heartbeat of the city as you cruise through its streets
              on our meticulously maintained bikes and Scooty on rent in
              Ghaziabad. From hidden marvels to iconic landmarks, Ghaziabad bike
              rentals empower you to explore at your own rhythm.
            </p>
            <p>
              The introduction of Freedo in Ghaziabad unveils a pragmatic and
              thrilling solution – premium bike and scooter rentals. It offers
              not just convenience and maneuverability, but also a swift passage
              through the city's lively streets. Glide through Ghaziabad's
              attractions, savoring the city's charm while contributing to a
              cleaner environment.
            </p>

            <h2 class="about-2-head">
              Two-Wheeler on Rent in Ghaziabad: Sculpt Your Journey, Your Way
            </h2>
            <p>
              In a city where every nook harbors a unique tale, a two-wheeler
              becomes your key to unraveling these narratives. Freedo recognizes
              that every adventurer seeks an exclusive connection with the
              places they visit. What better way to achieve that than by weaving
              your route, choosing your halts, and setting your pace?!
            </p>
            <p>
              Whether you lean toward a scooty on rent in Ghaziabad or the
              robust bike hire Ghaziabad, we've got your ride enveloped.
              Navigate the city effortlessly, choosing from our diverse fleet of
              scooter rental Ghaziabad options. Embrace the freedom of exploring
              Ghaziabad on a Two-wheeler on Rent In Ghaziabad, tailored
              expressly for your journey.
            </p>
            <p>
              When it comes to immersing yourself in Ghaziabad's dynamic allure,
              there's no superior method than on two wheels. Freedo's
              "two-wheeler on rent in Ghaziabad" service empowers you to craft
              your journey, filled with freedom, flexibility, and authenticity.
            </p>
            <p>
              Ghaziabad's allure lies not solely in its landmarks but also in
              its narrow alleys, local markets, and clandestine neighborhoods.
              With Freedo's services of bike rent in Ghaziabad, you metamorphose
              into the architect of your exploration. Opt for a classic bike for
              a leisurely cruise, a nimble scooter for navigating traffic, or an
              eco-friendly scooter for a sustainable adventure – the decision
              rests with you.
            </p>
            <p>
              We also prioritize environmental considerations and exert our
              utmost to fulfill sustainability goals. Embrace eco-conscious
              travel with our bike hire in Ghaziabad. Glide soundlessly through
              Ghaziabad's lanes, diminishing your carbon footprint while
              immersing yourself in the city's beauty. Effortless, sustainable,
              and innovative – relish Ghaziabad's charm the eco-friendly way.
            </p>
            <p>
              With a scooty on rent in Ghaziabad, you are unshackled from
              schedules or predetermined routes. Embark on detours, uncover
              hidden gems, and steep yourself in the authenticity of the city.
              Feel the adrenaline of the unknown, guided purely by your
              inquisitiveness.
            </p>
            <p>
              Reserve your ride, step into the realm of possibilities, and
              embrace the vibrant pulse of streets with Freedo-bike rentals
              Ghaziabad. Your journey, your way – initiate it with our bike
              rental services!
            </p>
            <h2 class="about-2-head">
              Why Opt for Freedo's Bike Rentals Ghaziabad?
            </h2>
            <p>
              Freedo is your gateway to an enriched travel experience with
              superior bike hire services in Ghaziabad. Regardless of whether
              you are a solo explorer or with companions, our bike rentals
              elevate your journey.
            </p>
            <p>
              <b>Diverse Selection: </b>From economical models to cruisers, our
              fleet caters to all inclinations.
            </p>
            <p>
              <b>Ownership-Free Experience: </b>Savor the ride without the
              encumbrance of ownership, maintenance, or insurance.
            </p>
            <p>
              <b>Adaptable Rental Schemes: </b> Be it daily, weekly, or monthly
              – our plans conform to your travel needs.
            </p>
            <p>
              <b>Budget-Friendly Adventure: </b>Accessible rates for a thrilling
              experience well within your budget.
            </p>
            <p>
              <b>Unleash Unbridled Freedom: </b> Ride whenever and wherever,
              devoid of constraints.
            </p>
            <p>
              <b>Community-Centric Approach: </b>Forge connections with fellow
              riders, explore collectively, and exchange experiences.
            </p>
            <p>
              <b>Proficient Assistance: </b>Our dedicated customer support
              ensures your continual safety and satisfaction.
            </p>
            <h2 class="about-2-head">
              Book Your Bike And Scooter Rentals in Ghaziabad at Freedo Today!
            </h2>
            <div class="row">
              <div class="col-sm-12">
                <div class="card footer-img float-md-end float-sm-none">
                  <img src={glamour} class="card-img-top" alt="glamour" />
                  <div class="card-body mb-2">
                    <h5>Rent a Scooter/Bike</h5>
                    <span>Start at just</span>
                    <h5>499/day</h5>
                  </div>
                </div>
                <p>
                  Ghaziabad presents a myriad of attractions and activities
                  waiting to be explored by intrepid travelers. Opting for bike
                  or scooter rentals is undoubtedly a strategic move that can
                  profoundly enrich your journey.
                </p>

                <p>
                  Experience the liberty of the road with Freedo! Book your bike
                  and scooter rentals in Ghaziabad effortlessly. Whether you
                  seek a thrilling ride or a convenient means to traverse the
                  city, Freedo has you enveloped. Choose from our extensive
                  assortment of two-wheelers, including bike rentals in
                  Ghaziabad and scooty on rent in Ghaziabad.
                </p>

                <p>
                  With our service of bike and scooter rentals in Ghaziabad,
                  your exploration knows no bounds. Liberated from the
                  constraints of public transport schedules or the rigidity of
                  guided tours, you have the autonomy to unravel the city's
                  hidden treasures at your leisure.
                </p>

                <p>
                  Are you prepared to redefine your city exploration? Engage
                  with Freedo’s service of bike hire in Ghaziabad for the
                  ultimate two-wheeler rental experience. Feel the breeze,
                  absorb the sights, and embrace the essence of Ghaziabad like
                  never before. Secure your booking now and set your spirits
                  free!
                </p>
                <p>
                  Enjoy the convenience of bike hire in Ghaziabad for a day or
                  more. Freedo simplifies scooter rental in Ghaziabad, ensuring
                  you secure the ideal two-wheeler on rent in Ghaziabad for your
                  journey. Revel in the joy of riding with Freedo today.
                </p>
                <p>
                  For a streamlined and prompt reservation process, consider
                  downloading our application. By doing so, you gain access to
                  exclusive offers on our two-wheeler rentals. Alternatively,
                  reach out to us via email, phone call, or our website to
                  submit your inquiry.
                </p>
              </div>

              <div>
                <h3>What you will get at Freedo:</h3>
                <div class="d-flex flex-wrap align-items-center mt-3 fetured-row">
                  <div class="featured-icon-wrap">
                    <img src={sanitized} />
                    <span>
                      Sanitized <br />
                      Vehicles
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={complimentaryHelmet} />
                    <span>
                      Complimentary <br />
                      Helmet
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={insuredRide} />
                    <span>
                      Fully Insured <br />
                      Ride
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={assistance} />
                    <span>
                      24 x 7 <br />
                      Assistance
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={payments} />
                    <span>
                      Instant & Secure <br />
                      Payments
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    metaData: {
      title: "Best Scooty & Bike Rentals in Ghaziabad Starting at Rs. 249",
      description:
        "Freedo Rentals offers scooter & bike rentals in Ghaziabad starting at Rs. 249. Explore the city with two-wheelers at affordable daily, weekly, and monthly packages from the best-rated two-wheeler rental app.",
      keywords:
        "Bike Rentals in Ghaziabad, Scooty on rent in Ghaziabad, Bike on Rent in Ghaziabad",
    },
  },
  {
    cityId: 4,
    pathname: "/bike-rentals-in-faridabad",
    cityName: "Faridabad",
    top_banner: {
      subHeading: "Two Wheeler Rentals",
      heading: `Bike and Scooty Rentals in Faridabad`,
      text: "Get the best two-wheeler rental solution for your travel needs.",
      backgroungImg: productSlider,
    },
    service: {
      heading: "Why You Should Choose Our Freedo Services",
      imgOne: govt,
      imgOneText: "Govt. Authorized Two Wheeler Rentals ",
      imgTwo: wideRange,
      imgTwoText: "Wide Range of Vehicles",
      imgThree: freeservice,
      imgThreeText: "Free Service and Maintenance",
      imgFour: lowPrice,
      imgFourText: "Guaranteed Lower Prices",
      imgFive: freeinsurance,
      imgFiveText: "Free Insurance",
      imgSix: homeDel,
      imgSixText: "Home Delivery Available",
    },
    mission: {
      subHeading: "Travel Without Hassle",
      heading: "Freedo - Best Bike Rentals In Faridabad",
      text: `Discover the joy of commutation with our efficient and popular bike rentals in Faridabad. You can enjoy a seamless experience with our easy to book two-wheelers. Our smart platform will make your journey perfect.`,
      title: "Experience the freedom of a motorcycle rental at Freedo!",
      description:
        "Wave goodbye to the challenges of ownership and vehicle maintenance. With Freedo, you're free to enjoy the ride without the burdens of owning.",
      imgOne: freedomIcon,
      imgOneText: "Freedom from \n Ownership Hassles",
      imgTwo: helmate,
      imgTwoText: "Complimentary \n Helmet",
      imgThree: payment,
      imgThreeText: "Instant KYC and \n Payments",
      image: gurgaon_about,
    },
    renting_fleet: {
      heading: "Our Renting Fleet in Faridabad",
    },
    faqs: {
      heading: "Is there anything you need to know? We have got your back!",
      img: gurgaon_faq,
      text: `Our executives work around the corner to clear all your doubts. To learn more about our exceptional rental services, review the FAQs!`,
      faq: [
        {
          question: `How Can I Book a Scooty And Bike on Rent in Faridabad For A Weekend?`,
          anaswer: `You can conveniently extend your booking for weekend getaways. Freedo offers easy scooty and bike rental services in Faridabad. You can tell us your travel plans and the details of your requirements, and we will provide the best options for a comfortable experience.`,
        },
        {
          question:
            "What are the rental rates for bikes and scooties in Faridabad?",
          anaswer: `Rental rates for bikes and scooties in Faridabad depend on factors such as the model, duration of rental, and any additional services. The rental start at 399 per day with discounted rates for longer rental durations.`,
        },
        {
          question: "Does Freedo offer Helmets For Safety?",
          anaswer: `Safety of the customer is our first priority. So we offer complientary helmets for  protection from any mishappening.`,
        },
        {
          question:
            "What Is the Minimum Age And Documents Required To Get A Bike on Rent In Faridabad?",
          anaswer: `A person with an age of 18 years can easily book a ride with our two wheeler rental service. However, the rider must have a valid driving license, and aadhar card for address proof from the concerned transport authority. International tourists may need an International Driving Permit (IDP) along with their native driver's license.`,
        },
        {
          question: "What Can I Do If There Is A Breakdown or Accident?",
          anaswer: `Don't panic if your two wheeler is showing trouble during the trip; our team is just a call away. We will assist you throughout the problem. If you want a replacement for your two-wheeler to complete your ride, that can be looked into as well.`,
        },
        {
          question:
            "What models and brands of two-wheelers do you offer for rent in Faridabad?",
          anaswer: `In Faridabad, you can find a variety of bikes and scooties available for rent, including popular models such as Hero Pleasure Plus, Hero Destini,  Hero Xpulse, Glamour, and more. Whether you need a commuter scooter for city travel or a powerful bike for long rides, there are options to suit different preferences and requirements.`,
        },
      ],
    },
    blogs: {
      heading: "Latest Blog",
      baneers: [
        {
          img: WEB_URL + "/blog-images/blog-13.jpg",
          heading:
            "Unveil Delhi's Secrets on These Must-Try Weekend Trails with Freedo",
          text: `Delhi is a place of tall buildings, skyscrapers, ancient monuments, narrow lanes, broad roads & everything else you can desirably think of! The capital of India has to offer in terms of exploration. If you are looking forward to taking off the stress this weekend, you must try out these bike trails and explore these gram-worthy locations in Delhi NCR.`,
          link: "blog/best-weekend-bike-trails-near-delhi-NCR",
        },
        {
          img: WEB_URL + "/blog-images/blog-7.jpg",
          heading:
            "Explore 5 Epic Bike Road Trip Destinations Near Delhi-NCR with Freedo Bike Rentals",
          text: `No doubt that the best way to feel refreshed and relaxed is a road trip. You can just hop on your bike or car and go wherever the eternal road takes you. Spontaneous weekend road trips are always fun and best to explore new places with your friends and colleagues. If you are a bike road trip lover living near Delhi-NCR, you are at the best place because there are several places you can visit anytime`,
          link: "blog/Bike-road-trips-near-Delhi-NCR-with-freedo-bike-rentals",
        },
        {
          img: WEB_URL + "/blog-images/blog-9.jpg",
          heading:
            "Monsoon Magic: Explore the Best Getaways from Delhi with Freedo Rentals!",
          text: `Are you looking to enjoy this monsoon season away from the chaos of Delhi? Then why not hit the road and explore some of the best places to visit from Delhi during monsoon. From picturesque mountain routes to idyllic coastal drives, there's something for everyone. So grab your rain gear and get ready for an adventure!`,
          link: "blog/delhi-monsoon-getaways-with-freedo-rentals",
        },
      ],
    },
    top_footer: {
      jsxSnippet: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Elevate your Journey with Freedo's Best Bike & Scooty On Rent in
              Faridabad
            </h2>
            <p>
              Moving through the busy traffic in any city can take time and a
              two-wheeler has proved to be an advantage to this situation. A
              bike or a scooter can help you take the shortest route, and save
              your time & energy. Freedo understands everyone's plight and has
              come up with rental services for scooters and bikes on affordable
              rental plans. So, getting a bike on rent in Faridabad is an
              efficient way of choosing a vehicle if you are a travel
              enthusiast, or an office commuter, or someone who just wishes to
              take a break on weekends.
            </p>
          </div>
        </>
      ),
      jsxDetails: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Elevate your Journey with Freedo's Best Bike & Scooty On Rent in
              Faridabad
            </h2>
            <p>
              Moving through the busy traffic in any city can take time and a
              two-wheeler has proved to be an advantage to this situation. A
              bike or a scooter can help you take the shortest route, and save
              your time & energy. Freedo understands everyone's plight and has
              come up with rental services for scooters and bikes on affordable
              rental plans. So, getting a bike on rent in Faridabad is an
              efficient way of choosing a vehicle if you are a travel
              enthusiast, or an office commuter, or someone who just wishes to
              take a break on weekends.
            </p>

            <p>
              You can ride through the lanes of the busy city and the adjoining
              areas of Faridabad with ease. Freedo offers the adaptability to
              choose from different two-wheeler models according to your needs.
              Customers can effortlessly select the ideal vehicle from our
              diverse rental options.
            </p>

            <h2 class="about-2-head">
              Time to Fulfill Your Travel Itineraries On A Bike on Rent In
              Faridabad!
            </h2>
            <p>
              A two wheeler can offer a smooth ride to the travelers who want to
              walk through the city and explore different places. Faridabad is a
              busy city known for upcoming industries, and job opportunities.
              Freedo has come up with the best two wheeler rental service, which
              offers an easy choice to reach your destination. Freedo provides a
              hassle-free rental experience in Faridabad, and our excellent
              platform will ensure that you can avail the best bikes at a
              competitive price.
            </p>

            <p>
              Freedo provides attractive deals and discounts when you book
              through our efficient website or app. Customers can book any
              two-wheeler and navigate through various options with ease!
            </p>

            <h2 class="about-2-head">
              Why is Freedo The Best Rentals Service Provider in Faridabad?
            </h2>
            <p>
              Traffic is one of the growing concerns in every metropolitan city.
              If you want to explore the city on your own terms, or seeking to
              wander through the streets and cafes, then choosing Freedo will
              give you the most reliable choice. Freedo is your ultimate
              gateway, providing a true and joyful travelling experience with
              its best bike and scooter rental services in Faridabad
            </p>
            <p>
              <b>Multiple Options: </b> You can choose from an array of
              two-wheelers. This will give you peace of mind and the flexibility
              to navigate the streets at your own pace.
            </p>
            <p>
              <b>No Upfront Investment: </b> No worries about riding a
              two-wheeler with a hefty price. Be free from the stress of
              maintenance or insurance.
            </p>
            <p>
              <b>Flexible Plans: </b> You can choose your rental plans according
              to your travel requirements.
            </p>
            <p>
              <b>Freedom of Traveling </b> You can ride anywhere with Freedo
              bikes. Be it daily office commute or long drives, our two-wheelers
              are the best option.
            </p>
            <p>
              <b>Extensive Support System: </b> The experienced and trained
              professional will ensure you a comfortable and safe ride. If you
              have any issues, then call our customer executive at Freedo, and
              they will resolve your problems.
            </p>

            <h2 class="about-2-head">
              Live Your Passion For Traveling With Bike Rentals From Freedo!
            </h2>
            <div class="row">
              <div class="col-sm-12">
                <div class="card footer-img float-md-end float-sm-none">
                  <img src={glamour} class="card-img-top" alt="glamour" />
                  <div class="card-body mb-2">
                    <h5>Rent a Scooter/Bike</h5>
                    <span>Start at just</span>
                    <h5>499/day</h5>
                  </div>
                </div>
                <p>
                  Scooters & Bikes are now the most popular choice of mobility
                  in the city. It has enhanced the overall experience of the
                  customers. And booking a scooty on rent in Faridabad through
                  Freedo will give you the opportunity to roam in the city
                  worry-free. You don't have to rely on someone to take you
                  around the cafes or famous places.
                </p>

                <p>
                  With Freedo around the corner, you can easily rent a scooter
                  and bike in Faridabad through their rental system. If you
                  travel solo and want a thrilling ride to navigate through the
                  busy city, contact Freedo.
                </p>

                <p>
                  Our user-friendly two-wheeler booking process will ensure a
                  smooth and convenient ride. All you have to do is provide the
                  necessary information about your trip details and driving
                  license before making the final payment. Our flexible payment
                  system offers various payment gateway integrations for your
                  effortless ride.
                </p>
                <p>
                  We always believe in keeping our commitment and offering a
                  pleasing commutation experience. Book any two-wheeler of your
                  choice from our mobile app and start your two-wheeler rental
                  journey today.
                </p>
                <p>
                  Come and discover the benefits of booking a bike on rent in
                  Faridabad with Freedo!
                </p>
              </div>

              <div>
                <h3>What you will get at Freedo:</h3>
                <div class="d-flex flex-wrap align-items-center mt-3 fetured-row">
                  <div class="featured-icon-wrap">
                    <img src={sanitized} />
                    <span>
                      Sanitized <br />
                      Vehicles
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={complimentaryHelmet} />
                    <span>
                      Complimentary <br />
                      Helmet
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={insuredRide} />
                    <span>
                      Fully Insured <br />
                      Ride
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={assistance} />
                    <span>
                      24 x 7 <br />
                      Assistance
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={payments} />
                    <span>
                      Instant & Secure <br />
                      Payments
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    metaData: {
      title: "Best Bike Rentals in Faridabad Starting at Rs. 249",
      description:
        "Freedo Rentals offers bike rentals in Faridabad starting at Rs. 249. Explore the city with two-wheelers at affordable daily, weekly, and monthly packages from the best-rated two-wheeler rental app.",
      keywords:
        "Bike Rentals in Faridabad, Scooty on rent in Faridabad, Bike on Rent in Faridabad",
    },
  },

  {
    cityId: 25,
    pathname: "/bike-rentals-in-vijayawada",
    cityName: "Vijayawada",
    top_banner: {
      subHeading: "Two Wheeler Rentals",
      heading: `Bike and Scooty Rentals in Vijayawada`,
      text: "Get the best two-wheeler rental solution for your travel needs.",
      backgroungImg: productSlider,
    },
    service: {
      heading: "Why You Should Choose Our Freedo Services",
      imgOne: govt,
      imgOneText: "Govt. Authorized Two Wheeler Rentals",
      imgTwo: wideRange,
      imgTwoText: "Wide Range of Vehicles",
      imgThree: freeservice,
      imgThreeText: "Free Service and Maintenance",
      imgFour: lowPrice,
      imgFourText: "Guaranteed Lower Prices",
      imgFive: freeinsurance,
      imgFiveText: "Free Insurance",
      imgSix: homeDel,
      imgSixText: "Home Delivery Available",
    },
    mission: {
      subHeading: "Travel Without Hassle",
      heading: "Freedo - Best Bike Rentals In Vijaywada",
      text: `It's time to explore the diverse landscapes with Freedo's reliable bike rentals in Vijayawada. Renting a bike allows one to enjoy the experience of traveling alone. Freedo has provided stylish and seamless two-wheeler booking options to explore the city at one's own pace.`,
      title: "Experience the freedom of a motorcycle rental at Freedo!",
      description:
        "Wave goodbye to the challenges of ownership and vehicle maintenance. With Freedo, you're free to enjoy the ride without the burdens of owning.",
      imgOne: freedomIcon,
      imgOneText: "Freedom from \n Ownership Hassles",
      imgTwo: helmate,
      imgTwoText: "Complimentary \n Helmet",
      imgThree: payment,
      imgThreeText: "Instant KYC and \n Payments",
      image: gurgaon_about,
    },
    renting_fleet: {
      heading: "Our Renting Fleet in Vijayawada",
    },
    faqs: {
      heading: "Is there anything you need to know? We have got your back!",
      img: gurgaon_faq,
      text: `We understand you might have questions. Our cooperative customer executives will walk you through the booking process and clear all your doubts.`,
      faq: [
        {
          question: `What Are The Benefits Of Renting A Bike In Vijayawada With Freedo?`,
          anaswer: `Freedo has achieved a reputation for offering the best bike rental services in India's major cities. We take pride in honoring our commitment to make commutation easy for all. Customers can reserve their desired ride online from our Freedo app, select the time period conveniently, and pay the rent accordingly.`,
        },
        {
          question:
            "What Are The Various Two-Wheeler Rental Options In Vijayawada?",
          anaswer: `We have brought many of the best and most economical mobility options for your travel requirements. We have high-performance motorcycles and compact and lightweight scooters. Customers can choose from fuel-efficient scooters and bikes based on their preference according to the terrain in their travel itinerary. `,
        },
        {
          question: "How Do I Get Two-wheelers On Rent In Vijayawada?",
          anaswer: `You can easily book your two-wheelers from our booking page on our website or app. Select the date and location of your pickup. We will then guide you to the nearest Freedo two-wheeler station. If you want, we can drop the scooty and bikes off at your convenience.`,
        },
        {
          question: "What Is The Way To Register With Freedo Rentals?",
          anaswer: `Our seamless booking system is simple and smooth. Anyone can register on our website or our Freedo two-wheeler rentals app. Download the app from the Play Store. You can register by providing your email and mobile number to book rides.`,
        },
        {
          question:
            "Do I Need Any Documents To Book Bike Rentals In Vijayawada ?",
          anaswer: `No, you don't need any documents to book bike rentals in Vijayawada. All you need is an e-KYC to book your preferred ride. To explore our versatile bike rentals app, you need only your smartphone. A valid driving license and your Aadhar card can help you become our esteemed customer.`,
        },
        {
          question:
            "What Are The Different Vijayawada Bike Rentals Plans That Are Available?",
          anaswer: `We offer different rental plans for choosing bikes according to your travel needs. You can choose an hourly plan for commuting or the long term rental option. We have monthly to quarterly options for bike rentals in Vijayawada. You can find full details of rental options on our booking page.`,
        },
      ],
    },
    blogs: {
      heading: "Latest Blog",
      baneers: [
        {
          img: WEB_URL + "/blog-images/blog-18.jpg",
          heading: "Finding the perfect bike rental company for your next ride",
          text: `Traveling in cars or public transport is a bit of a hassle, especially if you are driving in the busy streets of cities like Delhi. `,
          link: "blog/finding-the-perfect-bike-rental-company-for-your-next-ride",
        },
        {
          img: WEB_URL + "/blog-images/blog-6.jpg",
          heading:
            "Gear Up for Adventure: A Comprehensive Guide to Solo Bike Trips and Exclusive Bike Rental Tips",
          text: `Taking a solo bike trip on a motorcycle towards a distant place without a care in the world is a dream many carry in their heart. Bikers who have taken such solo bike trips say it is a heavenly feeling that everyone must experience at least once in their lifetime. Let’s share with you some emotions that you may feel in such a life-changing trip`,
          link: "blog/solo-bike-trips-guide",
        },
        {
          img: WEB_URL + "/blog-images/blog-3.jpg",
          heading:
            "Safe and Joyful Journeys: Ensuring Child Pillion Riders' Safety with Freedo Bike Rentals",
          text: `Child pillion riders are in focus as per the latest notification of the Ministry of Road Transport and Highways. From Feb 2023, all children ages 4 yrs and below upto nine months who are riding or being carried pillion on a two-wheeler will need to be in safety gear.`,
          link: "blog/safe-joyful-journeys-child-pillion-safety-freedo-bike-rentals",
        },
      ],
    },
    top_footer: {
      jsxSnippet: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Explore Urban Mobility with Freedo's Premium Bike Rentals in
              Vijayawada
            </h2>
            <p>
              Vijayawada is a promising city and a business hub, a few hundred
              kilometers from Hyderabad. Freedo has introduced amazing
              self-driven bike rentals in Vijayawada. Those visiting the city
              for the first time can choose bike rides for daily commuting.
              Freedo’s Bike Rentals will provide comfort and convenience at an
              affordable cost.
            </p>
          </div>
        </>
      ),
      jsxDetails: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Explore Urban Mobility with Freedo's Premium Bike Rentals in
              Vijayawada
            </h2>
            <p>
              Vijayawada is a promising city and a business hub, a few hundred
              kilometers from Hyderabad. Freedo has introduced amazing
              self-driven bike rentals in Vijayawada. Those visiting the city
              for the first time can choose bike rides for daily commuting.
              Freedo’s Bike Rentals will provide comfort and convenience at an
              affordable cost.
            </p>

            <p>
              Renting a scooter or Bike in Vijayawada has never been easy. You
              can reserve your ride from the different types of serviced
              vehicles in our app. You can plan for a drive to enjoy the scenic
              beauty of this beautiful city.
            </p>

            <h3>Plunge Into Your Own Travel Itinerary in Vijayawada</h3>
            <p>
              Popular bike rental companies like Freedo understand the anxiety
              of travelers who want to book an efficient ride without paying too
              much. The professionals at Freedo will ensure a smooth and safe
              bike journey within your budget. If you want a highly efficient
              bike or a scooty on rent in Vijayawada, Freedo can help. You can
              ride through the busy traffic with ease with our powerful fleet.
            </p>

            <h3>
              Enjoy A Smart Commutation Option With Easy Two Wheeler Rentals In
              Vijayawada
            </h3>
            <p>
              Getting a two-wheeler ride to enjoy the charms of the city of
              Vijayawada will help you to discover new places at our own
              leisure. Freedo's two-wheeler rental services will allow you to
              customize your travel journey. The flexibility of riding a bike or
              a scooter, without owning it actually, will provide you with
              immense joy and adrenaline rush.
            </p>
            <p>
              Before renting a bike or scooter, you should check the tires and
              the efficiency of the clutch, brake, and light. Our Vijayawada
              bike rentals service assistants are available round the clock to
              offer quick guidance and sort out any technical problems with your
              vehicle.
            </p>
            <p>
              We know you're concerned about the carbon footprint, so we have
              brought eco-friendly electric rides to meet our sustainability
              goals. Now, you can easily book a two-wheeler and travel around
              Vijayawada without polluting the air. We provide an effortless way
              of mobility to contribute to the green environment of Vijayawada.
            </p>
            <p>
              Freedo provides the necessary biking equipment for you and your
              companion. We also offer sturdy headgear so that you can ride
              safely. If you experience any troubleshooting, a phone call to our
              professionals will help you relieve stress. We will provide all
              kinds of assistance, be it a tyre puncture, a damaged mirror, or a
              breakdown. Choose the freedom of riding on the streets of
              Vijayawada with Freedo scooty on rent in Vijayawada.
            </p>

            <h2 class="about-2-head">
              Why Avail the Excellent Rental Services From Freedo?
            </h2>
            <div class="row">
              <div class="col-sm-12">
                <div class="card footer-img float-md-end float-sm-none">
                  <img src={glamour} class="card-img-top" alt="glamour" />
                  <div class="card-body mb-2">
                    <h5>Rent a Scooter/Bike</h5>
                    <span>Start at just</span>
                    <h5>449/day</h5>
                  </div>
                </div>
                <p>
                  Freedo is an online two-wheeler rental service platform that
                  has been offering exceptional commutation services throughout
                  India. We are proud to build the most convenient and
                  sustainable online mobility platform. We provide organized and
                  convenient rental options for bikes and scooty. You can plan
                  to begin on various highway trips and glide through the
                  streets of the city. Freedo's Vijayawada bike rentals will
                  offer you affordable custom plans to cater for your driving
                  requirements. View the magnificent Indrakiladri hills and see
                  the Budameru River on your own with our quick two wheeler
                  rentals.
                </p>
                <p>
                  <b> Here are some of the features:</b>
                </p>
                <h3>1. Easy Registration: </h3>
                <p className="ps-2">
                  You can quickly register on our online platform for smooth
                  pickup and drop of two wheeler at your convenient location.!
                </p>
                <h3>2. Hassle-Free Reserve System: </h3>
                <p className="ps-2">
                  {" "}
                  Please provide us with the pickup date and time, and our
                  executives will drop off your preferred two-wheeler at your
                  place. You can choose from a variety of vehicles.
                </p>
                <h3>3. Best Deals & Cost-Efficient Bike Rentals: </h3>
                <p className="ps-2">
                  Make your booking with our easy two-wheeler rental plans.
                  Depending on your travel requirements, you can choose a
                  cost-efficient daily or monthly rental option for the bike
                </p>
                <h3>4. Flexible Ride: </h3>
                <p className="ps-2">
                  No worries about the booking schedules; you can even extend
                  the time of bookings, if needed.
                </p>
                <h3>5. No Issues Of Maintenance: </h3>
                <p className="ps-2">
                  We offer high-quality, serviced motorbikes and scooters. Our
                  dedicated executives will ensure that all our vehicles are in
                  proper working condition.
                </p>
              </div>

              <div>
                <h3>What you will get at Freedo:</h3>
                <div class="d-flex flex-wrap align-items-center mt-3 fetured-row">
                  <div class="featured-icon-wrap">
                    <img src={sanitized} />
                    <span>
                      Sanitized <br />
                      Vehicles
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={complimentaryHelmet} />
                    <span>
                      Complimentary <br />
                      Helmet
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={insuredRide} />
                    <span>
                      Fully Insured <br />
                      Ride
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={assistance} />
                    <span>
                      24 x 7 <br />
                      Assistance
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={payments} />
                    <span>
                      Instant & Secure <br />
                      Payments
                    </span>
                  </div>
                </div>
              </div>

              <div class="abt-detail-content">
                <h3>
                  A Smart Travel Experience - Vijayawada Bike Rentals From
                  Freedo
                </h3>
                <p>
                  With your trusted and reliable Freedo app, you can happily
                  pick any two-wheeler, select the days of rent, make payments,
                  and start your journey. We also come up with great offers from
                  time to time. Our streamlined mobile app and website will help
                  you easily experience all the booking aspects in simple finger
                  clicks.
                </p>
                <p>
                  Vijayawada is a beautiful city adorned with majestic temples.
                  If you are an avid explorer, the city has many attractions
                  that you can discover in your own time. Choosing Freedo to
                  rent a bike or scooter is one intelligent move that can
                  enhance your expedition to know more. Ditch the inconvenience
                  of public transport and bring home a Freedo two-wheeler.
                </p>
                <p>
                  With our Vijayawada bike rentals, you can have the privilege
                  of uncovering the city's natural beauty and walking along with
                  the locals to feel the true essence of Vijayawada.
                </p>
              </div>
            </div>
          </div>
        </>
      ),
    },
    metaData: {
      title: "Best Scooty & Bike Rentals in Vijayawada Starting at Rs. 249",
      description:
        "Freedo Rentals offers scooter & bike rentals in Vijayawada starting at Rs. 249. Explore the city with two-wheelers at affordable daily, weekly, and monthly packages from the best-rated two-wheeler rental app.",
      keywords:
        "Bike Rentals in Vijayawada, Scooty on rent in Vijayawada, Bike on Rent in Vijayawada",
    },
  },
  {
    cityId: 27,
    pathname: "/bike-rentals-in-hyderabad",
    cityName: "Hyderabad",
    top_banner: {
      subHeading: "Two Wheeler Rentals",
      heading: `Bike and Scooty Rentals in Hyderabad`,
      text: "Get the best two-wheeler rental solution for your travel needs.",
      backgroungImg: productSlider,
    },
    service: {
      heading: "Why You Should Choose Our Freedo Services",
      imgOne: govt,
      imgOneText: "Govt. Authorized Two Wheeler Rentals",
      imgTwo: wideRange,
      imgTwoText: "Wide Range of Vehicles",
      imgThree: freeservice,
      imgThreeText: "Free Service and Maintenance",
      imgFour: lowPrice,
      imgFourText: "Guaranteed Lower Prices",
      imgFive: freeinsurance,
      imgFiveText: "Free Insurance",
      imgSix: homeDel,
      imgSixText: "Home Delivery Available",
    },
    mission: {
      subHeading: "Travel Without Hassle",
      heading: "Freedo - Best Bike Rentals In Hyderabad",
      text: `Now you can avail a two wheeler on rent in Hyderabad and glide through the streets of this beautiful city like a pro. Freedo offers excellent scooty and bike rental plans. You can now enjoy the ease and comfort of mobility with Freedo’s stylish and effortless two-wheeler options.`,
      title:
        "Experience the freedom of Bike and Scooty on rent in Hyderabad at Freedo!",
      description:
        "Say goodbye to ownership and maintenance troubles. With Freedo’s bike and scooty rentals in Hyderabad, you can commute to the city of magnificent pearls and attar without the burden.",
      imgOne: freedomIcon,
      imgOneText: "Freedom from \n Ownership Hassles",
      imgTwo: helmate,
      imgTwoText: "Complimentary \n Helmet",
      imgThree: payment,
      imgThreeText: "Instant KYC and \n Payments",
      image: gurgaon_about,
    },
    faqs: {
      heading: "Have you got questions? Freedo answers it all!",
      img: gurgaon_faq,
      text: `Our experts can offer complete assistance. Feel free to ask your questions, and we will try our best to answer them. Some commonly asked questions by our esteemed riders:`,
      faq: [
        {
          question: `How Can I Book a Two-Wheeler On Rent in Hyderabad?`,
          anaswer: `Visit our website or download the app. Browse our Hyderabad fleet, choose your perfect ride, select your rental duration, provide necessary documents, and make the payment. We'll send you confirmation details and pick-up for delivery instructions. When your adventure ends, simply return the vehicle at the designated location.`,
        },
        {
          question:
            "What Types Of Two Wheelers Are Available For Rent in Hyderabad?",
          anaswer: `You can find various types of two wheelers on rent in Hyderabad, including scooties and motorcycles/bikes. Freedo Rental services offer a range of options, from fuel-efficient scooties ideal for city commuting to powerful motorcycles suitable for long rides. Whether you're a beginner or an experienced rider, there's a bike rental option to match your preferences and riding style.`,
        },
        {
          question:
            "Do you provide helmets with two-wheeler rentals in Hyderabad?",
          anaswer: `Freedo offers complimentary helmets and rentable safety gear so that you can ride safely and smartly with our scooty and bike rentals  in Hyderabad.`,
        },
        {
          question: "What Are The Rental Rates For Bikes In Hyderabad?",
          anaswer: `Freedo has curated budget-friendly rental rates for two-wheelers. You can select from our fleet of scooters and bikes at an attractive price. Check out the detailed pricing information on our app or website. We even offer discounts for some cases.`,
        },
        {
          question:
            "What Are The Documents Required to Book A Two-wheeler on Rent in Hyderabad?",
          anaswer: `To book a two-wheeler on rent in Hyderabad, you will typically need to provide certain documents for identification purposes that includes govt authorised driving license and aadhar card as proof of identity.`,
        },
        {
          question: "Where Can I Pick Up My Two-Wheeler From?",
          anaswer: `Freedo Rentals offers a convenient booking system. You can choose your desired vehicle and confirm a pick-up location during the booking process. Our assistants will then drop the two-wheeler at your location.`,
        },
        {
          question: "Can I Extend My Rental Booking?",
          anaswer: `Yes, absolutely. You can extend the tenure of your scooty and bike rentals in Hyderabad by making an extension request before the drop-off time. You can visit our app or website to make such requests. However, your request can be denied or confirmed based on the availability of two-wheelers.`,
        },
      ],
    },
    blogs: {
      heading: "Latest Blog",
      baneers: [
        {
          img: WEB_URL + "/blog-images/blog-18.jpg",
          heading: "Finding the perfect bike rental company for your next ride",
          text: `Traveling in cars or public transport is a bit of a hassle, especially if you are driving in the busy streets of cities like Delhi. `,
          link: "blog/finding-the-perfect-bike-rental-company-for-your-next-ride",
        },
        {
          img: WEB_URL + "/blog-images/blog-6.jpg",
          heading:
            "Gear Up for Adventure: A Comprehensive Guide to Solo Bike Trips and Exclusive Bike Rental Tips",
          text: `Taking a solo bike trip on a motorcycle towards a distant place without a care in the world is a dream many carry in their heart. Bikers who have taken such solo bike trips say it is a heavenly feeling that everyone must experience at least once in their lifetime. Let’s share with you some emotions that you may feel in such a life-changing trip`,
          link: "blog/solo-bike-trips-guide",
        },
        {
          img: WEB_URL + "/blog-images/blog-3.jpg",
          heading:
            "Safe and Joyful Journeys: Ensuring Child Pillion Riders' Safety with Freedo Bike Rentals",
          text: `Child pillion riders are in focus as per the latest notification of the Ministry of Road Transport and Highways. From Feb 2023, all children ages 4 yrs and below upto nine months who are riding or being carried pillion on a two-wheeler will need to be in safety gear.`,
          link: "blog/safe-joyful-journeys-child-pillion-safety-freedo-bike-rentals",
        },
      ],
    },
    top_footer: {
      jsxSnippet: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Explore the Beauty of The Nawabi City with Freedo’s Bike Rentals
              in Hyderabad
            </h2>
            <p>
              Hyderabad is the charming city of the Nawabs and Mughals. It has
              always fascinated vacationers with its captivating beauty. Freedo
              Rentals has opened new avenues to give you an unforgettable memory
              with bike rentals in Hyderabad. The city has made a prominent mark
              in the pages of history for its rich, vibrant culture and
              delicious cuisines. Freedo Rentals will offer you a golden chance
              to navigate the city at your leisure through the iconic landmarks
              and busy alleys.
            </p>
          </div>
        </>
      ),
      jsxDetails: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Explore the Beauty of The Nawabi City with Freedo’s Bike Rentals
              in Hyderabad
            </h2>
            <p>
              Hyderabad is the charming city of the Nawabs and Mughals. It has
              always fascinated vacationers with its captivating beauty. Freedo
              Rentals has opened new avenues to give you an unforgettable memory
              with bike rentals in Hyderabad. The city has made a prominent mark
              in the pages of history for its rich, vibrant culture and
              delicious cuisines. Freedo Rentals will offer you a golden chance
              to navigate the city at your leisure through the iconic landmarks
              and busy alleys.
            </p>
            <h3 class="about-2-head">
              Witnessing The Charm Of Hyderabad on Two Wheels
            </h3>
            <p>
              Every lane and bylane of the charming Hyderabad tells a story.
              Freedo Rentals acknowledges that every traveler is unique, and so
              is their itinerary. Make a personal connection with the city with
              Freedo’s two wheeler on rent in Hyderabad. It will help you
              accomplish your hidden desire to appreciate the city through your
              eyes.
            </p>

            <p>
              If you are a biker and want to feel the thrill of driving a
              Glamour bike, Freedo Rentals offers the perfect bike rental
              options for your next Hyderabad adventure.
            </p>
            <h3 class="about-2-head">
              A Glimpse Into The Wild: Two-wheeler on Rent in Hyderabad
            </h3>
            <p>
              Owning a bike can give you a perfect taste of adventure, but the
              convenience and affordability of Freedo Rentals have exceeded the
              benefits. The user-friendly website or the app offers a variety of
              two-wheelers, ideal for any terrain. With Freedo Rentals, there
              are no hassles of ownership or maintenance. The booking process is
              simple and smooth. With just a few clicks, you can book a ride for
              yourself on the chosen dates. Bike Rentals in Hyderabad were never
              so easy and quick! A free helmet will be an added advantage to
              your safety. Freedo Rentals has indeed brought you a hassle-free,
              beautiful adventure.
            </p>
            <p>
              With Freedo's bike rentals In Hyderabad, you can rent a
              two-wheeler and glide through the streets, colorful bazaars, and
              neighborhoods that will surely fascinate you. We have always given
              importance to sustainable modes of mobility.
            </p>
            <p>
              With Two Wheeler on Rent options in Hyderabad, you are free from
              the tight follow- up schedules and pre-defined tours. You can plan
              your itinerary and take a quick peek to find some cafes or food
              joints.
            </p>
            <p>
              Reserve your sweet ride and surrender to the beautiful energy of
              the streets with our bikes and scooty rentals in Hyderabad.
            </p>

            <h2 class="about-2-head">
              Why Choose Freedo's Two Wheelers Rentals in Hyderabad?
            </h2>
            <p>
              When it comes to exploring Hyderabad, ditch the traffic woes and
              delve into its essence with Freedo rentals. We're your gateway to
              an enhanced Hyderabad experience, perfect for solo adventurers or
              groups.
            </p>
            <p>
              <b>Endless Choices: </b> Our Hyderabad fleet caters to all styles,
              from budget-friendly models to powerful cruisers.
            </p>
            <p>
              <b>No More Tension: </b>You can enjoy your ride without the burden
              of maintenance and insurance.
            </p>
            <p>
              <b>Easy Plans: </b>Freedo Rentals offers flexible rental plans
              that can cater to your travel needs.
            </p>
            <p>
              <b>Budget-Conscious Adventures: </b>Explore Hyderabad without
              breaking the bank with our affordable rates.
            </p>
            <p>
              <b>Enjoy Freedom: </b>Explore Hyderabad on your terms, free from
              public transport limitations.
            </p>
            <p>
              <b>Community Spirit: </b>Connect with fellow riders, explore
              together, and share unforgettable experiences.
            </p>
            <p>
              <b>Expert Support: </b> Our Hyderabad customer support team
              ensures a smooth and safe journey.
            </p>
            <p>
              Freedo's scooty and bike rentals in Hyderabad can ease your daily
              commute and help you explore the city.
            </p>

            <h2 class="about-2-head">
              Book Your Scooty and Bike Rentals in Hyderabad at Freedo Today!
            </h2>
            <div class="row">
              <div class="col-sm-12">
                <div class="card footer-img float-md-end float-sm-none">
                  <img src={glamour} class="card-img-top" alt="glamour" />
                  <div class="card-body mb-2">
                    <h5>Rent a Scooter/Bike</h5>
                    <span>Start at just</span>
                    <h5>449/day</h5>
                  </div>
                </div>
                <p>
                  It's time to choose Freedo's two-wheeler on rent in Hyderabad.
                  Time to live that ultimate riding experience. So, if you're an
                  explorer or a travel enthusiast who is seeking to escape into
                  the wilderness around the periphery of Hyderabad, then
                  Freedo’s two-wheeled adventure can surprise you. Book your
                  ride now and make your mind go free!
                </p>

                <p>
                  You can even extend the tenure of bike and scooty on rent in
                  Hyderabad if you are planning to stay for a few more days in
                  this charming city. Freedo Rentals has brought an excellent,
                  economical fleet and ensures that you have the perfect
                  companion for your Hyderabad itinerary.
                </p>

                <p>
                  Freedo Rentals understands the importance of safety, and that
                  is why we provide complimentary helmets to ensure you have a
                  smooth riding experience. We also make sure that our vehicles
                  are regularly inspected to ensure good performance and maximum
                  safety. We offer a vast collection of vehicles that can suit
                  your individual choice and personality. Freedo strives to
                  offer exceptional mobility services and hope to give our
                  customers smooth & comfortable rides with our well-maintained
                  two-wheelers.
                </p>
              </div>
            </div>
          </div>
        </>
      ),
    },
    metaData: {
      title: "Best Scooty & Bike Rentals in Hyderabad Starting at Rs. 449",
      description:
        "Freedo Rentals offers scooter & bike rentals in Hyderabad starting at Rs. 449. Explore the city with two-wheelers at affordable daily, weekly, and monthly packages from the best-rated two-wheeler rental app.",
      keywords:
        "Bike Rentals in Hyderabad, Scooty on rent in Hyderabad, Bike on Rent in Hyderabad",
    },
  },
  {
    cityId: 26,
    pathname: "/bike-rentals-in-goa",
    cityName: "Goa",
    top_banner: {
      subHeading: "Two Wheeler Rentals",
      heading: `Bike and Scooty Rentals in Goa`,
      text: "Get the best two-wheeler rental solution for your travel needs.",
      backgroungImg: productSlider,
    },
    service: {
      heading: "Why You Should Choose Our Freedo Services",
      imgOne: govt,
      imgOneText: "Govt. Authorized Two Wheeler Rentals",
      imgTwo: wideRange,
      imgTwoText: "Wide Range of Vehicles",
      imgThree: freeservice,
      imgThreeText: "Free Service and Maintenance",
      imgFour: lowPrice,
      imgFourText: "Guaranteed Lower Prices",
      imgFive: freeinsurance,
      imgFiveText: "Free Insurance",
      imgSix: homeDel,
      imgSixText: "Home Delivery Available",
    },
    mission: {
      subHeading: "Travel Without Hassle",
      heading: "Freedo - Best Bike Rentals In Goa",
      text: `It's time to explore the diverse landscapes with Freedo's reliable bike for hire in Goa. Renting a bike allows one to enjoy the experience of traveling alone. Freedo has provided stylish and seamless two-wheeler booking options to explore the city at one's own pace.`,
      title: "Experience The Freedom of  Bike Rentals At Freedo!",
      description:
        "With Freedo, you're free to enjoy the ride without much hassles.",
      imgOne: freedomIcon,
      imgOneText: "Freedom from Ownership Hassles",
      imgTwo: helmate,
      imgTwoText: "Complimentary Helmet ",
      imgThree: payment,
      imgThreeText: "Instant KYC and Payments",
      image: gurgaon_about,
    },
    renting_fleet: {
      heading: "Our Renting Fleet in Goa",
    },
    faqs: {
      heading: "Is there anything you need to know? We have got your back!",
      img: gurgaon_faq,
      text: `We know you have a lot on your mind. Our dedicated experts will address all your doubts. Our Freedo Champions will provide all sorts of help regarding bike for hire in Goa. Here are some of our FAQs for your immediate assistance. If you need any more clarification, please call our customer executives.`,
      faq: [
        {
          question: `How Can I Book A Two-wheeler on Rent in Goa?`,
          anaswer: `Booking a bike or a scooty for rent in Goa with Freedo is a cakewalk. Just visit our website or download our app. You can then browse through our varied choices of vehicles. Choose your desired two-wheeler and fill out the duration. Once we confirm the booking, you will receive a notification with pick-up instructions. As soon as you complete the rental period, leave the two-wheeler at the designated spot. Isn't it so simple!`,
        },
        {
          question: "What Kinds of Two-Wheelers Are Offered On Rent?",
          anaswer: `We understand our clients' diverse travel preferences. So, we have brought a fleet of varied scooter and bikes. `,
        },
        {
          question: "Can I Get A Scooty for Rent in Goa?",
          anaswer: `Absolutely! Freedo allows you convenient and hassle-free scooty for rent in Goa so that you can enjoy the sun and the sand.`,
        },
        {
          question: "Is Renting A Bike Affordable Than Buying?",
          anaswer: `Yes! We assure you that we offer reasonable rates. You can book a two-wheeler on rent in Goa at a moderate price with Freedo. You can check the convenient pricing details on our app or the website. Freedo provides flexible payment plans that can cater to your travel needs.`,
        },
        {
          question: "Do I Need Specific Documents?",
          anaswer: `Want to hire a bike in Goa? It was never so easy. All you need is a valid driver's licence and your Aadhar card. These are mandatory documents needed. For any other questions, you can seek the help of our executives.`,
        },
        {
          question: "Are Helmets Provided?",
          anaswer: `Yes, Freedo offers a complimentary helmet for every bike or scooty for rent in Goa so that you can ride safely. All our two-wheelers are serviced regularly. We ensure that our vehicles provide optimal performance on the road.`,
        },
        {
          question: "What Are The Options For Making Payments?",
          anaswer: `With Freedo, you can have safe and hassle-free riding. With our seamless payment options, you can quickly pay the rental amount. You can avail of online systems through our user-friendly app or our website. We even accept card payments. Book your scooty for rent in Goa with us today and set out for a fascinating journey through the streets of this sun-kissed land!`,
        },
      ],
    },
    blogs: {
      heading: "Latest Blog",
      baneers: [
        {
          img: WEB_URL + "/blog-images/blog-7.jpg",
          heading:
            "Explore 5 Epic Bike Road Trip Destinations Near Delhi-NCR with Freedo Bike Rentals",
          text: `No doubt that the best way to feel refreshed and relaxed is a road trip. You can just hop on your bike or car and go wherever the eternal road takes you. Spontaneous weekend road trips are always fun and best to explore new places with your friends and colleagues. If you are a bike road trip lover living near Delhi-NCR, you are at the best place because there are several places you can visit anytime`,
          link: "blog/Bike-road-trips-near-Delhi-NCR-with-freedo-bike-rentals",
        },
        {
          img: WEB_URL + "/blog-images/blog-6.jpg",
          heading:
            "Gear Up for Adventure: A Comprehensive Guide to Solo Bike Trips and Exclusive Bike Rental Tips",
          text: `Taking a solo bike trip on a motorcycle towards a distant place without a care in the world is a dream many carry in their heart. Bikers who have taken such solo bike trips say it is a heavenly feeling that everyone must experience at least once in their lifetime. Let’s share with you some emotions that you may feel in such a life-changing trip`,
          link: "blog/solo-bike-trips-guide",
        },
        {
          img: WEB_URL + "/blog-images/blog-2.jpg",
          heading:
            "Ride Beyond Boundaries: Freedo's Exclusive Bike Rentals for a Hassle-Free Journey in Delhi NCR",
          text: `Every time you think of traveling to a dream destination or making your daily commute a bit easy, the idea of opting for a two-wheeler rental service seems a viable solution. Isn’t it?? This mode of transport reduces dependence on a personal vehicle or public transport. You can go to as many places as you want without taking any pain of ownership and maintenance of the vehicle.`,
          link: "blog/bike-rentals-for-a-hassle-free-journey-in-delhi-ncr",
        },
      ],
    },
    top_footer: {
      jsxSnippet: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Discover Goa with Freedo's Bike and Scooter Rentals
            </h2>
            <p>
              Goa, a city known for its beautiful beaches and vibrant lifestyle,
              invites you to explore its streets on our well-maintained bikes.
              From iconic landmarks to hidden gems, our bike hire in Goa gives
              you the freedom to experience Goa at your own pace.
            </p>
          </div>
        </>
      ),
      jsxDetails: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Discover Goa with Freedo's Bike and Scooter Rentals
            </h2>
            <p>
              Goa, a city known for its beautiful beaches and vibrant lifestyle,
              invites you to explore its streets on our well-maintained bikes.
              From iconic landmarks to hidden gems, our bike hire in Goa gives
              you the freedom to experience Goa at your own pace.
            </p>
            <p>
              Freedo offers premium bikes and scooty for rent in Goa. It is a
              convenient way to explore the city. Glide through the city's
              attractions, enjoy its beauty, and appreciate the landmarks.
            </p>
            <p>
              Booking a two-wheeler on rent in Goa will assist you in soaking
              yourself in the vibe of the Goanese people. All our fleets are
              meticulously maintained, which will help you discover the true
              essence of the city's vastness. You can explore the old churches
              and the beautiful breezy beaches with your partner.
            </p>

            <h2 class="about-2-head">Freedo Offers Easy Bike on Rent In Goa</h2>
            <p>
              Freedo's superior two-wheeler rentals have revolutionized
              mobility. You can now ride through the lanes of Goa without being
              stuck in traffic. Our two wheelers on rent in Goa are ideal for
              commuting through the bustling lanes of Goa. Glide through the
              city easily, enjoy the beauty on our scooty for rent in Goa, and
              minimize your environmental impact.
            </p>
            <p>
              Every corner of Goa has a fascinating story to tell. And with
              Freedo, you can have that power to discover these tales with the
              safe riding on our two-wheeler. Craft your itinerary, find new
              routes, and stop at unknown places to live in that wilderness.
            </p>
            <p>
              Freedo caters to your individual travel preferences. Whether you
              want a powerful bike or a convenient scooter, the choice is yours.
              With Freedo, you can commute with ease and flexibility, thanks to
              the fully maintained fleet of two-wheeler rentals.
            </p>
            <p>
              Get ready to spend some beautiful days in Goa with Freedo’s
              two-wheeler on rent in Goa.
            </p>

            <h2 class="about-2-head">
              Why Choose Freedo’s Bike Rentals in Goa?
            </h2>
            <p>
              Freedo offers an effortless and innovative Goa experience with
              excellent Bike rental plans. You can now escape the traffic and
              explore the beauties of Goa. If you are travelling solo or with a
              group, Freedo can help you make some beautiful journeys.
            </p>
            <p>
              <b>Varied Selection: </b>We offer budget-friendly models of
              vehicles. Our extensive fleet can cater to every preference.
            </p>
            <p>
              <b>No Hassles of Ownership: </b>No more responsibility for owning
              a bike, maintenance issues, or insurance hassles.
            </p>
            <p>
              <b>Flexibility in Rental Options: </b> You can choose a plan
              according to your travel itinerary.
            </p>
            <p>
              <b>Affordability: </b> Enjoy a convenient and smooth ride within
              your budget.
            </p>
            <p>
              <b>Independence: </b> No more burden of schedules; you can now
              ride whenever and wherever you want to go.
            </p>
            <p>
              <b>Expert Support Team: </b>Our dedicated customer support team
              ensures a seamless and safe experience.
            </p>
            <div>
              <h2 class="about-2-head">
                Book Bike for Hire in Goa From Freedo Rentals
              </h2>
              <p>
                Book a bike for hire in Goa effortlessly with Freedo. If you
                want a thrilling adventure or daily commuting option, Freedo has
                all for you. Our fleet of two-wheelers will offer a convenient
                and sustainable mode of transportation in Goa.
              </p>
              <p>
                Freedo's bike and scooter rentals liberate your exploration. No
                more waiting for public buses or the rigidity of travel agents;
                you have the complete freedom to see Goa at your leisure.
              </p>
              <p>
                It is time to visit our website and get your desired two wheeler
                on rent in Goa. Simply browse through our varied choices of
                vehicles. Choose your desired two-wheeler and fill out the
                duration. Once we confirm the booking, you will receive a
                notification with pick-up instructions. As soon as you complete
                the rental period, leave the two-wheeler at the designated spot.
              </p>
            </div>

            <h2 class="about-2-head">Book Your Ride Today!</h2>
            <div class="row">
              <div class="col-sm-12">
                {/* <div class="card footer-img float-md-end float-sm-none">
                  <img src={glamour} class="card-img-top" alt="glamour" />
                  <div class="card-body mb-2">
                    <h5>Rent a Scooter/Bike</h5>
                    <span>Start at just</span>
                    <h5>499/day</h5>
                  </div>
                </div> */}
                <p>
                  The mesmerizing beauty of the white sand and palm trees has
                  always attracted millions to Goa. During your visit, you can
                  explore various unique activities. Freedo can level up your
                  journey by offering you a smart mobility option.
                </p>
              </div>
              <div>
                <h3>What you will get at Freedo:</h3>
                <div class="d-flex flex-wrap align-items-center mt-3 fetured-row">
                  <div class="featured-icon-wrap">
                    <img src={sanitized} />
                    <span>
                      Sanitized <br />
                      Vehicles
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={complimentaryHelmet} />
                    <span>
                      Complimentary <br />
                      Helmet
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={insuredRide} />
                    <span>
                      Fully Insured <br />
                      Ride
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={assistance} />
                    <span>
                      24 x 7 <br />
                      Assistance
                    </span>
                  </div>

                  <div class="featured-icon-wrap">
                    <img src={payments} />
                    <span>
                      Instant & Secure <br />
                      Payments
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    metaData: {
      title: "Best Scooty & Bike Rentals in Goa Starting at Rs. 333",
      description:
        "Freedo Rentals offers scooter & bike rentals in Goa starting at Rs. 333. Explore the city with two-wheelers at affordable daily, weekly, and monthly packages from the best-rated two-wheeler rental app.",
      keywords:
        "Bike Rentals in Goa, Scooty on rent in Goa, Bike on Rent in Goa",
    },
  },
  {
    cityId: 29,
    pathname: "/bike-rentals-in-lucknow",
    cityName: "Lucknow",
    top_banner: {
      subHeading: "Two-Wheeler Rental Services",
      heading: `Bike and Scooty Rentals in Lucknow`,
      text: "Get the best two-wheeler rental solution for your travel needs.",
      backgroungImg: productSlider,
    },
    service: {
      heading: "Why You Should Choose Our Freedo Services",
      imgOne: govt,
      imgOneText: "Govt. Authorized Two Wheeler Rentals",
      imgTwo: wideRange,
      imgTwoText: "Wide Range of Vehicles",
      imgThree: freeservice,
      imgThreeText: "Free Service and Maintenance",
      imgFour: lowPrice,
      imgFourText: "Guaranteed Lower Prices",
      imgFive: freeinsurance,
      imgFiveText: "Free Insurance",
      imgSix: homeDel,
      imgSixText: "Home Delivery Available",
    },
    mission: {
      subHeading: "Travel Without Hassle",
      heading: "Freedo - Get Quick Bike Hire in Lucknow",
      text: `Avail a bike on rent in Lucknow with Freedo’s quick services. Travel through the streets of this beautiful Nawabi city and discover new places. Freedo offers excellent bike rental plans for travellers. Mobility has never been so easy and comfortable in Lucknow. Thanks to our bike rental services in Nawabi city.`,
      title: "Experience The Ease Of Commutation With Bike Rentals in Lucknow",
      description:
        "Owning a vehicle comes with added responsibilities. Say goodbye to ownership and maintenance troubles. With Freedo’s bike hire in Lucknow, you can commute through the royal city without the burden.",
      imgOne: freedomIcon,
      imgOneText: "Freedom from \n Ownership Hassles",
      imgTwo: helmate,
      imgTwoText: "Complimentary \n Helmet",
      imgThree: payment,
      imgThreeText: "Instant KYC and \n Payments",
      image: gurgaon_about,
    },
    faqs: {
      heading:
        "Have you got questions regarding bike rentals in Lucknow? Freedo answers it all!",
      img: gurgaon_faq,
      text: (
        <div>
          <p>
            We offer complete assistance for your queries regarding bikes on
            rent in Lucknow. Feel free to ask your questions. We will answer
            them for a transparent outlook.
          </p>
          <p>Some commonly asked questions by our esteemed riders:</p>
        </div>
      ),
      faq: [
        {
          question: `How Can I Book a Bike On Rent in Lucknow?`,
          anaswer: `You can visit our website or download the Freedo app. Check out our Lucknow bike lineup for easy bike rentals in Lucknow. Pick your favorite ride from among the varied options! Choose how long you need it. Upload the necessary documents. Then, make your payment. We'll send you confirmation details and delivery instructions immediately. When you're done with your travel, just drop off the vehicle at the designated spot.`,
        },
        {
          question: "What kinds of bikes can I rent in Lucknow?",
          anaswer: `Our fleet has the amazing Hero Glamour for rent in Lucknow. Whether you're cruising through the city or hitting the open road, Freedo's got the best bike option. We've got the best fuel-efficient bike rentals in Lucknow.  No matter your skill level, there's a ride to suit your style.`,
        },
        {
          question: "Do you provide helmets with a bike on rent in Lucknow?",
          anaswer: `Absolutely! Freedo hooks you up with helmets so that you can ride safe and sound in Lucknow. Check our website for safe bike rentals in Lucknow.`,
        },
        {
          question: "What are the rental rates for bikes in Lucknow?",
          anaswer: `Freedo's got wallet-friendly rates for bikes on rent in Lucknow. Pick our Hero Glamour at prices that won't break the bank. Check out our Freedo app or website for all the details, and keep an eye out for discounts.`,
        },
        {
          question: "What documents do I need to hire a bike in Lucknow?",
          anaswer: `For bike rentals in Lucknow, you'll need to show your government-issued driving license, local address proof and Aadhar card for identification.`,
        },
        {
          question: "Where can I pick up my rented bike?",
          anaswer: `Freedo's simple booking system makes bike hire in Lucknow very easy. Choose your ride and the bike can either be picked from our warehouse location or can be home delivered.`,
        },
        {
          question:
            "How can I extend the period of my bike rentals in Lucknow?",
          anaswer: `Absolutely! If you need more time with your bike in Lucknow, just request an extension. Make sure you do that before your drop-off time. Click our app or website to make the request. But remember, extensions for bike hire in Lucknow are based on availability.`,
        },
      ],
    },
    blogs: {
      heading: "Latest Blog",
      baneers: [
        {
          img: WEB_URL + "/blog-images/blog-18.jpg",
          heading: "Finding the perfect bike rental company for your next ride",
          text: `Traveling in cars or public transport is a bit of a hassle, especially if you are driving in the busy streets of cities like Delhi. `,
          link: "blog/finding-the-perfect-bike-rental-company-for-your-next-ride",
        },
        {
          img: WEB_URL + "/blog-images/blog-6.jpg",
          heading:
            "Gear Up for Adventure: A Comprehensive Guide to Solo Bike Trips and Exclusive Bike Rental Tips",
          text: `Taking a solo bike trip on a motorcycle towards a distant place without a care in the world is a dream many carry in their heart. Bikers who have taken such solo bike trips say it is a heavenly feeling that everyone must experience at least once in their lifetime. Let’s share with you some emotions that you may feel in such a life-changing trip`,
          link: "blog/solo-bike-trips-guide",
        },
        {
          img: WEB_URL + "/blog-images/blog-3.jpg",
          heading:
            "Safe and Joyful Journeys: Ensuring Child Pillion Riders' Safety with Freedo Bike Rentals",
          text: `Child pillion riders are in focus as per the latest notification of the Ministry of Road Transport and Highways. From Feb 2023, all children ages 4 yrs and below upto nine months who are riding or being carried pillion on a two-wheeler will need to be in safety gear.`,
          link: "blog/safe-joyful-journeys-child-pillion-safety-freedo-bike-rentals",
        },
      ],
    },
    top_footer: {
      jsxSnippet: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Explore Through The City With The Best Bike On Rent in Lucknow
            </h2>
            <p>
              Lucknow is one of the most royal cities in the heart of India and
              each of the lanes has a different story to speak. If you want to
              venture through these lanes and have a fun time, our bike hire in
              Lucknow is here for you! You get the most affordable bike on rent
              in Lucknow and that too with minimum hassles!
            </p>
          </div>
        </>
      ),
      jsxDetails: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Explore Through The City With The Best Bike On Rent in Lucknow
            </h2>
            <p>
              Lucknow is one of the most royal cities in the heart of India and
              each of the lanes has a different story to speak. If you want to
              venture through these lanes and have a fun time, our bike hire in
              Lucknow is here for you! You get the most affordable bike on rent
              in Lucknow and that too with minimum hassles!
            </p>
            <h3 class="about-2-head">
              Move Like A King With A Bike Hire in Lucknow
            </h3>
            <p>
              Bikes give you a sense of freedom. Particularly if you are someone
              who loves to move about on a bike, this is the best option for
              you!
            </p>
            <p>
              Rent a bike from Freedo today and you will start exploring the
              city like no one else. How do we say that? Thousands of our
              trusted clients have already booked a bike on rent in Lucknow and
              had the time of their lives!
            </p>

            <h2 class="about-2-head">
              What Should Drive You To Choose Us For Bike Rentals in Lucknow?
            </h2>
            <p>
              Wondering what it is that makes us the top choice for bikes on
              rent in Lucknow? At Freedo, we assure you that the service we
              provide you with is simply impeccable.
            </p>
            <p>
              <b>Best Bike Choice: </b>We come with the most promising Hero
              Glamour bike option for commutation.
            </p>
            <p>
              <b>Bid Goodbye to All Worries: </b>The Freedo app has the most
              simple and hassle-free booking and pickup process for bike hire in
              Lucknow.
            </p>
            <p>
              <b>Affordable: </b>For bikes on rent in Lucknow, we help you have
              the most affordable options.
            </p>
            <p>
              <b>Expert Support: </b>Want someone who can help you? We have a
              team of the most cohesive and supportive customer support team.
            </p>
            <p>
              <b>Variety of Plans: </b>We have different tenures of rental
              options that you can choose as per your need.
            </p>
            <p>
              With the bike on rent in Lucknow, we make sure that you have the
              best one-of-a-kind experience with us!
            </p>

            <h2 class="about-2-head">
              Why Wait? Book The Best Bike On Rent In Lucknow Today!
            </h2>
            <div class="row">
              <div class="col-sm-12">
                {/* <div class="card footer-img float-md-end float-sm-none">
                  <img src={glamour} class="card-img-top" alt="glamour" />
                  <div class="card-body mb-2">
                    <h5>Rent a Scooter/Bike</h5>
                    <span>Start at just</span>
                    <h5>449/day</h5>
                  </div>
                </div> */}
                <p>
                  Freedo is your best friend when it comes to booking a Hero
                  Glamour bike and that too in an affordable price range.
                </p>
                <p>
                  Want to extend the tenure of your bike hire in Lucknow? Freedo
                  will make sure that you can do that as well at ease! The range
                  of options that you get in the economical price range is one
                  of a kind for sure. At Freedo, we always try to make sure that
                  you get access to exceptional services but on a budget!
                </p>
                <p>
                  Freedo Rentals understands the importance of safety. That is
                  why we provide complimentary helmets to ensure you have a
                  smooth riding experience. We also make sure that our vehicles
                  are regularly inspected to ensure good performance and maximum
                  safety. We offer a vast collection of bikes on rent in
                  Lucknow. You can choose the one that suits your individual
                  choice and personality. Freedo strives to offer exceptional
                  mobility services and hope to give our customers smooth &
                  comfortable rides with our well-maintained bikes.
                </p>
                <b>
                  For easy and quick bike hire in Lucknow, visit our website
                  today or download the app!
                </b>
              </div>
            </div>
            <div>
              <h3>What you will get at Freedo:</h3>
              <div class="d-flex flex-wrap align-items-center mt-3 fetured-row">
                <div class="featured-icon-wrap">
                  <img src={sanitized} />
                  <span>
                    Sanitized <br />
                    Vehicles
                  </span>
                </div>

                <div class="featured-icon-wrap">
                  <img src={complimentaryHelmet} />
                  <span>
                    Complimentary <br />
                    Helmet
                  </span>
                </div>

                <div class="featured-icon-wrap">
                  <img src={insuredRide} />
                  <span>
                    Fully Insured <br />
                    Ride
                  </span>
                </div>

                <div class="featured-icon-wrap">
                  <img src={assistance} />
                  <span>
                    24 x 7 <br />
                    Assistance
                  </span>
                </div>

                <div class="featured-icon-wrap">
                  <img src={payments} />
                  <span>
                    Instant & Secure <br />
                    Payments
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    metaData: {
      title: "Best Bike Rentals in Lucknow",
      description:
        "Freedo Rentals offers bike rentals in Lucknow at affordable prices. Wide Range of bikes at affordable daily, weekly and monthly packages. Unleash the freedom with the best rated bike rental app.",
      keywords:
        "Bike Rentals in Lucknow, Scooty on rent in Lucknow, Bike on Rent in Lucknow",
    },
  },
  {
    cityId: 30,
    pathname: "/bike-rentals-in-kolkata",
    cityName: "Kolkata",
    top_banner: {
      subHeading: "Bike Rentals",
      heading: `Motorcycle on rent in Kolkata`,
      text: "Get the best bike rental solution for your travel needs",
      backgroungImg: productSlider,
    },
    service: {
      heading: "Why You Should Choose Our Freedo Services",
      imgOne: govt,
      imgOneText: "Govt. Authorized Two Wheeler Rentals",
      imgTwo: wideRange,
      imgTwoText: "Extensive Vehicle Selection",
      imgThree: freeservice,
      imgThreeText: "Free Service and Maintenance",
      imgFour: lowPrice,
      imgFourText: "Guaranteed Lower Prices",
      imgFive: freeinsurance,
      imgFiveText: "Free Insurance",
      imgSix: homeDel,
      imgSixText: "Home Delivery Available",
    },
    mission: {
      subHeading: "Travel Without Hassle",
      heading: "Freedo - Best Bike Rentals in Kolkata",
      text: `You can traverse the cityscape or explore the countryside with Freedo's reliable bike rentals in Kolkata. A lone riding experience is often possible only with a rented bike. To explore the city on bikes, try the Freedo app and book your preferred ride.`,
      title: "Experience The Freedom of a Motorcycle on Rent in Kolkata!",
      description:
        "With Freedo, you're free to enjoy the bike ride hassle-free.",
      imgOne: freedomIcon,
      imgOneText: "Freedom from \n Ownership Hassles",
      imgTwo: helmate,
      imgTwoText: "Complimentary \n Helmet",
      imgThree: payment,
      imgThreeText: "Instant KYC and \n Payments",
      image: gurgaon_about,
    },
    faqs: {
      heading:
        "Have you got questions regarding bike rentals in Kolkata? Freedo answers it all!",
      img: gurgaon_faq,
      text: (
        <div>
          <p>
            In each city, our representative welcomes you and answers all your
            queries about bike rentals. You can expect any type of bike
            rent-related help from our Freedo Champions.
          </p>
          <p>
            Here are some of the FAQs you can use for immediate assistance. Our
            customer care executives are available to answer any further queries
            you might have.{" "}
          </p>
        </div>
      ),
      faq: [
        {
          question: `How Can I Book A Motorcycle on Rent in Kolkata?`,
          anaswer: `You can book a motorcycle on rent in Kolkata with Freedo through our website, app, or by visiting their Freedo hub directly.`,
        },
        {
          question:
            "What makes Freedo a preferred bike rental provider in Kolkata for all types of riders?",
          anaswer: `Freedo offers a wide range of bikes, catering to different rider preferences, and prioritizes customer service with flexible rental plans, thus making it the preferred bike rental provider in Kolkata.`,
        },
        {
          question: "Can I Get A Motorcycle on Rent in Kolkata?",
          anaswer: `Yes, you can rent a motorcycle in Kolkata from Freedo. We offer an expansive fleet for selection to meet your riding needs with their daily, weekly, and monthly packages.`,
        },
        {
          question: "Is Bike Rental Affordable At Freedo Rentals In Kolkata?",
          anaswer: `Yes, bike rental in Kolkata is affordable. Freedo offers competitive rates and flexible rental options to fit various budgets.`,
        },
        {
          question: "Do I Need Specific Documents for Bike Hire in Kolkata?",
          anaswer: `Booking a bike for hire has never been this easy. Freedo requires your driver's license, Aadhar card, and local address proof as the referring document to hire a bike on rent.`,
        },
        {
          question: "Are Helmets Provided by Freedo Rentals?",
          anaswer: `Yes, Freedo offers a complimentary helmet with every motorcycle for rent in Kolkata so that you can ride safely. All our bikes are serviced regularly. We ensure that our vehicles provide optimal performance on the road.`,
        },
        {
          question:
            "What Are The Options For Making Payments for Renting Bikes?",
          anaswer: `Freedo offers multiple payment options for renting bikes, including credit/debit cards, UPI, and mobile wallets ensuring a hassle-free experience.`,
        },
      ],
    },
    blogs: {
      heading: "Latest Blog",
      baneers: [
        {
          img: WEB_URL + "/blog-images/blog-18.jpg",
          heading: "Finding the perfect bike rental company for your next ride",
          text: `Traveling in cars or public transport is a bit of a hassle, especially if you are driving in the busy streets of cities like Delhi. `,
          link: "blog/finding-the-perfect-bike-rental-company-for-your-next-ride",
        },
        {
          img: WEB_URL + "/blog-images/blog-6.jpg",
          heading:
            "Gear Up for Adventure: A Comprehensive Guide to Solo Bike Trips and Exclusive Bike Rental Tips",
          text: `Taking a solo bike trip on a motorcycle towards a distant place without a care in the world is a dream many carry in their heart. Bikers who have taken such solo bike trips say it is a heavenly feeling that everyone must experience at least once in their lifetime. Let’s share with you some emotions that you may feel in such a life-changing trip`,
          link: "blog/solo-bike-trips-guide",
        },
        {
          img: WEB_URL + "/blog-images/blog-3.jpg",
          heading:
            "Safe and Joyful Journeys: Ensuring Child Pillion Riders' Safety with Freedo Bike Rentals",
          text: `Child pillion riders are in focus as per the latest notification of the Ministry of Road Transport and Highways. From Feb 2023, all children ages 4 yrs and below upto nine months who are riding or being carried pillion on a two-wheeler will need to be in safety gear.`,
          link: "blog/safe-joyful-journeys-child-pillion-safety-freedo-bike-rentals",
        },
      ],
    },
    top_footer: {
      jsxSnippet: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Discover Kolkata with Freedo's Bike Hire in Kolkata
            </h2>
            <p>
              Kolkata, the city of joy, has a rich history and culture, inviting
              all to explore the city on bikes for commuting. Roam around the
              lanes of Kolkata and see the towering high rises coinciding with
              old buildings from the British era, little by little, with bike
              hire in Kolkata. From Victoria Memorial to the Eco Park, enjoy
              your day on the bikes.
            </p>
            <p>
              Rent out premium bikes at affordable prices in Kolkata with
              Freedo. Renting is perhaps the most convenient thing if you wish
              to see the city and soak in its Bengali culture.
            </p>
            <p>
              A rented bike can be your ultimate ride to traverse a city's
              concrete jungle. You can glide through the city’s vastness with
              our well-maintained bike fleet. Explore the town's heritage and
              tourist attractions laid over the map with Freedo Rentals- Bike
              Hire in Kolkata.
            </p>
          </div>
        </>
      ),
      jsxDetails: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Discover Kolkata with Freedo's Bike Hire in Kolkata
            </h2>
            <p>
              Kolkata, the city of joy, has a rich history and culture, inviting
              all to explore the city on bikes for commuting. Roam around the
              lanes of Kolkata and see the towering high rises coinciding with
              old buildings from the British era, little by little, with bike
              hire in Kolkata. From Victoria Memorial to the Eco Park, enjoy
              your day on the bikes.
            </p>
            <p>
              Rent out premium bikes at affordable prices in Kolkata with
              Freedo. Renting is perhaps the most convenient thing if you wish
              to see the city and soak in its Bengali culture.
            </p>
            <p>
              A rented bike can be your ultimate ride to traverse a city's
              concrete jungle. You can glide through the city’s vastness with
              our well-maintained bike fleet. Explore the town's heritage and
              tourist attractions laid over the map with Freedo Rentals- Bike
              Hire in Kolkata.
            </p>

            <h2 class="rental-head">
              Freedo Offers Easy Bike Rentals in Kolkata
            </h2>
            <p>
              Moving around Kolkata has never been this simple; with Freedo
              bikes, you can expect hassle-free movement around the town. These
              bikes are kept in good condition to sneak through the dingy lanes
              or the city hotspots, avoiding the traffic in between. Take a
              joyride by booking a motorcycle on rent in Kolkata and skim
              through the streets and heart of the land.
            </p>
            <p>
              The old corners of Kolkata have histories waiting to be
              discovered. Freedo offers you this exclusive chance to search and
              discover these stories with a bike ride through this plot. Plan
              your journey with unprecedented stops and retrace new routes on
              the map with the help of Freedo Bike Rentals in Kolkata.
            </p>
            <p>
              For solo expeditions, the Hero Glamour bike is the optimal choice
              to commute through the streets of Kolkata. Freedo takes pride in
              maintaining the whole fleet collection, ensuring a hassle-free
              riding experience.
            </p>
            <p>
              Take the time out to scale the city with a Motorcycle on Rent in
              Kolkata by Freedo.
            </p>
            <h3 class="about-2-head">
              Why Choose Freedo’s Bike Rentals in Kolkata?
            </h3>
            <p>
              Get exceptional bike rental plans from Freedo for running around
              Kolkata on two wheels. Skip the traffic and only indulge in the
              beauty of the city. Whether it is a solo trip you plan in Kolkata
              or visiting with friends, Freedo Bike Rentals in Kolkata can give
              your plan the spark it needs.
            </p>
            <p>
              <b>No Security Deposit:</b> No specific amount is required as a
              security deposit when renting a bike from Freedo Rentals.
            </p>
            <p>
              <b>No Hassles of Ownership:</b> Say goodbye to the hassles of
              owning a bike and maintaining it.
            </p>
            <p>
              <b>Flexible Rental Options:</b> You can choose a plan according to
              your travel itinerary.
            </p>
            <p>
              <b>Expert Support Team:</b> A customer support team dedicated to
              your service and ensuring safe measures.
            </p>

            <h2 class="about-2-head">Book Your Bike Ride Today!</h2>
            <p>
              Do not miss out on the contrast of old buildings and new high
              rises overlapping one another in a diverse city like Kolkata.
              Freedo bike rentals in Kolkata provide you with a smart mobility
              option for aiding you in your activities and errands in the city.
            </p>

            <h2 class="about-2-head">
              Book Bike Hire in Kolkata From Freedo Rentals
            </h2>
            <p>
              Freedo has made the process of renting bikes in Kolkata
              effortless. Whether for daily commuting or an adventure trip plan,
              Freedo has options for all riding plans. A motorcycle on rent in
              Kolkata from the Freedo collection is the most-adequate option, in
              terms of safety and budget.
            </p>
            <p>
              Rental services like Freedo are like a breath of fresh air for
              riders wanting to see the town. Waiting at the public bus stop or
              conversing with traveling agencies can be a thing of the past when
              you have complete control over the process of bike hire in
              Kolkata.
            </p>
            <p>
              If you have a traveling plan, it is time to visit the Freedo
              website or install their app to see their incredible bike
              collection. Our simple algorithm lets you seamlessly browse our
              inventory and pick your desired vehicle for a specific date. The
              booking confirmation is made available shortly after, with
              notifications for pick-up instructions. A motorcycle on rent in
              Kolkata from Freedo has to be returned to the designed spot at the
              end of the rental period.
            </p>

            <div>
              <h3>What you will get at Freedo:</h3>
              <div class="d-flex flex-wrap align-items-center mt-3 fetured-row">
                <div class="featured-icon-wrap">
                  <img src={sanitized} />
                  <span>
                    Sanitized <br />
                    Vehicles
                  </span>
                </div>

                <div class="featured-icon-wrap">
                  <img src={complimentaryHelmet} />
                  <span>
                    Complimentary <br />
                    Helmet
                  </span>
                </div>

                <div class="featured-icon-wrap">
                  <img src={insuredRide} />
                  <span>
                    Fully Insured <br />
                    Ride
                  </span>
                </div>

                <div class="featured-icon-wrap">
                  <img src={assistance} />
                  <span>
                    24 x 7 <br />
                    Assistance
                  </span>
                </div>

                <div class="featured-icon-wrap">
                  <img src={payments} />
                  <span>
                    Instant & Secure <br />
                    Payments
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    metaData: {
      title: "Best Bike Rentals in Kolkata Starting at Rs. 499",
      description:
        " Freedo Rentals offers bike rentals in Kolkata starting at Rs. 499. Explore the city with two-wheelers at affordable daily, weekly, and monthly packages from the best-rated two-wheeler rental app.",
      keywords:
        "Bike Rentals in Kolkata, Scooty on rent in Kolkata, Bike on Rent in Kolkata",
    },
  },
  {
    cityId: 20222,
    pathname: "/bike-rentals-in-patna",
    cityName: "Patna",
    top_banner: {
      subHeading: "Bike Rentals",
      heading: `Bike on rent in Patna`,
      text: "Get the best bike rental solution for your travel needs",
      backgroungImg: productSlider,
    },
    service: {
      heading: "Why You Should Choose Our Freedo Services",
      imgOne: govt,
      imgOneText: "Govt. Authorized Two Wheeler Rentals",
      imgTwo: wideRange,
      imgTwoText: "Wide Range of Vehicles",
      imgThree: freeservice,
      imgThreeText: "Free Service and Maintenance",
      imgFour: lowPrice,
      imgFourText: "Guaranteed Lower Prices",
      imgFive: freeinsurance,
      imgFiveText: "Free Insurance",
      imgSix: homeDel,
      imgSixText: "Home Delivery Available",
    },
    mission: {
      subHeading: "Travel Without Hassle",
      heading: "Freedo - Best Bike Rent in Patna",
      text: `Freedo introduces smart, affordable, and easily accessible mobility options by offering its premium range of bikes on rent in Patna. Travel effortlessly within the city by choosing the model of your choice.`,
      title: "Experience The Freedom of a Bike on Rent in Patna!",
      description:
        "With Freedo, you're free to enjoy the bike ride hassle-free.",
      imgOne: freedomIcon,
      imgOneText: "Freedom from \n Ownership Hassles",
      imgTwo: helmate,
      imgTwoText: "Complimentary \n Helmet",
      imgThree: payment,
      imgThreeText: "Instant KYC and \n Payments",
      image: gurgaon_about,
    },
    faqs: {
      heading:
        "Have you got questions regarding bike rentals in Patna? Freedo answers it all!",
      img: gurgaon_faq,
      text: (
        <div>
          <p>
            If you have questions about bike rental or need help with a booking,
            our team is here to assist you. You will get answers to some of the
            most frequently asked questions below.
          </p>
          <p>
            Feel free to connect with us if you do not find what you are looking
            for.{" "}
          </p>
        </div>
      ),
      faq: [
        {
          question: `How To Get A Bike on Rent In Patna with Freedo?`,
          anaswer: `You can book a bike on rent in Patna with Freedo through our website, Android/IOS app, or by visiting our Freedo Hub directly.`,
        },
        {
          question:
            "Why You Must Consider Bike Rent in Patna Over Buying A Bike?",
          anaswer: `Renting saves money on purchase, maintenance, and insurance. It’s flexible, hassle-free, and lets you ride without long-term commitments.`,
        },
        {
          question: "What are the documents required  for Bike Rent in Patna?",
          anaswer: `To book a bike on rent in Patna, you will typically need to provide certain documents for identification purposes that include govt authorised driving license, Aadhar card and a local address proof.`,
        },
        {
          question:
            "Do you provide helmets with your bikes at  Freedo Rentals?",
          anaswer: `Freedo offers complimentary helmets and rentable safety gear so that you can ride safely and smartly on our rented bikes in Patna.`,
        },
        {
          question:
            "What Are The Options For Making Payments for Renting Bikes in Patna?",
          anaswer: `We offer convenient payment options, including online payment through our app or website. We accept various payment methods to offer a good experience to our customers. Don't wait! Rent your bike at Freedo today!`,
        },
      ],
    },
    blogs: {
      heading: "Latest Blog",
      baneers: [
        {
          img: WEB_URL + "/blog-images/blog-18.jpg",
          heading: "Finding the perfect bike rental company for your next ride",
          text: `Traveling in cars or public transport is a bit of a hassle, especially if you are driving in the busy streets of cities like Delhi. `,
          link: "blog/finding-the-perfect-bike-rental-company-for-your-next-ride",
        },
        {
          img: WEB_URL + "/blog-images/blog-6.jpg",
          heading:
            "Gear Up for Adventure: A Comprehensive Guide to Solo Bike Trips and Exclusive Bike Rental Tips",
          text: `Taking a solo bike trip on a motorcycle towards a distant place without a care in the world is a dream many carry in their heart. Bikers who have taken such solo bike trips say it is a heavenly feeling that everyone must experience at least once in their lifetime. Let’s share with you some emotions that you may feel in such a life-changing trip`,
          link: "blog/solo-bike-trips-guide",
        },
        {
          img: WEB_URL + "/blog-images/blog-3.jpg",
          heading:
            "Safe and Joyful Journeys: Ensuring Child Pillion Riders' Safety with Freedo Bike Rentals",
          text: `Child pillion riders are in focus as per the latest notification of the Ministry of Road Transport and Highways. From Feb 2023, all children ages 4 yrs and below upto nine months who are riding or being carried pillion on a two-wheeler will need to be in safety gear.`,
          link: "blog/safe-joyful-journeys-child-pillion-safety-freedo-bike-rentals",
        },
      ],
    },
    top_footer: {
      jsxSnippet: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Avail Bike on Rent in Patna For The Adventurer Inside You
            </h2>
            <p>
              Patna’s streets are always buzzing with activity, from busy
              markets to packed roads. Whether you want to explore the city's
              rich history or simply need a convenient way to get around,
              renting a bike from Freedo is the perfect choice.
            </p>
            <p>
              We offer well-maintained, stylish bikes that are ready to ride.
              With a large fleet to choose from, you’ll always get the best
              option for your journey. Freedo gives you the freedom to travel on
              your terms, without waiting for cabs or relying on public
              transport.
            </p>
            <p>
              With a rental bike, you can avoid the hassle of traffic delays and
              reach your destination on time. Every bike is thoroughly checked
              before being rented out, and we also provide a helmet for your
              safety.
            </p>
          </div>
        </>
      ),
      jsxDetails: (
        <>
          <div class="abt-content">
            <h2 class="rental-head">
              Avail Bike on Rent in Patna For The Adventurer Inside You
            </h2>
            <p>
              Patna’s streets are always buzzing with activity, from busy
              markets to packed roads. Whether you want to explore the city's
              rich history or simply need a convenient way to get around,
              renting a bike from Freedo is the perfect choice.
            </p>
            <p>
              We offer well-maintained, stylish bikes that are ready to ride.
              With a large fleet to choose from, you’ll always get the best
              option for your journey. Freedo gives you the freedom to travel on
              your terms, without waiting for cabs or relying on public
              transport.
            </p>
            <p>
              With a rental bike, you can avoid the hassle of traffic delays and
              reach your destination on time. Every bike is thoroughly checked
              before being rented out, and we also provide a helmet for your
              safety.
            </p>

            <h2 class="rental-head">
              Freedo Bikes Rent in Patna Lead You To Freedom of Commutation
            </h2>
            <p>
              Freedo is making travel easier by expanding its services to Patna,
              giving locals and visitors a hassle-free way to get around the
              city. Whether you're commuting daily or exploring new places,
              renting a bike makes your journey smooth and convenient.
            </p>
            <p>
              Patna has many hidden gems, from its historic sites to its lively
              streets, and the best way to experience them is on a rented bike.
              With affordable rates and well-maintained vehicles, Freedo ensures
              a safe and comfortable ride.
            </p>
            <p>
              We offer a variety of bike models, so you can choose the one that
              suits you best. With Freedo, you get the freedom to explore Patna
              at your own pace—ride through its lesser-known lanes, visit iconic
              landmarks, and soak in the city's rich culture.
            </p>
            <h3 class="about-2-head">
              Reasons To Choose Freedo Bike Rent in Patna Over Others
            </h3>
            <p>
              Make your Patna exploration a memorable one with Freedo bike rides
              to the underbelly of the town. Whether alone or with your friend's
              group, have a beautiful journey through the town on a two-wheeler
              of your choice.
            </p>
            <p>
              <b>No Upfront Investment:</b> Freedo charges no security deposit,
              which makes bike on rent with Freedo the best option.
            </p>
            <p>
              <b>Flexible Rental Plans:</b> Whether daily, weekly, or monthly
              our plans adapt to your travel needs.
            </p>
            <p>
              <b>No Ownership Worries:</b> Enjoy the ride without the stress of
              ownership, maintenance, or insurance.
            </p>
            <p>
              <b>Affordability:</b> Enjoy a convenient and smooth ride that fits
              your budget.
            </p>
            <p>
              <b>Extensive Support System:</b> The experienced and trained
              professional will ensure you a comfortable and safe ride. If you
              have any issues, then call our customer executive at Freedo, and
              they will resolve your problems.
            </p>

            <h2 class="about-2-head">
              Book Bike Rent in Patna From Freedo Rentals
            </h2>
            <p>
              Experience the rich history of Patna as you ride through its
              streets, where the past meets the present. Whether you're
              exploring the city or just getting around, renting a bike from
              Freedo makes your journey easier and more enjoyable.
            </p>

            <h2 class="about-2-head">
              Bike on Rent in Patna - Your Ultimate Travel Friend
            </h2>
            <p>
              Worry less and download the app in a single tap to book a bike on
              rent in Patna with Freedo. Make our rented bikes the primary mode
              of transport and add convenience to your journey.
            </p>
            <p>
              With Freedo, you get the power to explore the city and travel on
              your terms. No waiting for public transport anymore, and be on
              time every time with Freedo. Start your journey today!!
            </p>
            <div>
              <h3>What you will get at Freedo:</h3>
              <div class="d-flex flex-wrap align-items-center mt-3 fetured-row">
                <div class="featured-icon-wrap">
                  <img src={sanitized} />
                  <span>
                    Sanitized <br />
                    Vehicles
                  </span>
                </div>

                <div class="featured-icon-wrap">
                  <img src={complimentaryHelmet} />
                  <span>
                    Complimentary <br />
                    Helmet
                  </span>
                </div>

                <div class="featured-icon-wrap">
                  <img src={insuredRide} />
                  <span>
                    Fully Insured <br />
                    Ride
                  </span>
                </div>

                <div class="featured-icon-wrap">
                  <img src={assistance} />
                  <span>
                    24 x 7 <br />
                    Assistance
                  </span>
                </div>

                <div class="featured-icon-wrap">
                  <img src={payments} />
                  <span>
                    Instant & Secure <br />
                    Payments
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    metaData: {
      title: " Best Bike Rentals in Patna",
      description:
        "Freedo Rentals offers bike on rent in Patna with the best pricing. Explore the city with bikes at affordable daily, weekly, and monthly packages from the best-rated bike rental app.",
      keywords:
        "Bike Rentals in Patna, Scooty on rent in Patna, Bike on Rent in Patna",
    },
  },
];

export const Banxgalore = "Bangalore";
export const Gurugram = "Gurgaon";

// '/bike-rentals-in-bangalore'
export const initialState = {
  name: "",
  phone: "",
  location: "Select location",
  vehicle: "Select vehicle",
  email: "",
};

export const enquiryState = {
  name: false,
  phone: false,
  email: false,
  location: false,
  vehicle: false,
};
export const langErrorMsg = "Please Enter in English";
export const arrStarBasedData = [
  {},
  {
    name: "Very Poor",
    emoji: sadFeedbackSmall,
    msg1: "We're sorry to hear about your negative experience.",
    msg2: "We're thankful you shared it with us, allowing us to address and rectify the situation.",
  },
  {
    name: "Poor",
    emoji: sadFeedbackSmall,
    msg1: "We're truly sorry to hear that your experience did not align with our commitment to customer satisfaction. ",
    msg2: "We will definitely improve our services.",
  },
  {
    name: "Satisfactory",
    emoji: stisFeedackSmall,
    msg1: "Thank you for your valuable feedback.",
    msg2: "We will surely improve and make things right for you in the near future.",
  },
  {
    name: "Very Good",
    emoji: loveFeedbackSmall,
    msg1: "Thank you for your valuable feedback.",
    msg2: "Keep choosing us !",
  },
  {
    name: "Excellent",
    emoji: loveFeedbackSmall,
    msg1: "Thank you for your valuable feedback.",
    msg2: "Keep choosing us !",
  },
];
export const CustomStar = (
  <path d="M52.939,75.941,51.1,69.462l-1.836,6.478ZM50.252,28.549a.935.935,0,0,1,1.7,0l6.574,14.5,15.819,1.772a.935.935,0,0,1,.526,1.62L63.119,57.17l3.2,15.592a.935.935,0,0,1-1.378,1L51.1,65.9,37.265,73.763a.935.935,0,0,1-1.378-1l3.2-15.592L27.333,46.438a.935.935,0,0,1,.526-1.62l15.819-1.772ZM30.116,62.041l5.59-3.754-6.728.264Zm43.134-3.57-6.729-.239,5.6,3.734ZM36.178,36.1l5.32,4.127-2.364-6.3Zm26.851-2.2L60.68,40.2l5.311-4.14Z" />
);
