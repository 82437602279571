import { Box } from "@mui/material";
import { ReactElement, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { Routes } from "../../../constant/routeContants";
import { GenericObject } from "../../../interfaces/commonInterface";
import {
  ICityList,
  IErrorState,
  IGenerateOtpPayload,
  InitialOtpState,
  InitialState,
  IResendOtpPayload,
  IVerifyOtpPayload,
} from "../../../interfaces/operatorInterface";
import en from "../../../locales/en.json";
import {
  getAllDistrictListAction,
  getOperatorCmsInfoAction,
  operatorLeadGenerationAction,
  operatorLeadVerificationAction,
  retryOtpForOperatorAction,
} from "../../../redux/actions";
import { RootState } from "../../../redux/store";
import {
  emailValidation,
  formatString,
  hindiTextValidation,
  mobileValidation,
} from "../../../utills/helper";
import { checkAlfa, checkNumeric } from "../../../utills/regex";
import BottomSection from "./sections/BottomSection";
import TopSection from "./sections/TopSection";

const initialState: InitialState = {
  name: "",
  phone: "",
  email: "",
  preferredCity: [],
  consent: false,
};

const initialErrorState: IErrorState = {
  name: false,
  phone: false,
  email: false,
  preferredCity: false,
  consent: false,
};

const initialOtpState: InitialOtpState = {
  phone: "",
  otp: "",
  referenceId: "",
  agreementId: "",
};

const OperatorRegistration = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clearOtpRef = useRef<any>(null);

  const { allDistrictList } = useSelector(
    (state: RootState) => state.operatorReducer
  );

  const [state, setState] = useState<InitialState>(initialState);
  const [errorState, setErrorState] = useState<IErrorState>(initialErrorState);
  const [filteredOptions, setFilteredOptions] = useState<ICityList[]>([]);
  const [show, setShow] = useState<boolean>(false); // OTP modal
  const [fields, setFields] = useState<InitialOtpState>({ ...initialOtpState });
  const [showModal, setShowModal] = useState<boolean>(false); // T&C modal
  const [isChecked, setIsChecked] = useState<boolean>(false); // T&C checkbox

  useEffect(() => {
    dispatch(getAllDistrictListAction()); // Fetch master district list
    dispatch(
      getOperatorCmsInfoAction({
        "populate[section1][populate][0]": "mainImage",
        "populate[section1][populate][1]": "cards",
        "populate[section1][populate][2]": "cards.icon",
        "populate[section2][populate][2]": "image",
        "populate[section3][populate][0]": "cards",
        "populate[section3][populate][1]": "cards.icon",
        "populate[section4][populate][1]": "image",
        "populate[section5][populate]": "*",
      })
    );
  }, []);

  useEffect(() => {
    if (allDistrictList?.length) {
      const tempList: ICityList[] = allDistrictList?.map(
        (item: string): ICityList => {
          return {
            name: item,
            displayName: formatString(item),
          };
        }
      );
      setFilteredOptions([...tempList]);
    }
  }, [JSON.stringify(allDistrictList)]);

  // Function to handle form field changes
  const handleChange = (key: string, value: any): void => {
    if (key === "preferredCity") {
      setState((preValue: InitialState) => ({
        ...preValue,
        [key]: value,
      }));
    } else if (key === "otp") {
      hindiTextValidation(value) &&
        setFields((prevState: InitialOtpState) => ({
          ...prevState,
          [key]: value,
        }));
    } else if (key === "name") {
      hindiTextValidation(value) &&
        checkAlfa(value) &&
        setState((prevState: InitialState) => ({
          ...prevState,
          [key]: value,
        }));
    } else if (key === "phone") {
      hindiTextValidation(value) &&
        checkNumeric(value) &&
        setState((prevState: InitialState) => ({
          ...prevState,
          [key]: value,
        }));
    } else {
      hindiTextValidation(value) &&
        setState((preValue: InitialState) => ({
          ...preValue,
          [key]: value,
        }));
    }
  };

  // Function to handle events after lead generation api success
  const leadGenerationApiSuccessFunction = (data?: GenericObject): void => {
    setFields((prev: InitialOtpState) => ({
      ...prev,
      referenceId: data?.referenceId,
      phone: data?.phone,
    }));
    setShow(true);
  };

  // Function to validate form fields
  const validateField = (state: any): boolean => {
    const errors: IErrorState | any = {};
    let isValid = true;

    const validate = (field: string, value: any) => {
      switch (field) {
        case "name":
          if (value.length < 3) {
            errors.name = en?.errorMessages?.invalidName;
            isValid = false;
          } else {
            errors.name = false;
          }
          break;

        case "phone":
          if (value.length < 10) {
            errors.phone = en?.errorMessages?.invalidPhone;
            isValid = false;
          } else if (!mobileValidation(value)) {
            errors.phone = en?.errorMessages?.mobileError;
            isValid = false;
          } else {
            errors.phone = false;
          }
          break;

        case "email":
          if (!emailValidation(value.trim())) {
            errors.email = en?.errorMessages?.invalidEmail;
            isValid = false;
          } else {
            errors.email = false;
          }
          break;

        default:
          break;
      }
    };

    // Validate each field
    Object.keys(state)?.forEach((field: any) => {
      validate(field, state[field]);
    });

    // Update the error state
    setErrorState((prev: IErrorState) => ({ ...prev, ...errors }));
    return isValid;
  };

  // Function to generate otp
  const generateOtpHandler = (): void => {
    if (validateField(state)) {
      // Proceed with form submission
      const payload: IGenerateOtpPayload = {
        data: {
          name: state?.name?.trim(),
          phone: state?.phone?.trim(),
          email: state?.email?.trim(),
          preferredCity: state?.preferredCity,
        },
      };
      dispatch(
        operatorLeadGenerationAction(payload, leadGenerationApiSuccessFunction)
      );
    }
  };

  // Function to check if submit button is disabled
  const isDisabled = (): boolean => {
    let { name, phone, email, preferredCity, consent } = state;
    //
    if (
      name.trim() &&
      phone.trim() &&
      phone?.length === 10 &&
      email.trim() &&
      preferredCity?.length &&
      Boolean(consent)
    ) {
      return false;
    } else {
      return true;
    }
  };

  // Function to handle events after otp resend api success
  const retryOtpForOperatorApiSuccessFunction = (): void => {};

  // Function to resend otp
  const handleResendOtp = (): void => {
    setFields({ ...fields, otp: "" });
    if (clearOtpRef?.current) {
      clearOtpRef?.current?.updateState([]);
    }
    //
    const payload: IResendOtpPayload = {
      data: { referenceId: fields?.referenceId },
    };
    dispatch(
      retryOtpForOperatorAction(payload, retryOtpForOperatorApiSuccessFunction)
    );
  };

  // Function to handle events after lead/ otp verification api success
  const leadVerificationApiSuccessFunction = (): void => {
    setIsChecked(false);
    setState(initialState);
    setShow(false);

    // Navigate to Home screen
    setTimeout(() => {
      navigate(Routes?.HOME);
    }, 100);
  };

  // Function to verify otp
  const validateOtpHandler = (): void => {
    const payload: IVerifyOtpPayload = {
      data: {
        otp: fields?.otp,
        referenceId: fields?.referenceId,
      },
    };
    dispatch(
      operatorLeadVerificationAction(
        payload,
        leadVerificationApiSuccessFunction
      )
    );
  };

  // Function to handle modal open/close
  const handleModal = (): void => setShowModal(!showModal);
  const handleModalClose = (event: any, reason: string): void => {
    if (reason && reason === "backdropClick") return;
    setFields({ ...fields, otp: "" });
    setShow(false);
  };

  // Handle user consent
  const handleAgree = (): void => {
    setState({ ...state, consent: true }); // User agreed
    setIsChecked(true); // Check the checkbox
    setShowModal(false); // Close modal
  };

  // Handle user disagreement
  const handleDisagree = (): void => {
    setState({ ...state, consent: false }); // User disagreed
    setIsChecked(false); // Uncheck the checkbox
    setShowModal(false); // Close modal
  };

  // Function to check if OTP submit button is enabled
  const isOtpSubmitBtnEnabled = (): boolean => {
    if (fields?.otp && fields?.otp?.length === 6) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Box>
      <Helmet>
        <title>{en?.global?.pageTitle}</title>
        <meta name={en?.global?.title} content={en?.global?.content} />
        <meta
          name={en?.global?.description}
          content={en?.global?.descriptionContent}
        />
      </Helmet>

      <TopSection
        state={state}
        filteredOptions={filteredOptions}
        isChecked={isChecked}
        showModal={showModal}
        fields={fields}
        show={show}
        generateOtpHandler={generateOtpHandler}
        handleChange={handleChange}
        isDisabled={isDisabled}
        handleModal={handleModal}
        handleDisagree={handleDisagree}
        handleAgree={handleAgree}
        validateOtpHandler={validateOtpHandler}
        handleResendOtp={handleResendOtp}
        isOtpSubmitBtnEnabled={isOtpSubmitBtnEnabled}
        handleModalClose={handleModalClose}
        errorState={errorState}
        ref={clearOtpRef}
      />

      <BottomSection />
    </Box>
  );
};

export default OperatorRegistration;
