import { YOR_ACTION } from "../actionTypes";

export const getAvailableVehicleYor = (payload: any) => ({
  type: YOR_ACTION.YOR_GET_AVAILABLE_VEHICLES_REQUESTED,
  payload,
});

//action for calculate charges
export const calculateCharges = (payload: any): any => {
  return {
    type: YOR_ACTION.CALCULATE_CHARGES_REQUESTED,
    payload,
  };
};

//action for get available plans
export const getAvailablePlans = (payload: any): any => {
  return {
    type: YOR_ACTION.PLAN_AVAILABLES_REQUESTED,
    payload,
  };
};

//action for store booking
export const storeBooking = (payload: any): any => {
  return {
    type: YOR_ACTION.STORE_BOOKING_REQUESTED,
    payload,
  };
};

//action for create order
export const createOrder = (payload: any): any => {
  return {
    type: YOR_ACTION.CREATE_ORDER_REQUESTED,
    payload,
  };
};
// action to filter the available vehicle on basic of vehicle type
export const filterVehicleModel = (payload: any): any => ({
  type: YOR_ACTION.FILTER_VEHICLE_MODEL_RENTING_FLEET,
  payload,
});

// action to filter the available vehicle on basic of vehicle type
export const filterVehicleType = (payload: any): any => ({
  type: YOR_ACTION.FILTER_VEHICLE_TYPE_RENTING_FLEET,
  payload,
});

//action for getting payment status
export const getPaymentStatus = (payload: any): any => {
  return {
    type: YOR_ACTION.GET_PAYMENT_STATUS_REQUESTED,
    payload,
  };
};

//clear order data
export const clearOrderData = () => {
  return {
    type: YOR_ACTION.CLEAR_ORDER_DATA,
  };
};
// action to get city V2
export const getCityV2 = (payload: any): any => ({
  type: YOR_ACTION.GET_CITY_V2_REQUESTED,
  payload,
});

// set user city yor
export const setUserCityYor = (payload: any): any => ({
  type: YOR_ACTION.SET_USER_CITY_YOR,
  payload,
});

//ACTION TO SET USER BOOKING DATA YOR
export const setUserBookingDataYor = (payload: any): any => ({
  type: YOR_ACTION.SET_YOR_BOOKING_DATA,
  payload,
});

//get yor bookings
export const getYorBookingDetails = (payload: any): any => ({
  type: YOR_ACTION.GET_YOR_BOOKINGS_DETAILS_REQUESTED,
  payload,
});

// Sort yor renting fleet
export const sortYorRentingFleet = (payload: any) => ({
  type: YOR_ACTION.SORT_YOR_RENTING_FLEET,
  payload,
});

//action to get all coupons
export const getAllCoupons = (payload: any): any => ({
  type: YOR_ACTION.GET_ALL_COUPONS_REQUESTED,
  payload,
});

//action for cancelled booking
export const cancelledBooking = (payload: any): any => ({
  type: YOR_ACTION.CANCELLED_BOOKING_REQUESTED,
  payload,
});

// action for yor cms data
export const getYorCmsData = (): any => ({
  type: YOR_ACTION.GET_YOR_HOME_SCREEN_DATA_REQUESTED,
});
