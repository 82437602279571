import { createTheme, useMediaQuery } from "@mui/material";
import { green } from "@mui/material/colors";
import { useMemo } from "react";
import { colors } from "../themes/colors";
import { accordionCustomization } from "./customizations/accordionCustomization";
import { autoCompleteCustomization } from "./customizations/autoCompleteCustomization";
import { chipCustomizations } from "./customizations/chipCustomization";
import { inputTextCustomizations } from "./customizations/inputTextCustomisation";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    poster: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    poster?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    poster: true;
    XLheading: true;
    heading: true;
    headingNoPadding: true;
    subheading: true;
    body1Italic: true;
    body1: true;
    errorMessage1: true;
    smallText: true;
    extraSmall: true;
  }
}

const defaultTheme = createTheme();
const lightMode = {
  primary: {
    main: colors.THEME_BLUE,
    contrastText: colors.white,
  },
  secondary: {
    main: green[500],
  },
  badge: {
    main: colors.bg_btn_color,
  },
  background: {
    paper: colors.white,
    // default: colors.THEME_BLUE,
  },
  drawerMenu: {
    background: {
      main: colors.black,
    },
    text: {
      primary: colors.text_black,
      secondary: colors.text_gray,
    },
  },
};
const darkMode = {};

const typography = {
  fontFamily: "Euclid Circular B, Helvetica, Arial, sans-serif",
  h1: {
    fontSize: defaultTheme.typography.pxToRem(48),
    fontWeight: 600,
    lineHeight: 1.2,
    letterSpacing: -0.5,
  },
  h2: {
    fontSize: defaultTheme.typography.pxToRem(36),
    fontWeight: 600,
    lineHeight: 1.2,
  },
  h3: {
    fontSize: defaultTheme.typography.pxToRem(30),
    lineHeight: 1.2,
  },
  h4: {
    fontSize: defaultTheme.typography.pxToRem(24),
    fontWeight: 600,
    lineHeight: 1.5,
  },
  h5: {
    fontSize: defaultTheme.typography.pxToRem(20),
    fontWeight: 600,
  },
  h6: {
    fontSize: defaultTheme.typography.pxToRem(18),
    fontWeight: 600,
  },
  subtitle1: {
    fontSize: defaultTheme.typography.pxToRem(16),
    fontWeight: 500,
  },
  subtitle2: {
    fontSize: defaultTheme.typography.pxToRem(14),
    fontWeight: 500,
  },
  body1: {
    fontSize: defaultTheme.typography.pxToRem(14),
    fontWeight: 400,
  },
  body2: {
    fontSize: defaultTheme.typography.pxToRem(14),
    fontWeight: 500,
  },
  caption: {
    fontSize: defaultTheme.typography.pxToRem(12),
    fontWeight: 400,
  },
  errorMessage1: {
    fontWeight: 400,
    color: colors.error_red,
    fontSize: defaultTheme.typography.pxToRem(12),
  },
  smallText: {
    lineHeight: "10px",
    fontSize: defaultTheme.typography.pxToRem(10),
    fontWeight: 400,
  },
  extraSmall: {
    lineHeight: "12px",
    fontSize: defaultTheme.typography.pxToRem(8),
    fontWeight: 400,
  },
};

const customComponents = {
  MuiButton: {
    styleOverrides: {
      root: {
        variants: [
          {
            props: {
              variant: "contained",
              color: "primary",
            },
            style: {
              width: "100%",
              textTransform: "none",
              border: "none",
              background: "linear-gradient(90deg, #1BD8DA 100%, #1BC4A6F2 95%)",
              color: "#fff",
              borderRadius: "60px",
              minHeight: "40px",
              maxHeight: "50px",
              fontSize: "14px",
              transition: "background-image 0.3s ease-in-out",
              padding: "7px 45px",
              gap: 8,
              boxShadow: "unset",
              "&:hover": {
                background: "#1BD8DA",
              },
              "&:active": {
                background:
                  "linear-gradient(90deg, #1BD8DA 100%, #1BC4A6F2 95%)",
                boxShadow: "inset 0px 0px 20px rgba(238, 238, 238, 0.25)",
              },
              "&.Mui-disabled": {
                background: "#eaeaea",
                color: "#c0c0c0",
              },
            },
          },
          {
            props: {
              variant: "outlined",
              color: "primary",
            },
            style: {
              width: "100%",
              textTransform: "none",
              alignSelf: "stretch",
              padding: "7px 45px",
              background: "linear-gradient(0deg,  0%,  100%)",
              borderWidth: "1.50px",
              borderRadius: "60px",
              minHeight: "40px",
              maxHeight: "50px",
              gap: 8,
              boxShadow: "unset",
              display: "inline-flex",
              // transition: "background-image 0.3s ease-in-out",
              "&:hover": {
                background:
                  "linear-gradient(90deg, #1BD8DA 100%, #1BC4A6F2 95%)",
                color: "white",
                border: "none",
              },
              "&:active": {
                background:
                  "linear-gradient(90deg, #1BD8DA 100%, #1BC4A6F2 95%)",
                boxShadow: "0px 0px 20px rgba(238, 238, 238, 0.25)",
                color: "white",
              },
            },
          },
          {
            props: {
              variant: "text",
              color: "primary",
            },
            style: {
              textTransform: "none",
              alignSelf: "stretch",
              padding: "8px 14px",
              background: "linear-gradient(0deg,  0%,  100%)",
              borderRadius: 10,
              justifyContent: "center",
              alignItems: "center",
              gap: 8,
              boxShadow: "unset",
              display: "inline-flex",
              "&:hover": {
                alignSelf: "stretch",
                background:
                  "linear-gradient(90deg, rgba(1, 53, 61, 0.24) 0%, rgba(43, 199, 201, 0.24) 100%)",
              },
              "&:active": {
                alignSelf: "stretch",
                background:
                  "linear-gradient(0deg,  0%,  100%), linear-gradient(90deg, rgba(1, 53, 61, 0.24) 0%, rgba(43, 199, 201, 0.24) 60%)",
                boxShadow: "0px 0px 20px rgba(238, 238, 238, 0.25)",
              },
            },
          },
        ],
      },
    },
  },
  /*  MuiTypography: {
    defaultProps: {
      variantMapping: {
        // Map the new variant to render a <h1> by default
        heading1: 'h1',
        XLheading: 'h6',
        heading: 'h2',
        headingNoPadding: 'h2',
        subheading: 'subtitle1',
        body1Italic: 'body1',
        body1: 'body1',
        errorMessage1: 'caption',
        smallText: 'caption',
        extraSmall: 'caption',
      },
    },
  }, */
  MuiDataGrid: {
    styleOverrides: {
      root: {
        ".MuiDataGrid-columnHeader": {
          color: colors.white,
          "& .MuiCheckbox-root svg": {
            color: "white",
          },
          "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg":
            {
              color: "white",
            },
        },
        "&::-webkit-scrollbar": {
          height: "1px",
          backgroundColor: "#f5f5f5",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "6px",
          backgroundColor: "#ccc",
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
        },
        "&::-webkit-scrollbar-track": {
          borderRadius: "6px",
          backgroundColor: "#f5f5f5",
        },
        "& .MuiDataGrid-row:hover": {
          cursor: "pointer",
        },
        "& .MuiDataGrid-iconSeparator": {
          display: "none",
        },
      },
    },
  },
};

export function useThemeMode() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  /* const { mode } = useSelector((state: RootState) => state.themeReducer); */
  const mode = "light";

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          ...(mode === "light" ? lightMode : darkMode),
        },
        shape: { borderRadius: 16 },
        // ...typographyObj,
        typography: { ...typography },
        components: {
          ...customComponents,
          ...chipCustomizations,
          ...inputTextCustomizations,
          ...accordionCustomization,
          ...autoCompleteCustomization,
        },
      }),
    [mode]
  );

  return theme;
}
