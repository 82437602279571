export enum ACTIONS {
  LOCALE = "LOCALE",
  SERVER_ERROR = "SERVER_ERROR",
  AUTH_ISSUE = "AUTH_ISSUE",
}

export enum AUTH_ACTIONS {
  SET_USER_TOKEN = "SET_USER_TOKEN",
  SET_USER_DATA_REQUESTED = "SET_USER_DATA_REQUESTED",
  SET_USER_DATA = "SET_USER_DATA",
  SET_USER_DATA_COMPLETED = "SET_USER_DATA_COMPLETED",
  LOGOUT = "LOGOUT",
  LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
  LOGOUT_FAILED = "LOGOUT_FAILED",

  USER_LOGIN_REQUESTED = "USER_LOGIN_REQUESTED",
  USER_LOGIN_SUCCEEDED = "USER_LOGIN_SUCCEEDED",
  USER_LOGIN_FAILED = "USER_LOGIN_FAILED",

  OTP_VERIFICATION_REQUESTED = "OTP_VERIFICATION_REQUESTED",
  OTP_VERIFICATION_SUCCEEDED = "OTP_VERIFICATION_SUCCEEDED",
  OTP_VERIFICATION_FAILED = "OTP_VERIFICATION_FAILED",

  USER_SIGNUP_REQUESTED = "USER_SIGNUP_REQUESTED",
  USER_SIGNUP_SUCCEEDED = "USER_SIGNUP_SUCCEEDED",
  USER_SIGNUP_FAILED = "USER_SIGNUP_FAILED",

  SENT_OTP_FOR_SIGNUP_REQUESTED = "SENT_OTP_FOR_SIGNUP_REQUESTED",
  SENT_OTP_FOR_SIGNUP_SUCCEEDED = "SENT_OTP_FOR_SIGNUP_SUCCEEDED",
  SENT_OTP_FOR_SIGNUP_FAILED = "SENT_OTP_FOR_SIGNUP_FAILED",

  RESEND_OTP_REQUESTED = "RESEND_OTP_REQUESTED",
  RESEND_OTP_SUCCEEDED = "RESEND_OTP_SUCCEEDED",
  RESEND_OTP_FAILED = "RESEND_OTP_FAILED",
  CLOSE_OTP_SCREEN = "CLOSE_OTP_SCREEN",

  UNDER_MAINTENANCE = "UNDER_MAINTENANCE",
}

export enum HOME_ACTIONS {
  GET_PACKAGE_MASTERS = "GET_PACKAGE_MASTERS",
  SET_PACKAGE_MASTERS = "SET_PACKAGE_MASTERS",
  GET_VEHICLE_LIST = "GET_VEHICLE_LIST",
  SET_VEHICLE_LIST = "SET_VEHICLE_LIST",
  SET_VEHICLE_LIST_LOADER = "SET_VEHICLE_LIST_LOADER",
  GET_CITY_LIST_MASTERS = "GET_CITY_LIST_MASTERS",
  SET_CITY_LIST_MASTERS = "SET_CITY_LIST_MASTERS",
  SET_BASIC_FILTER = "SET_BASIC_FILTER",
  GET_CAROUSEL_DATA = "GET_CAROUSEL_DATA ",
  SET_CAROUSEL_DATA = "SET_CAROUSEL_DATA ",
  SET_SELECTED_CITY = "SET_SELECTED_CITY",
  SET_COUPAN_DATA = "SET_COUPAN_DATA",
  GET_COUPAN_DATA = "GET_COUPAN_DATA",
  GET_FAQ_LIST = "GET_FAQ_LIST",
  SET_FAQ_LIST = "SET_FAQ_LIST",
  GET_NEAREST_CITY = "GET_NEAREST_CITY",
  GET_NEAREST_CITY_REQUESTED = " GET_NEAREST_CITY_REQUESTED",
  GET_NEAREST_CITY_SUCCESS = " GET_NEAREST_CITY_SUCCESS",
  TOGGLE_DETECTION_LOADER = "TOGGLE_DETECTION_LOADER",
  GET_NEAREST_CITY_FAILED = " GET_NEAREST_CITY_FAILED",
  ZOHO_UPCOMING_CITY_LEAD_REQUESTED = "ZOHO_UPCOMING_CITY_LEAD_REQUESTED",
  ZOHO_UPCOMING_CITY_LEAD_SUCCESS = "ZOHO_UPCOMING_CITY_LEAD_SUCCESS",
  ZOHO_UPCOMING_CITY_LEAD_FAILED = "ZOHO_UPCOMING_CITY_LEAD_FAILED",
  SET_FILTER_TYPE = "SET_FILTER_TYPE",
  SET_DEFAULT_CITY = "SET_DEFAULT_CITY",
  TOGGLE_CITY_MODAL = "TOGGLE_CITY_MODAL",
  TOGGLE_JOURNEY_MODAL = "TOGGLE_JOURNEY_MODAL",
}

export enum RENT_ACTIONS {
  GET_VEHICLE_TYPE = "GET_VEHICLE_TYPE",
  SET_VEHICLE_TYPE = "SET_VEHICLE_TYPE",
}
export enum CHECKOUT_ACTIONS {
  GET_COUPON_MASTERS = "GET_COUPON_MASTERS",
  SET_COUPON_MASTERS = "SET_COUPON_MASTERS",
  GET_BRANCH_SLOTS = "GET_BRANCH_SLOTS",
  SET_BRANCH_SLOTS = "SET_BRANCH_SLOTS",
  GET_HOME_DELIVERY_CHARGES = "GET_HOME_DELIVERY_CHARGES",
  SET_HOME_DELIVERY_CHARGES = "SET_HOME_DELIVERY_CHARGES",
  HOME_DELIVERY_NOT_AVAILABLE = "HOME_DELIVERY_NOT_AVAILABLE",
  GET_HOME_ADDRESS_LIST = "GET_HOME_ADDRESS_LIST",
  SET_HOME_ADDRESS_LIST = "SET_HOME_ADDRESS_LIST",
  SET_VEHICLE_DATA = "SET_VEHICLE_DATA",
  GET_VEHICLE_DATA = "GET_VEHICLE_DATA",
  SET_CREATE_BOOKING_SUCCESS = "SET_CREATE_BOOKING_SUCCESS",
  SET_CREATE_BOOKING_FAILED = "SET_CREATE_BOOKING_FAILED",
  POST_CREATE_BOOKING = "POST_CREATE_BOOKING",
  POST_PROCESS_ORDER = "POST_PROCESS_ORDER",
  POST_PAYMENT_VERIFY = "POST_PAYMENT_VERIFY",
  SET_BOOKING_DATA = "SET_BOOKING_DATA",
  VALIDATE_USER_EMERGENCY_DETAILS_REQUESTED = "VALIDATE_USER_EMERGENCY_DETAILS_REQUESTED",
  VALIDATE_USER_EMERGENCY_DETAILS_SUCCEED = "VALIDATE_USER_EMERGENCY_DETAILS_SUCCEED",
  VALIDATE_USER_EMERGENCY_DETAILS_FAILED = "VALIDATE_USER_EMERGENCY_DETAILS_FAILED",
  TOGGLE_HOME_DELIVERY_AVAILABLE = "TOGGLE_HOME_DELIVERY_AVAILABLE",
}
export enum PROFILE_ACTIONS {
  GET_BOOKING_LIST = "GET_BOOKING_LIST",
  SET_BOOKING_LIST = "SET_BOOKING_LIST",
  SET_BOOKING_ID = "SET_BOOKING_ID",
  GET_BOOKING_DETAILS_BY_ID = "GET_BOOKING_DETAILS_BY_ID",
  SET_BOOKING_DETAILS_BY_ID = "SET_BOOKING_DETAILS_BY_ID",
  GET_USER_PROFILE = "GET_USER_PROFILE",
  SET_USER_PROFILE = "SET_USER_PROFILE",
  POST_UPDATE_PROFILE = "POST_UPDATE_PROFILE",
  SET_EXTEND_DETAILS = "SET_EXTEND_DETAILS",
  GET_EXTEND_DETAILS = "GET_EXTEND_DETAILS",
  GET_CURRENT_BOOKING = "GET_CURRENT_BOOKING",
  SET_CURRENT_BOOKING = "SET_CURRENT_BOOKING",
  GET_USER_KYC_STATUS = "GET_USER_KYC_STATUS",
  SET_USER_KYC_STATUS = "SET_USER_KYC_STATUS",
  UPLOAD_IMAGES_TO_S3 = "UPLOAD_IMAGES_TO_S3",
  GET_ADDITIONAL_DOCS_STATUS = "GET_ADDITIONAL_DOCS_STATUS",
  SET_ADDITIONAL_DOCS_STATUS = "SET_ADDITIONAL_DOCS_STATUS",
  CREATE_ADDITIONAL_DOC = "CREATE_ADDITIONAL_DOC",
  GET_EXTEND_PACKAGE = "GET_EXTEND_PACKAGE",
  SET_EXTEND_PACKAGE = "SET_EXTEND_PACKAGE",
  SET_PRICE_DETAILS_BY_ID = "SET_PRICE_DETAILS_BY_ID",
  GET_PRICE_DETAILS_BY_ID = "GET_PRICE_DETAILS_BY_ID",
  END_RIDE = "END_RIDE",
  SEND_OTP_FOR_EMERGENCY_NUMBER = "SEND_OTP_FOR_EMERGENCY_NUMBER",
  VERIFY_OTP_FOR_EMERGENCY_NUMBER = "VERIFY_OTP_FOR_EMERGENCY_NUMBER",
  GET_DOCUMENT_DETAILS_BY_ID = "GET_DOCUMENT_DETAILS_BY_ID",
  SET_DOCUMENT_DETAILS_BY_ID = "SET_DOCUMENT_DETAILS_BY_ID",
  GET_BRANCH = "GET_BRANCH",
  EXTEND_RIDE = "EXTEND_RIDE",
  ODOMETER_READING = "ODOMETER_READING",
  PAY_EXTRA_CHARGES = "PAY_EXTRA_CHARGES",
  GET_MITC_LIST = "GET_MITC_LIST",
  SET_EXTEND_RIDE_DATA = "SET_EXTEND_RIDE_DATA",
  RESET_EXTEND_RIDE_DATA = "RESET_EXTEND_RIDE_DATA",
  SET_PAGINATION_DATA = "SET_PAGINATION_DATA",
  CLEAR_PAGINATION_DATA = "CLEAR_PAGINATION_DATA",
  GET_CANCELLATION_CHARGES = "GET_CANCELLATION_CHARGES",
  SET_CANCELLATION_CHARGES = "SET_CANCELLATION_CHARGES",
  CANCLE_BOOKING = "CANCLE_BOOKING",
  CHECK_REFUND_STATUS = "CHECK_REFUND_STATUS",
  SET_CANCLE_LOADER = "SET_CANCLE_LOADER",
  CANCEL_SUCCESS = "CANCEL_SUCCESS",
  SET_REFUND_STATUS = "SET_REFUND_STATUS",
  GET_BOOKING_STATUS = "GET_BOOKING_STATUS",
  SET_BOOKING_STATUS = "SET_BOOKING_STATUS",
  SET_BOOKING_LIST_LOADER = "SET_BOOKING_LIST_LOADER",
  GET_MODIFICATION_CHARGES = "GET_MODIFICATION_CHARGES",
  SET_MODIFICATION_CHARGES = "SET_MODIFICATION_CHARGES",
  GET_MODEL_AVAILABLE_DATES = "GET_MODEL_AVAILABLE_DATES",
  SET_MODEL_AVAILABLE_DATES = "SET_MODEL_AVAILABLE_DATES",
  MODIFY_BOOKING = "MODIFY_BOOKING",
  SET_MODIFY_BOOKING_STATE = "SET_MODIFY_BOOKING_STATE",
  CLEAR_MODIFY_BOOKING_STATE = "CLEAR_MODIFY_BOOKING_STATE",
  GET_BOOKING_CHANGE_CHARGES = "GET_BOOKING_CHANGE_CHARGES",
  SET_BOOKING_CHANGE_CHARGES = "SET_BOOKING_CHANGE_CHARGES",
  GET_QUERIES_LIST = "GET_QUERIES_LIST",
  SET_QUERIES_LIST = "SET_QUERIES_LIST",
}
export enum GLOBAL_ACTION {
  POST_CONTACT_US = "POST_CONTACT_US",
  SET_ADDRESS = "SET_ADDRESS",
  DELETE_ADDRESS = "DELETE_ADDRESS",
  GET_ADVERTISMENT = "GET_ADVERTISMENT",
  SET_ADVERTISMENT = "SET_ADVERTISMENT",
  SET_ENQUIRY = "SET_ENQUIRY",
  GET_VEHICLE_DETAILS = "GET_VEHICLE_DETAILS",
  GET_MITC_CONTENT = "GET_MITC_CONTENT",
  SEND_OTP_MITC = "SEND_OTP_MITC",
  VERIFY_MITC = "VERIFY_MITC",
  SET_LOCATION_LOADER = "SET_LOCATION_LOADER",
  RESEND_OTP_MITC = "RESEND_OTP_MITC",
  SET_LOCATION_VEHICLE_LIST = "SET_LOCATION_VEHICLE_LIST",
  SHOW_API_SUCCEED_MODAL = "SHOW_API_SUCCEED_MODAL",
  SHOW_API_FAILED_MODAL = "SHOW_API_FAILED_MODAL",
  SET_MODAL_MESSAGE = "SET_MODAL_MESSAGE",
  CLEAR_API_STATUS = "CLEAR_API_STATUS",
}

export enum DELETE_ACCOUNT_ACTION {
  GET_DEL_ACC_OPTIONS_REQUESTED = "GET_DEL_ACC_OPTIONS_REQUESTED",
  GET_DEL_ACC_OPTIONS_SUCCESS = "GET_DEL_ACC_OPTIONS_SUCCESS",
  GET_DEL_ACC_OPTIONS_FAILED = "GET_DEL_ACC_OPTIONS_FAILED",

  SEND_OTP_FOR_DEL_ACC_REQUESTED = "SEND_OTP_FOR_DEL_ACC_REQUESTED",
  SEND_OTP_FOR_DEL_ACC_SUCCEEDED = "SEND_OTP_FOR_DEL_ACC_SUCCEEDED",
  SEND_OTP_FOR_DEL_ACC_FAILED = "SEND_OTP_FOR_DEL_ACC_FAILED",

  CHANGE_ACC_STATUS_REQUESTED = "CHANGE_ACC_STATUS_REQUESTED",
  CHANGE_ACC_STATUS_SUCCEEDED = "CHANGE_ACC_STATUS_SUCCEEDED",
  CHANGE_ACC_STATUS_FAILED = "CHANGE_ACC_STATUS_FAILED",
}

export enum OPERATOR_ACTIONS {
  // Master City List
  GET_ALL_DISTRICT_REQUESTED = "GET_ALL_DISTRICT_REQUESTED",
  GET_ALL_DISTRICT_SUCCEEDED = "GET_ALL_DISTRICT_SUCCEEDED",
  GET_ALL_DISTRICT_FAILED = "GET_ALL_DISTRICT_FAILED",

  // Lead Generation
  OPERATOR_LEAD_GENERATION_REQUESTED = "OPERATOR_LEAD_GENERATION_REQUESTED",
  OPERATOR_LEAD_GENERATION_SUCCEEDED = "OPERATOR_LEAD_GENERATION_SUCCEEDED",
  OPERATOR_LEAD_GENERATION_FAILED = "OPERATOR_LEAD_GENERATION_FAILED",

  // Lead Verification
  OPERATOR_LEAD_VERIFICATION_REQUESTED = "OPERATOR_LEAD_VERIFICATION_REQUESTED",
  OPERATOR_LEAD_VERIFICATION_SUCCEEDED = "OPERATOR_LEAD_VERIFICATION_SUCCEEDED",
  OPERATOR_LEAD_VERIFICATION_FAILED = "OPERATOR_LEAD_VERIFICATION_FAILED",

  // Retry OTP For Operator
  RETRY_OTP_FOR_OPERATOR_REQUESTED = "RETRY_OTP_FOR_OPERATOR_REQUESTED",
  RETRY_OTP_FOR_OPERATOR_SUCCEEDED = "RETRY_OTP_FOR_OPERATOR_SUCCEEDED",
  RETRY_OTP_FOR_OPERATOR_FAILED = "RETRY_OTP_FOR_OPERATOR_FAILED",

  // Verify Operator Email Address
  VERIFY_OPERATOR_EMAIL_REQUESTED = "VERIFY_OPERATOR_EMAIL_REQUESTED",
  VERIFY_OPERATOR_EMAIL_SUCCEEDED = "VERIFY_OPERATOR_EMAIL_SUCCEEDED",
  VERIFY_OPERATOR_EMAIL_FAILED = "VERIFY_OPERATOR_EMAIL_FAILED",

  // Fetch ESign Doc
  FETCH_ESIGN_DOC_REQUESTED = "FETCH_ESIGN_DOC_REQUESTED",
  FETCH_ESIGN_DOC_SUCCEEDED = "FETCH_ESIGN_DOC_SUCCEEDED",
  FETCH_ESIGN_DOC_FAILED = "FETCH_ESIGN_DOC_FAILED",

  // ESign Generate OTP
  ESIGN_GENERATE_OTP_REQUESTED = "ESIGN_GENERATE_OTP_REQUESTED",
  ESIGN_GENERATE_OTP_SUCCEEDED = "ESIGN_GENERATE_OTP_SUCCEEDED",
  ESIGN_GENERATE_OTP_FAILED = "ESIGN_GENERATE_OTP_FAILED",

  // ESign Verify OTP
  ESIGN_VERIFY_OTP_REQUESTED = "ESIGN_VERIFY_OTP_REQUESTED",
  ESIGN_VERIFY_OTP_SUCCEEDED = "ESIGN_VERIFY_OTP_SUCCEEDED",
  ESIGN_VERIFY_OTP_FAILED = "ESIGN_VERIFY_OTP_FAILED",

  // ESign Resend OTP
  ESIGN_RESEND_OTP_REQUESTED = "ESIGN_RESEND_OTP_REQUESTED",
  ESIGN_RESEND_OTP_SUCCEEDED = "ESIGN_RESEND_OTP_SUCCEEDED",
  ESIGN_RESEND_OTP_FAILED = "ESIGN_RESEND_OTP_FAILED",

  // Fetch Operator CMS Information
  FETCH_OPERATOR_CMS_INFO_REQUESTED = "FETCH_OPERATOR_CMS_INFO_REQUESTED",
  FETCH_OPERATOR_CMS_INFO_SUCCEEDED = "FETCH_OPERATOR_CMS_INFO_SUCCEEDED",
  FETCH_OPERATOR_CMS_INFO_FAILED = "FETCH_OPERATOR_CMS_INFO_FAILED",
}
