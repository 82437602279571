// This is the css style objects that will be provided to "sx" prop of the mui components.

import { squareTexture } from "../../../../assets";
import { GenericObject } from "../../../../interfaces/commonInterface";
import { colors } from "../../../../themes/colors";

export const StyleObject: GenericObject = {
  textAlignCenter: { textAlign: "center" },
  cursorPointer: { cursor: "pointer" },
  customInputStyle: { boxShadow: "unset", minHeight: "50px" },
  muiTextFieldRootStyle: { width: 1, minWidth: "unset" },
  alignItemsCenter: { alignItems: "center" },
  boxTop: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardTop: {
    minWidth: 358,
    maxWidth: 358,
    minHeight: 220,
    maxHeight: 220,
    marginRight: 2,
    borderRadius: "24px",
    boxShadow: 0,
    background:
      "transparent linear-gradient(180deg, #fdffff 0%, #f2fcff 100%) 0% 0% no-repeat",
  },
  customBtn: {
    color: colors?.white,
    borderRadius: 20,
    width: 1,
    height: 50,
  },

  // ********** Operator Registration Section **********
  topSectionStack: {
    backgroundImage: `url(${squareTexture})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  mainImg: {
    height: "600px",
    width: "100%",
    padding: "unset",
  },
  joinUsFormTopBox: { mx: "auto" },
  joinUsFormSecondBox: {
    mx: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 2,
  },
  joinUsButton: {
    borderRadius: 20,
    width: 1,
    height: 50,
    zIndex: 99,
  },
  tncBox: { display: "flex", alignItems: "center" },
  dialogPaperProps: { borderRadius: "2px", padding: "8px" },
  yourAgreementText: { fontWeight: 600, pb: 1 },
  dialogDisagreeBtn: { color: colors?.error, borderRadius: "5px" },
  dialogAgreementBtn: { borderRadius: "5px" },
  dialogStyle: { mx: "auto" },
  dialogContentStyle: {
    height: 300,
    maxHeight: 300, // Set max height for scrollable content
    overflowY: "auto", // Enable vertical scrolling
    "&::-webkit-scrollbar": {
      width: "4px", // Set the scrollbar width
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: colors?.THEME_BLUE, // Set scrollbar thumb color
      borderRadius: "8px", // Make the scrollbar thumb rounded
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: colors?.THEME_BLUE, // Thumb color on hover
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: colors?.light_gray, // Set the track background color
    },
  },

  // ********** Top Horizontal Carousel Section **********
  carouselTopBox: { height: 140 },
  gridContainer: { position: "absolute", width: 1 },
  scrollableBox: {
    display: "flex",
    overflowX: "scroll", // Use scroll for functionality but hide scrollbar
    padding: "16px",
    scrollbarWidth: "none", // Hide scrollbar in Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Hide scrollbar in WebKit browsers (Chrome, Safari)
    },
  },
  cardStyle: {
    minWidth: 290,
    maxWidth: 290,
    minHeight: 180,
    maxHeight: 180,
    marginRight: 2,
    borderRadius: "4px",
    boxShadow: 0,
    backgroundColor: colors?.lotion_white,
    "& .MuiCardHeader-root": { padding: "unset" },
    "& .MuiCardContent-root": { padding: "unset" },
    "& .MuiTypography-h6": { padding: "unset" },
    padding: "32px",
  },
  imageIconStyle: {
    width: 44,
    height: 44,
    padding: "unset",
  },
  thcTruncatedTitle: {
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "18px",
    fontWeight: 500,
    color: colors?.shadow_gray,
    padding: "5px 0px",
  },
  thcTruncatedDescription: {
    display: "-webkit-box",
    WebkitLineClamp: 2, // Show up to 2 lines
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "14px",
    fontWeight: 400,
    color: colors?.spanish_gray,
    lineHeight: "18px",
  },

  // ********** Freedo Reimagined & How to Join a Fleet Section **********
  wrapperBox: {
    py: 5,
    background:
      "transparent linear-gradient(90deg, #effffb 0%, #fffffe 100%) 0% 0% no-repeat",
  },
  freedoReimaginedStack: { alignItems: "center" },
  howToJoinText: {
    textAlign: "center",
    pt: 5,
    pb: 2,
    px: "5%",
    fontWeight: 500,
  },
  howToJoinFleetCard: {
    minWidth: 358,
    maxWidth: 358,
    minHeight: 220,
    maxHeight: 220,
    marginRight: 2,
    borderRadius: "24px",
    boxShadow: "0 0 1px rgba(0, 0, 0, 0.1)",
    background:
      "transparent linear-gradient(180deg, #fdffff 0%, #f2fcff 100%) 0% 0% no-repeat",
    "& .MuiCardHeader-root": { padding: "unset" },
    "& .MuiCardContent-root": { padding: "unset" },
    "& .MuiTypography-h6": { padding: "unset" },
    padding: "24px",
  },
  howToJoinFleetCardIconImg: {
    width: 44,
    height: 44,
    padding: "unset",
    borderRadius: "40px",
  },
  htjfTruncatedTitle: {
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 500,
    padding: "5px 0px",
    fontSize: "20px",
    color: colors?.eigengrau,
  },
  htjfTruncatedDescription: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 400,
    WebkitLineClamp: 4,
    fontSize: "16px",
    lineHeight: "20px",
    color: colors?.dark_charcoal,
  },

  // ********** Make Money By Renting Out Your Vehicle Section **********
  boxxTop: {
    minHeight: "400px", // Full viewport height
    // backgroundImage: `url(${bannerImageOperatorModule})`, // Background image
    backgroundSize: "cover", // Cover the entire box
    backgroundPosition: "center", // Center the image
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", // Vertically center
    alignItems: "center", // Horizontally center
    textAlign: "center",
    color: "#fff", // White text for visibility
    padding: "50px",
  },

  // ********** Faq Section **********
  faqBoxStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background:
      "transparent linear-gradient(90deg, #effffb 0%, #fffffe 100%) 0% 0% no-repeat",
    padding: "50px 0",
  },
  faqText: {
    fontSize: "32px",
    borderBottom: `4px solid ${colors?.primary}`,
    paddingBottom: "3px",
    marginBottom: "50px",
    color: colors?.dark_jungle_green,
  },
  accordianStyle: {
    marginBottom: "15px",
    borderRadius: "10px",
    backgroundColor: colors?.azure_white,
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
  },
  accordionSummaryStyle: {
    backgroundColor: colors?.azure_white,
    padding: "10px 15px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  accordionTitle: {
    fontSize: "20px",
    fontWeight: 500,
    color: colors?.dark_jungle_green,
  },
  accordionDescription: {
    fontSize: "16px",
    fontWeight: 500,
    color: colors?.slate_gray,
  },
};
