import config from "./config.json";
import { NODE_ENV } from "./envConfig";
const configEnv: any = config;
const env: any = NODE_ENV;
const BASE_URL = configEnv[env]["BASE_URL"];
const WEB_URL = configEnv[env]["WEB_URL"];
const BASE_URL_CUSTOMER = configEnv[env]["BASE_URL_CUSTOMER"];
const CMS_URL = configEnv[env]["CMS_URL"];
const oldVersion = "v1/";
const versioning = "v2/";
const LatestVersion = "v5/";
const kyc = "kyc/";
const RAZORPAY_KEY = configEnv[env]["RAZOR_PAY_KEY"];
const FeedbackAPIExtenstion = "/dragon-buzz/";
const aliasYor: string = "/freedo-r2o/";
const aliasFreedoBiz: string = "/freedo-biz/";
const aliasMunim: string = "/freedo-munim/";
const aliasCms: string = "/cms/api/"; // alias for cms data

const API_URLS = {
  sendOTP: "sendOTP",
  verifyOTP: "verifyOTP",
  getMaster: "getMaster",
  packageMaster: "package_master",
  getVehicleModelListForCustomer: "vehicle/model-list",
  getCityList: "city/0",
  getCityById: "getCityById",
  getBookingList: "booking/",
  getBookingDetailsById: "booking/",
  vehicleType: "vehicle_type",
  sendOtpForSignUp: "sendOtpForSignUp",
  signupWithOtp: "signupWithOtp",
  userProfile: "user/get",
  updateUserProfile: "user/update",
  relationList: "relations",
  userLogout: "logout",
  getVehicleModelListDetails: "vehicle/model-detail",
  getFaqList: "public/faq",
  postContact: "public/createContactDetail",
  addAddress: "addAddress",
  deleteAddress: "deleteAddress",
  updateAddress: "updateAddress",
  extendDetails: "booking/extentionPaymentDetails/",
  getCurrentBooking: "booking/current-booking/",
  endRide: "endRide/",
  advertisement: "advertisement",
  getExtendPackage: "plans/extentions/",
  getBookingPriceById: "booking/booking-price/",
  sendEmergencyNumberOTP: "user/send-otp",
  verifyEmergencyNumberOTP: "user/verify-otp",
  getDocumentDetails: "document/",
  getBranch: "branch/",
  extendRide: "extendride",
  findNearestCity: "public/location/findNearestCity",
  notifyMe: "public/notifyMe",
  uploadOdometerReadings: "uploadOdometerReadings",
  payExtraCharges: "payExtraCharges",
  getVehicleDetails: "public/vehicle/getVehicleDetails",
  getAllMitcIds: "mitc/getAllMitcIds",
  getMitcHtmlContent: "public/mitc/getMitcHtmlContent",
  sendOtpForMitc: "public/mitc/sendOtpForMitc",
  verifyMitcForm: "public/mitc/verifyMitcForm",
  getDelAccOptions: "del_account_options",
  sendOtpForDelAcc: "public/v2/sendOtpForDeleteAcoount",
  changeAccStatus: "public/v2/changeAccountStatus",
  cancellationCharge: "cancellation-charge/",
  cancelBooking: "cancel-booking/",
  checkRefundStatus: "checkRefundStatusV2/",
  getBookingStatus: "getBookingStatus/",
  modificationCharges: "modificationCharges/",
  modifyBooking: "modifyBooking",
  getModelAvailableDates: "getModelAvailableDates",
  bookingChangeCharges: "bookingChangeCharges",
  getIssueCategoryList: "getMaster/issue_category/0",
  saveHelpDesk: "helpDesk",
  getUserProfileSignedUrl: "public/getSignedUrl",
  SubmitFeedbackForm: "feedback/v1",
  getFeedbackFormByBookingId: "feedback/v1/form/",
};
const CHECKOUT_API_URLS = {
  getCouponList: "getcouponsV2",
  getCustomer: "getCustomer",
  getBranchSlotsForBooking: "getBranchSlotsForBooking",
  getHomeDeliveryCharges: "getBranchDeliveryCharges",
  getHomeAddress: "getAddress/all",
  validateUserEmergency: "validateUserEmergency",
  storeBookingAppV4: "storeBookingAppV4",
  storeBookingV1: "v1/storeBooking",
  processOrder: "payment/order",
  verifyBookingPayment: "payment/verificationRes",
};
const KYC_API_URLS = {
  getDlSignedurl: "getSignedUrl",
  generateDigiLockerUrlWeb: "aadhaar/generateDigiLockerUrlWeb",
  userCheckKYC: "userKycChecks",
  saveDlImageData: "saveImageData",
  checkUserDocumentStatus: "manualKyc/customer/checkUserDocumentStatus",
  createAdditionalDocs: "manualKyc/customer/createAdditionalDocs",
};

// used to get the api url in revamped code (id is the dynamic id in the API url)
export const getApiUrls = (id: string = "", name: string | undefined = "") => {
  return {
    getCityV2: aliasFreedoBiz + "city/v2",
    getVehicleAvailabilityYor: aliasYor + `search/v1/availability`,
    calculateBookingCharges: aliasYor + "booking/v1/calculate/charges",
    getAvailablePlans: aliasYor + "plan/v1/availables",
    storeBooking: aliasYor + "booking/v1",
    createOrder: aliasMunim + "payment/v1",
    getPaymentStatus: aliasMunim + `payment/v1/${id}`,
    getBookingDetails: aliasYor + "booking/v1/list",
    getAllCoupons: aliasMunim + "coupon/v1/list",
    cancelledBooking: aliasYor + `booking/v1/${id}/cancel`,

    // OPERATOR_API_URLS
    getMasterCityList: aliasFreedoBiz + "city/v1/district",
    operatorLeadGeneration: aliasFreedoBiz + "operator/v1/lead",
    operatorLeadVerification: aliasFreedoBiz + "operator/v1/lead/verify",
    retryOtpForOperator: aliasFreedoBiz + "operator/v1/resend-otp",
    verifyOperatorEmail: aliasFreedoBiz + `operator/v1/verify-email/${id}`,
    fetchEsignDoc: aliasFreedoBiz + `operator/v1/e-sign/${id}`,
    eSignGenerateOtp: aliasFreedoBiz + "operator/v1/e-sign/generate-otp",
    eSignVerifyOtp: aliasFreedoBiz + "operator/v1/e-sign/verify",
    eSignResendOtp: aliasFreedoBiz + "operator/v1/e-sign/resend-otp",
    fetchOperatorCmsInfo: "/cms/api/operator-pages",

    // yor cms data
    getYorCmsData:
      aliasCms +
      "yor-customers?populate%5Bsection1%5D%5Bpopulate%5D%5B1%5D=backgroundImage&populate%5Bsection2%5D%5Bpopulate%5D%5B0%5D=offerCards&populate%5Bsection2%5D%5Bpopulate%5D%5B1%5D=offerCards.image&populate%5Bsection3%5D%5Bpopulate%5D%5B0%5D=reasonCards&populate%5Bsection3%5D%5Bpopulate%5D%5B1%5D=reasonCards.image&populate%5Bsection4%5D%5Bpopulate%5D%5B0%5D=mainImage&populate%5Bsection4%5D%5Bpopulate%5D%5B1%5D=getStartedCards&populate%5Bsection4%5D%5Bpopulate%5D%5B2%5D=getStartedCards.image&populate%5Bsection5%5D%5Bpopulate%5D%5B0%5D=tableEntries&populate%5Bsection6%5D%5Bpopulate%5D%5B0%5D=testimonials&populate%5Bsection6%5D%5Bpopulate%5D%5B1%5D=testimonials.avatar&populate%5Bsection6%5D%5Bpopulate%5D%5B2%5D=testimonials.emoji&populate%5Bsection7%5D%5Bpopulate%5D%5B0%5D=faqs&populate%5Bsection5%5D%5Bpopulate%5D%5B1%5D=tableHeading&populate%5Bsection3%5D%5Bpopulate%5D%5B2%5D=backgroundImage&populate%5Bsection3%5D%5Bpopulate%5D%5B3%5D=reasonCards.backgroundImage",
  };
};

export {
  BASE_URL,
  BASE_URL_CUSTOMER,
  versioning,
  LatestVersion,
  oldVersion,
  API_URLS,
  CHECKOUT_API_URLS,
  KYC_API_URLS,
  kyc,
  RAZORPAY_KEY,
  WEB_URL,
  FeedbackAPIExtenstion,
  CMS_URL,
};
