import { LocalStorage, yorFleetSort } from "../../../constant/enum";
import { commonReducerInterface } from "../../../interfaces/actionInterfaces";
import { setLocalStorageData } from "../../../utills/helper";
import { YOR_ACTION } from "../actions/actionTypes";

interface initialStateInterface {
  getAvailableVehicleLoader: boolean;
  getAvailableVehicles: any;
  allAvailableVehicles: any[];
  availablePlans: any;
  helmetTypes: any;
  planCharges: object;
  orderData: object;
  paymentLoader: boolean;
  paymentStatus: string;
  cityV2: any[];
  userCityYor: any;
  yorBookingData: any;
  yorBookingDetails: any;
  allCoupons: Object;
  loading: boolean;
  yorCmsData: any;
}

const initialState: initialStateInterface = {
  getAvailableVehicleLoader: false,
  getAvailableVehicles: {
    availableModels: [],
    unavailableModels: [],
  },
  allAvailableVehicles: [],
  availablePlans: [],
  helmetTypes: [],
  planCharges: {},
  orderData: {},
  paymentLoader: false,
  paymentStatus: "",
  cityV2: [],
  userCityYor: {},
  yorBookingData: {},
  yorBookingDetails: {},
  allCoupons: {},
  loading: false,
  yorCmsData: {},
};
const yorReducer = (state = initialState, action: commonReducerInterface) => {
  const { type, payload } = action;

  switch (type) {
    case YOR_ACTION.YOR_GET_AVAILABLE_VEHICLES_REQUESTED:
      return {
        ...state,
        getAvailableVehicleLoader: true,
        getAvailableVehicles: Object.keys(state.getAvailableVehicles)?.length
          ? state.getAvailableVehicles
          : {},
        allAvailableVehicle: state.allAvailableVehicles?.length
          ? state.allAvailableVehicles
          : [],
      };

    case YOR_ACTION.YOR_GET_AVAILABLE_VEHICLES_SUCCESS:
      /* sort fleets  */
      const sortedFleetAsc = [
        ...payload?.availableModels,
        ...payload?.unavailableModels,
      ]?.sort((a, b) =>
        payload?.sortType === yorFleetSort.HIGH_TO_LOW
          ? b?.planDetails?.monthlyCharges - a?.planDetails?.monthlyCharges
          : a?.planDetails?.monthlyCharges - b?.planDetails?.monthlyCharges
      );
      return {
        ...state,
        getAvailableVehicleLoader: false,
        getAvailableVehicles: payload,
        allAvailableVehicles: sortedFleetAsc,
      };

    case YOR_ACTION.YOR_GET_AVAILABLE_VEHICLES_FAILED:
      return {
        ...state,
        getAvailableVehicleLoader: false,
        getAvailableVehicles: {},
        allAvailableVehicles: [],
      };

    case YOR_ACTION.CALCULATE_CHARGES_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case YOR_ACTION.CALCULATE_CHARGES_SUCCESS:
      return {
        ...state,
        planCharges: payload,
        loading: false,
      };
    case YOR_ACTION.CALCULATE_CHARGES_FAILED:
      return {
        ...state,
        loading: false,
      };

    case YOR_ACTION.PLAN_AVAILABLES_REQUESTED:
      return {
        ...state,
        availablePlans: [],
        helmetTypes: [],
      };
    case YOR_ACTION.PLAN_AVAILABLES_SUCCESS:
      return {
        ...state,
        availablePlans: payload?.plans,
        helmetTypes: payload?.helmetTypes,
      };
    case YOR_ACTION.PLAN_AVAILABLES_FAILED:
      return {
        ...state,
      };
    case YOR_ACTION.STORE_BOOKING_REQUESTED:
      return {
        ...state,
        paymentLoader: true,
      };
    case YOR_ACTION.STORE_BOOKING_SUCCESS:
      return {
        ...state,
      };
    case YOR_ACTION.STORE_BOOKING_FAILED:
      return {
        ...state,
        paymentLoader: false,
      };

    case YOR_ACTION.CREATE_ORDER_REQUESTED:
      return {
        ...state,
        orderData: {},
      };
    case YOR_ACTION.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        orderData: payload,
        paymentLoader: false,
      };
    case YOR_ACTION.CREATE_ORDER_FAILED:
      return {
        ...state,
        paymentLoader: false,
      };

    case YOR_ACTION.GET_PAYMENT_STATUS_REQUESTED:
      return {
        ...state,
      };
    case YOR_ACTION.GET_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        paymentStatus: payload,
      };
    case YOR_ACTION.GET_PAYMENT_STATUS_FAILED:
      return {
        ...state,
      };
    case YOR_ACTION.CLEAR_ORDER_DATA:
      return {
        ...state,
        paymentStatus: "",
        orderData: {},
        planCharges: {},
      };

    case YOR_ACTION.FILTER_VEHICLE_MODEL_RENTING_FLEET:
      const filteredFleetModel = payload?.vehicleModel
        ? [
            ...state?.getAvailableVehicles?.availableModels,
            ...state?.getAvailableVehicles?.unavailableModels,
          ]?.filter(
            (item: any) => item?.modelDetails?.name === payload?.vehicleModel
          )
        : [
            ...state?.getAvailableVehicles?.availableModels,
            ...state?.getAvailableVehicles?.unavailableModels,
          ];

      /* sort fleets  */
      const filteredFleetModelSort = filteredFleetModel?.sort((a, b) =>
        payload?.sortType === yorFleetSort.LOW_TO_HIGH
          ? a?.planDetails?.monthlyCharges - b?.planDetails?.monthlyCharges
          : b?.planDetails?.monthlyCharges - a?.planDetails?.monthlyCharges
      );
      return { ...state, allAvailableVehicles: filteredFleetModelSort };

    case YOR_ACTION.FILTER_VEHICLE_TYPE_RENTING_FLEET:
      const filteredFleetType = payload?.vehicleType
        ? [
            ...state?.getAvailableVehicles?.availableModels,
            ...state?.getAvailableVehicles?.unavailableModels,
          ]?.filter(
            (item: any) =>
              item?.modelDetails?.type?.name === payload?.vehicleType
          )
        : [
            ...state?.getAvailableVehicles?.availableModels,
            ...state?.getAvailableVehicles?.unavailableModels,
          ];

      /* sort fleets  */
      const filteredFleetTypeSort = filteredFleetType?.sort((a, b) =>
        payload?.sortType === yorFleetSort.LOW_TO_HIGH
          ? a?.planDetails?.monthlyCharges - b?.planDetails?.monthlyCharges
          : b?.planDetails?.monthlyCharges - a?.planDetails?.monthlyCharges
      );
      return { ...state, allAvailableVehicles: filteredFleetTypeSort };

    case YOR_ACTION.GET_CITY_V2_REQUESTED:
      return { ...state, cityV2: [] };

    case YOR_ACTION.GET_CITY_V2_SUCCESS:
      return { ...state, cityV2: payload?.cities };

    case YOR_ACTION.GET_CITY_V2_FAILED:
      return { ...state, cityV2: [] };

    case YOR_ACTION.SET_USER_CITY_YOR:
      setLocalStorageData(LocalStorage.yorUserCity, { ...payload });
      return { ...state, userCityYor: { ...payload } };

    case YOR_ACTION.SET_YOR_BOOKING_DATA:
      return { ...state, yorBookingData: payload };

    case YOR_ACTION.GET_YOR_BOOKINGS_DETAILS_REQUESTED:
      return {
        ...state,
      };

    case YOR_ACTION.GET_YOR_BOOKINGS_DETAILS_SUCCESS:
      return {
        ...state,
        yorBookingDetails: payload,
      };
    case YOR_ACTION.GET_YOR_BOOKINGS_DETAILS_FAILED:
      return {
        ...state,
      };

    case YOR_ACTION.SORT_YOR_RENTING_FLEET:
      const sortedFleet = [...state.allAvailableVehicles]?.sort((a, b) =>
        payload?.sortType === yorFleetSort.LOW_TO_HIGH
          ? a?.planDetails?.monthlyCharges - b?.planDetails?.monthlyCharges
          : b?.planDetails?.monthlyCharges - a?.planDetails?.monthlyCharges
      );
      return {
        ...state,
        allAvailableVehicles: sortedFleet,
      };

    case YOR_ACTION.GET_ALL_COUPONS_REQUESTED:
      return {
        ...state,
      };
    case YOR_ACTION.GET_ALL_COUPONS_SUCCESS:
      return {
        ...state,
        allCoupons: payload,
      };
    case YOR_ACTION.GET_ALL_COUPONS_FAILED:
      return {
        ...state,
      };
    case YOR_ACTION.CANCELLED_BOOKING_REQUESTED:
      return {
        ...state,
      };

    case YOR_ACTION.GET_YOR_HOME_SCREEN_DATA_REQUESTED:
    case YOR_ACTION.GET_YOR_HOME_SCREEN_DATA_FAILED:
      return { ...state };

    case YOR_ACTION.GET_YOR_HOME_SCREEN_DATA_SUCCESS:
      return { ...state, yorCmsData: payload[0] ?? {} };

    default:
      return {
        ...state,
      };
  }
};

export default yorReducer;
