export enum colors {
  white = "#FFFFFF",
  black = "#000000",
  light_black = "rgb(36, 43, 53)",
  THEME_BLUE = "#2ABDBD",
  light_gray = "#F2F2F2",
  dark_gray = "rgb(114, 114, 114)",
  bluish_gray = "rgb(61, 56, 85)",
  heading = "rgb(123, 130, 137)",
  gray = "#D7DADF",
  error = "#FF0000",
  heading_black = "rgb(41, 40, 37)",
  LIGHT_BLUE = "rgb(43, 199, 201)",
  light_green = "rgb(22, 188, 118)",
  primary = "#2BC7C9",
  THEME_Yellow = "#FCB52F",

  light = "#ebedef",
  text_black = "#3C3C43",
  bg_btn_color = "#1BD8DA",
  text_gray = "#3C3C43CC",
  secondary_green = "#305D61",
  error_red = "#d02f2f",
  secondary_gray = "#52595A",
  dark_grayish_cyan = "#A8AFB0",
  secondary_dark_gray = "#585858",
  red = "#FF270E",
  YOR_THEME_BLUE = "#3999A1",
  YOR_text_gray = "#808F8C",
  dark_grayish_red = "#5C5B5B",
  dark_cyan = "#01353D",
  vivid_red = "#FF270E",
  dark_moderate_cyan = "#3999A1",
  strong_cyan = "#2bc6c99d",
  black_opacity = "rgba(0, 0, 0, 0.5)",
  dark_gray_secondary = "#424242",
  light_gray_secondary = "#D9D9D9",
  grayish_cyan = "#A9D6D6",
  dark_desaturated_cyan = "#325F63",
  very_dark_gray = "#333333",
  very_light_gray = "#E0E0E0",
  very_light_gray_secondary = "#FBFBFB",
  light_grayish_cyan = "#F6F9F9",
  shadow_gray = "#424141",
  spanish_gray = "#909090",
  eigengrau = "#191D23",
  dark_charcoal = "#303030",
  lotion_white = "#FAFAFA",
  azure_white = "#F5FEFF",
  slate_gray = "#667085",
  dark_jungle_green = "#101828",
}
