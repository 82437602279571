import { Box, Stack, SvgIcon, Typography } from "@mui/material";
import DatePicker from "react-datepicker";
import { CustomIconButton } from "../../atom/Buttons/CustomButtons";
import { colors } from "../../../../themes/colors";

interface datePickerInterface {
  selectedDate: any;
  dateChangeHandler: Function;
  minDate?: Date;
  maxDate?: Date;
  maxTime?: Date;
  dateFormat?: string;
  showTimeBlock?: boolean;
  showPackages?: boolean;
  packages?: any[];
  selectedPackage?: any;
  packagebuttonStyles?: any;
  packageSelectHandler?: Function;
  toggleCalendarHandler?: Function;
  showTimeSelect?: boolean;
  showInline?: boolean;
}

const CustomReactDateTimePicker = ({
  selectedDate = new Date(),
  dateChangeHandler = () => {},
  minDate = new Date(),
  maxDate = new Date(),
  maxTime = new Date(),
  dateFormat = "MMMM d, yyyy h:mm aa",
  showPackages = false,
  showTimeBlock = false,
  packages = [],
  selectedPackage = {},
  packagebuttonStyles,
  packageSelectHandler = () => {},
  toggleCalendarHandler = () => {},
  showTimeSelect = true,
  showInline = true,
}: datePickerInterface) => {
  return (
    <Stack className="DatePickerWrapper" sx={{ maxWidth: "min-content" }}>
      <DatePicker
        selected={selectedDate}
        onChange={(date: any) => dateChangeHandler(date)}
        showTimeSelect={showTimeBlock}
        minDate={new Date()}
        minTime={() => new Date()}
        maxTime={() => new Date()}
        // maxTime={() => setHours(setMinutes(new Date(), 30), 20)}
        dateFormat="MMMM d, yyyy h:mm aa"
        inline={showInline}
        style={{ width: "max-content" }}
        onClickOutside={() => toggleCalendarHandler()}
        // onInputClick={() => setIsOpen(true)}
        isClearable={true}
        customI
        maxDate={maxDate}
      />

      {/* Custom UI */}
      {showPackages && (
        <Stack
          sx={{
            background: "#fff",
            border: "1px solid darkgray",
            width: "auto",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          <Typography variant="subheading">Select Package</Typography>
          <Stack direction="row" flexWrap="wrap" gap="4px">
            {packages &&
              packages?.length > 0 &&
              packages.map((item: any, index: number) => {
                return (
                  <>
                    <CustomIconButton
                      buttonSx={{
                        ...packagebuttonStyles,
                        background:
                          selectedPackage?.id == item?.id
                            ? colors.THEME_BLUE
                            : colors.light_gray,
                      }}
                      key={item?.id}
                      onClick={(e) => {
                        packageSelectHandler(e, index, item);
                      }}
                      variant={
                        // selectedPackage == item?.package_name
                        selectedPackage?.id == item?.id ? "contained" : "text"
                      }
                    >
                      <Typography
                        variant="smallText"
                        sx={{
                          color:
                            selectedPackage?.id == item?.id
                              ? colors.white
                              : colors.light_black,
                        }}
                      >
                        {selectedPackage?.id == item?.id
                          ? item?.package_name
                          : item?.package_name}
                      </Typography>
                    </CustomIconButton>
                  </>
                );
              })}
            <CustomIconButton
              buttonSx={{
                ...packagebuttonStyles,
                background: colors.light_gray,
              }}
              key={"customPackage"}
              onClick={(e) => {
                packageSelectHandler(e, -1, "custom");
              }}
              variant={"text"}
            >
              <Typography
                variant="smallText"
                sx={{
                  color: colors.light_black,
                }}
              >
                Custom
              </Typography>
            </CustomIconButton>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default CustomReactDateTimePicker;
