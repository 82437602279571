import { Components, Theme } from "@mui/material";

export const autoCompleteCustomization: Components<Theme> = {
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        "& .MuiAutocomplete-inputRoot": {
          display: "flex",
          flexWrap: "nowrap", // Prevent line wrapping
        },
        "& .MuiAutocomplete-tag": {
          overflow: "hidden",
          textOverflow: "ellipsis", // Truncate text
          whiteSpace: "nowrap", // Prevent wrapping of individual chips
          maxWidth: "120px", // Set max width for each chip
        },
        "& .MuiOutlinedInput-root": {
          minHeight: "50px",
          fontSize: "12px",
        },
      },
    },
  },
};
