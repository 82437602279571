import React, { useState } from "react";
import {
  slide1,
  slide3,
  slide4,
  sliderVideo,
  WebsiteBanner1,
  WebsiteBanner2,
  WebsiteBanner3,
  WebsiteBanner4,
} from "../../assets";
import en from "../../locales/en.json";
import SelectCityModal from "./SelectCityModal";
import { getLocalStorageData } from "../../utills/helper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, FC } from "react";
import { actions } from "../../redux";
import { RootState } from "../../redux/reducers";

import JourneyModal from "./JourneyModal";
import { LocalStorage } from "../../constant/enum";
import { Routes } from "../../constant/routeContants";
import { Link } from "react-router-dom";
import { toggleCityModal, toggleJourneyModal } from "../../redux/actions";
import { FirebaseEvents } from "../../utills/firebaseEvents";

const { homePageCarousal } = en.home;

export const CauraselData = {
  IsSuccess: true,
  ResponseStatusCode: 200,
  Message: ["Record fetch successfully"],
  Result: [
    {
      id: 100,
      header: "Two Wheelers Rentals ",
      body_text: "Freedom To",
      body_text_1: "Move",
      body_para: "Presenting the safe, reliable and affordable",
      body_para_1: "mobility solution",
      launch_date: "2023-06-01T00:00:00.000Z",
      imageData: [
        WebsiteBanner1,
        WebsiteBanner2,
        WebsiteBanner3,
        WebsiteBanner4,
      ],
    },
    {
      id: 101,
      header: "Two Wheelers Rentals ",
      body_text: "Freedom To",
      body_text_1: "Move",
      body_para: "Presenting the safe, reliable and affordable",
      body_para_1: "mobility solution",
      launch_date: "2023-06-01T00:00:00.000Z",
      imageData: [slide1, sliderVideo, slide3, slide4],
    },
  ],
  Error: "",
};

const HomePageCarousal = () => {
  const dispatch = useDispatch();
  const cityData = getLocalStorageData(LocalStorage.userCity);
  const { carouselData } = useSelector((state: RootState) => state.homeReducer);
  useEffect(() => {
    dispatch(
      actions.getCarouselData({ type: "GetCarouselDetails", payload: {} })
    );
  }, []);

  /* temp to restrict crousel movement only single data*/
  const carouselDataSingle = carouselData?.length ? [carouselData[1]] : [];

  return (
    <section className="slider-wrapper">
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          {carouselDataSingle?.map((_data: any, index: number) => {
            return (
              <button
                key={_data.id}
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={index}
                className="active"
                aria-current="true"
                aria-label={"Slide " + (index + 1)}
              ></button>
            );
          })}
        </div>
        <div className="carousel-inner">
          <CarousalItem carouselData={carouselDataSingle} />
        </div>
      </div>
    </section>
  );
};

const CarousalItem = (props: any) => {
  return (
    <>
      {props.carouselData?.map((_details: any, index: number) => {
        const { imageData } = _details;

        return (
          <div
            className={"carousel-item " + (index === 0 ? " active " : "")}
            data-bs-interval="4000"
            key={index}
          >
            <div className="container-fluid">
              <div className="main-content-wrapper main-content-wrapper-slider">
                <div className="row slider-content">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <h5>{_details.header}</h5>
                    <h1>
                      {_details.body_text}
                      <br />
                      {_details.body_text_1}
                    </h1>
                    <p>
                      {_details.body_para}
                      <br /> {_details.body_para_1}
                    </p>
                    {/* {index == 0 ? ( */}
                    <LocationModal />
                    {/* ) : (
                      <Link
                        to={Routes.RENT}
                        className="freedo-primary-button rounded-pill"
                      >
                        Explore our fleet
                        <i className="fa-solid fa-right-long"></i>
                      </Link>
                    )} */}
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row justify-content-end slider-right-wrap">
                      <div className="slider-img-wrap">
                        <img
                          src={imageData[0]}
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = imageData[0];
                          }}
                        />
                        <img
                          src={imageData[1]}
                          className={`slide-2 ${
                            index === 0 ? "new-slide-2" : ""
                          }`}
                          alt="img"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = imageData[1];
                          }}
                        />
                      </div>
                      <div className="slider-img-wrap">
                        <img
                          src={imageData[2]}
                          className="slide-3"
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = imageData[2];
                          }}
                        />
                        <img
                          src={imageData[3]}
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = imageData[3];
                          }}
                        />
                      </div>

                      {index !== 0 ? (
                        <>
                          <div className="top-content rounded-pill">
                            {homePageCarousal.HassleFreeRentalSolution}
                            <i className="fa-solid fa-circle-check"></i>
                          </div>
                          <div className="bottom-content rounded-pill">
                            {homePageCarousal.AffordableRentalRides}
                            <i className="fa-solid fa-circle-check"></i>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

const LocationModal: FC = () => {
  const dispatch = useDispatch();
  const selectCityBtn = document.getElementById("cityModalBtn");
  const cityData = getLocalStorageData(LocalStorage.userCity);
  const [userCity, setUserCity] = useState<any>(cityData);
  const [isJourneyCity, setIsJourneyCity] = useState<boolean>(false);

  const handleCityChange = (newCity: any) => {
    if (newCity) {
      setUserCity(newCity);
    }
    if (isJourneyCity) {
      const elem = document.getElementById("btn-journy-trigger");
      if (elem) {
        elem.click();
        // setIsJourneyCity(false);
      }
    }
  };

  useEffect(() => {
    if (selectCityBtn && !cityData) {
      selectCityBtn.click();
    }
  }, [selectCityBtn]);

  return (
    <div>
      <form className="locate-me-model-wrap">
        <div className="form-check location-wrap">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-6">
              <button
                className="freedo-outline-btn location-btn"
                id="cityModalBtn"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalToggle"
                onClick={() => dispatch(toggleCityModal(true))}
              >
                <i className="fa-solid fa-location-dot me-2"></i>
                {cityData ? cityData.city_name : homePageCarousal.LocateMe}
              </button>
            </div>
            <div className="col-md-6 col-sm-6 col-6">
              <span className="shine"></span>
              <button
                className="freedo-outline-btn search-btn"
                id="btn-journy-trigger"
                data-bs-toggle="modal"
                data-bs-target="#journyModal"
                // value={homePageCarousal.SearchVehicle}
                onClick={(e: any) => {
                  e.preventDefault();
                  setIsJourneyCity(true);
                  FirebaseEvents("HomepageSearchVehicle", {});
                }}
              >
                Search Vehicle
              </button>
              <SelectCityModal handleChange={handleCityChange} />
            </div>
          </div>
        </div>
      </form>
      <JourneyModal
        isJourneyCity={isJourneyCity}
        setIsJourneyCity={setIsJourneyCity}
      />
    </div>
  );
};

export default HomePageCarousal;
