import moment from "moment";
import en from "../locales/en.json";
import { DECRYPTION_KEY } from "./regex";
var CryptoJS = require("crypto-js");
const privateKey = "RnJlZWRvQXBw";

export const throttle = (fun: any, threshold: number = 2000) => {
  let execute = true,
    timeout: any = null;
  return (...args: any) => {
    if (execute) {
      fun(...args);
      execute = false;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        execute = true;
        timeout = null;
      }, threshold);
    }
  };
};

export const deepCopy = (data: any) => {
  if (data) {
    return JSON.parse(JSON.stringify(data));
  } else return data;
};

export const debounce = (func: any, threshold: number = 500) => {
  let debounceHandler: any = null;
  return (...args: any) => {
    if (debounceHandler) clearTimeout(debounceHandler);
    debounceHandler = setTimeout(() => func(...args), threshold);
  };
};

const isArrayNotEmpty = (array: Array<any>) =>
  array !== null && Array.isArray(array) && array.length > 0;

export const handleMessage = (response: any) => {
  if (typeof response === "string") {
    return response;
  } else if (isArrayNotEmpty(response?.Message)) {
    return response?.Message[0];
  } else if (response?.Message) {
    return response?.Message;
  } else if (isArrayNotEmpty(response?.data?.Message)) {
    return response?.data?.Message[0];
  } else if (response?.data?.Message) {
    return response?.data?.Message;
  } else if (response?.data?.result?.Message) {
    return response?.data?.result?.Message;
  } else if (isArrayNotEmpty(response?.response?.data?.Message)) {
    return response?.response?.data?.Message[0];
  } else if (response?.response?.data?.Message) {
    return response?.response?.data?.Message;
  } else if (response?.response?.data?.message) {
    return response?.response?.data?.message;
  } else if (response?.data?.message) {
    return response?.data?.message;
  } else if (response?.data?.meta?.message) {
    return response?.data?.meta?.message;
  } else if (response?.data?.error?.errorMessage) {
    return response?.data?.error?.errorMessage;
  } else if (response?.errorMessage) {
    return response?.errorMessage;
  } else if (response?.response?.data?.error?.errorMessage) {
    return response?.response?.data?.error?.errorMessage;
  } else {
    return en.errorMessages.SomethingWentWrong;
  }
};

export const mobileValidation = (mobile: string) => {
  let regex = /^[6-9]\d{9}$/;
  return regex.test(mobile);
};

export const hindiTextValidation = (text: string) => {
  if (!text) return true;
  let regex = /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/;
  //let regex =/^[\u0900-\u097F\s]*$/
  return regex.test(text);
};
export const emailValidation = (email: string) => {
  let regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const restrictName = (text: string) =>
  text.replace(/[^A-Za-z]/g, "").trim();

export const restrictNameWithSpaces = (text: string) => {
  if (!text.trim()?.length) return "";
  let regex = /^[A-Za-z ]+$/;
  return regex.test(text) ? text : text.slice(0, -1);
};

export const restrictNumber = (text: string) =>
  text.replace(/[^0-9]/g, "").trim();

export const validate = (fields: any = {}, errorFields: object = {}) => {
  const validateFields = Object.keys(fields);
  let obj: any = errorFields;
  let error = false;
  validateFields.forEach((each) => {
    if (obj[each] === undefined) return;

    if (
      typeof fields[each] === "string" ||
      typeof fields[each] === "number" ||
      typeof fields[each] === "object"
    ) {
      if (!fields[each]) {
        error = true;
        obj[each] = en.errorMessages.requiredField;
      } else {
        switch (true) {
          case each?.toLocaleLowerCase()?.includes("email"):
            if (!validateEmail(fields[each]).status) {
              error = true;
              obj[each] = en.errorMessages.InvalidEmail;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("pincode"):
            if (fields[each]?.length < 6) {
              error = true;
              obj[each] = en.errorMessages.InvalidPincode;
            }
            break;
          case each?.toLocaleLowerCase()?.includes("mobile") ||
            each?.toLocaleLowerCase()?.includes("phone"):
            if (fields[each]?.length < 10) {
              error = true;
              obj[each] = en.errorMessages.mobileError;
            }
            break;
          default:
            break;
        }
      }
    }

    // else if( fields["inputFields"].category_name==""){
    //   error = true;
    //     obj[each] = en.errorMessages.requiredField;

    // }
    else {
      // @ts-ignore: Unreachable code error
      if (!fields[each]?.id) {
        error = true;
        // @ts-ignore: Unreachable code error
        obj[each] = en.errorMessages.requiredField;
      }
    }
  });
  return { errorFields: { ...errorFields, ...obj }, error };
};

export const validateEmail = (email: string) => {
  const emailRegex =
    /^[A-Z0-9_-]+([\.][A-Z0-9_]+)*@[A-Z0-9-]+(\.[a-zA-Z]{2,3})+$/i;
  email = email?.trim();
  if (email == "" || email == undefined || email == null)
    return { status: false, error: en.errorMessages.requiredField };
  else if (!emailRegex.test(email))
    return { status: false, error: en.errorMessages.InvalidEmail };
  else return { status: true, error: "" };
};
export const getLocalStorageData = (key: string) => {
  const data = localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  } else {
    return null;
  }
};
export const setLocalStorageData = (key: string, value: any) => {
  if (value !== undefined) {
    const data = JSON.stringify(value);
    localStorage.setItem(key, data);
  }
};

// removing existing key from localStorage
export const removeLocalStorageData = (key: string) => {
  localStorage.removeItem(key);
};

export const decryptData = (encrypted: string) => {
  let decrypted = CryptoJS.AES.decrypt(encrypted, DECRYPTION_KEY);
  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
};

export const getParamsString = (obj: any) => {
  let paramString = "?";
  Object.keys(obj).forEach((_keys, index) => {
    if (obj[_keys]) {
      paramString +=
        _keys +
        "=" +
        obj[_keys] +
        (index + 1 !== Object.keys(obj).length ? "&" : "");
    }
  });
  return paramString;
};

export const getFilePath = (documents: any, document_id: number) => {
  try {
    let doc = documents.filter(
      (_doc: any) => _doc.document_type_id === document_id
    );
    return doc[0]["documents"]["document_path"];
  } catch (ex) {
    return "";
  }
};

export const findEndDate = (startDate, days) =>
  startDate
    ? moment(moment(startDate), "DD/MM/YYYY")
        .add(days, "days")
        .format("YYYY-MM-DD")
    : "";

export const compressSizer: any = (size) => {
  const MB = size / Math.pow(1024, 2);
  if (Math.round(MB) === 0) return 100;
  if (Math.round(MB) === 1) return 100;
  if (Math.round(MB) === 2) return 90;
  if (Math.round(MB) === 3) return 80;
  if (Math.round(MB) === 4) return 70;
  if (Math.round(MB) <= 5) return 60;
  if (Math.round(MB) <= 7) return 10;
  if (Math.round(MB) <= 10) return 10;
  if (Math.round(MB) <= 15) return 10;
  if (Math.round(MB) <= 20) return 10;
  if (Math.round(MB) > 20) return 10;
};

export const imgFileReader = (file, callback) => {
  const reader: any = new FileReader();
  reader.readAsDataURL(file);
  reader.addEventListener("load", () => {
    callback(reader.result);
  });
};

export const getEncryptedText = (text: string) => {
  return window.btoa(text.toString());
};
export const getDecryptedText = (text: any) => {
  return window.atob(text.toString());
};
export function capitalizeFirstLetter(str) {
  if (!str) return "";
  str = str?.split(" ");

  for (let i = 0, x = str?.length; i < x; i++) {
    str[i] = str[i][0]?.toUpperCase() + str[i]?.substr(1);
  }

  return str?.join(" ");
}

export const closeAllModals = () => {
  const collection: any = document.getElementsByClassName("close-model");
  console.log(collection, 8887);
  if (collection) {
    let divs = Array.prototype.forEach.call(collection, (testElement) => {
      console.log("closed", 8887);
      testElement.click();
    });
  }
};

export function handleModalClose(className) {
  const elements: any = document.getElementsByClassName(className);
  while (elements?.length > 0) {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
    elements[0].parentNode.removeChild(elements[0]);
  }
}

export function commmaSeparator(num: number) {
  if (num) {
    var result = num.toString().split(".");
    result[0] = result[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return result.join(".");
  } else return num;
}

export function filterCurrentCity(cityList, currentCityId) {
  console.log(cityList, "::currentcityid");
  return cityList.filter((city) => city?.id === currentCityId);
}

export const convertRentalDayIntoNumber = (day) => {
  if (day && (day.includes("Day") || day.includes("Days"))) {
    return Number(day.split(" ")[0]);
  }
  return 0;
};

export const toggleSignInDrawer = (elementId?: string) => {
  const ele = elementId
    ? document.getElementById(elementId)
    : document.getElementById("signIn-drawer");
  if (ele) ele.click();
};

export const getFormattedDate = (inputDate: any, type: string) => {
  let resultDate = "N/A";
  let lastLoggedIn;
  if (inputDate) {
    lastLoggedIn = new Date(Number(inputDate));
    if (lastLoggedIn) {
      let dateOfMonth = lastLoggedIn.getDate();
      dateOfMonth =
        dateOfMonth < 10
          ? dateOfMonth.toString().padStart(2, "0")
          : dateOfMonth;
      let month = lastLoggedIn.getMonth() + 1;
      month = month < 10 ? month.toString().padStart(2, "0") : month;
      let fullYear = lastLoggedIn.getFullYear();
      let hours = (lastLoggedIn.getHours() + 24) % 12 || 12;
      let minutes =
        (lastLoggedIn.getMinutes() < 10 ? "0" : "") + lastLoggedIn.getMinutes();
      let seconds =
        (lastLoggedIn.getSeconds() < 10 ? "0" : "") + lastLoggedIn.getSeconds();
      let timeFormat = lastLoggedIn.getHours() >= 12 ? "PM" : "AM";

      if (type === "date") {
        resultDate = `${dateOfMonth}-${month}-${fullYear}`;
      }
      if (type === "time") {
        resultDate = `${hours}:${minutes}:${seconds} ${timeFormat}`;
      }
      if (type === "dateTime") {
        resultDate = `${dateOfMonth}-${month}-${fullYear} ${hours}:${minutes} ${timeFormat}`;
      }
      if (type === "hours") {
        resultDate = `${hours}:${minutes} ${timeFormat}`;
      }
    }
  }
  return resultDate;
};

/**
 * Constructs a URL with query parameters from the given payload object.
 * Each key in the payload object represents a query parameter name, and its corresponding value is the parameter value.
 * @url The base URL to which query parameters will be appended.
 * @payload An object where keys represent query parameter names and values represent parameter values.
 * @returns The constructed URL with properly encoded query parameters.
 */
// Generic function to construct URL with query parameters
export const constructUrl = (url: string, payload: any): string => {
  let apiUrl: string = `${url}`;
  let queryParams: string[] = [];

  // Loop through the payload object and construct query parameters
  for (const [key, value] of Object.entries(payload)) {
    if (value) {
      if (Array.isArray(value)) {
        // Handle array values by formatting them correctly
        const formattedArray = value.map((item) => JSON.stringify(item));
        queryParams.push(`${key}=[${formattedArray.join(", ")}]`);
      } else {
        // Handle other types of values (i.e., string, number, boolean)
        queryParams.push(`${key}=${encodeURIComponent(String(value))}`);
      }
    }
  }

  // Join query parameters with '&' and append to apiUrl if there are any
  if (queryParams.length > 0) {
    apiUrl += `?${queryParams.join("&")}`;
  }

  return apiUrl;
};

// IST(timestamp) to DD/MM/YYYY
export const convertTimestampToDate = (timestamp: number): string => {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  // Return formatted date in dd/mm/yyyy format
  return `${day}-${month}-${year}`;
};

// convert paise to rupee
export const convertPaiseToRupee = (paise: number): string => `₹${paise / 100}`;

export const toPascalCase = (str: string) => {
  // This function converts the input string to lowercase using toLowerCase(), then uses a regular expression /\b\w/g to match each word boundary (\b) followed by a word character (\w). The replace() method is used to replace each matched character with its uppercase equivalent using the toUpperCase() function.
  return str?.toLowerCase().replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
};
//categorized time slots
export const categorizeSlots = (slots: any) => {
  const categorized: any = {
    morning: [],
    afternoon: [],
    evening: [],
  };
  if (!slots) return categorized;
  slots?.forEach((slot: any) => {
    const startHour = parseInt(slot.start.split(":")[0]);
    if (startHour >= 0 && startHour < 12) {
      categorized.morning.push(slot);
    } else if (startHour >= 12 && startHour < 17) {
      categorized.afternoon.push(slot);
    } else if (startHour >= 17 && startHour < 24) {
      categorized.evening.push(slot);
    }
  });
  return categorized;
};

// Format Time Slot
export const formatTime = (time) => {
  if (!time) return "";
  const [hour, minute] = time.split(":").map(Number);
  const ampm = hour >= 12 ? "pm" : "am";
  const formattedHour = hour % 12 || 12; // Convert 0 to 12 for 12 AM
  return `${formattedHour}:${minute < 10 ? `0${minute}` : minute} ${ampm}`;
};

//get sum  of array
export const getArraySum = (arr: any = []) => {
  let sum = 0;
  if (arr.length > 0) {
    sum = arr.reduce((partialSum, a) => partialSum + a, 0);
  }
  return sum;
};
//get difference in days
export const getDifferenceInDays = (date1: any, date2: any) => {
  date1 = new Date(date1);
  date2 = new Date(date2);

  // Calculating the time difference
  // of two dates
  let Difference_In_Time = date2.getTime() - date1.getTime();

  // Calculating the no. of days between
  // two dates
  let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

  // To display the final no. of days (result)
  return Difference_In_Days;
};

//get formatted INR
export const getFormattedINR = (amount: number) => {
  let formattedAmount = "";
  // if (amount > 0) {
  amount = amountConvertToRupee(amount);
  formattedAmount = amount.toLocaleString("yoren-IN", {
    maximumFractionDigits: 0,
    style: "currency",
    currency: "INR",
  });
  // }
  return formattedAmount;
};

//convert amount to rupee
export const amountConvertToRupee = (num: number) => {
  let result = 0;
  if (num > 0) {
    result = num / 100;
  }

  return result;
};
// remove undefined , null and empty values from JSON
export const filterJson = (object) => {
  if (!object) return {};
  Object.entries(object).forEach(([k, v]: any) => {
    if (v && typeof v === "object") {
      filterJson(v);
    }
    if (
      (v && typeof v === "object" && !Object.keys(v).length) ||
      v === null ||
      v === undefined ||
      v === ""
    ) {
      if (Array.isArray(object)) {
        object.splice(k, 1);
      } else {
        delete object[k];
      }
    }
  });
  return object;
};

//get formatted date
export const dateFormat = (date: any, dateTimeDisplay: boolean = false) => {
  if (!date) return;
  const dayjs = require("dayjs");
  const modifiedDate = dayjs(date);
  const formattedDate = dateTimeDisplay
    ? modifiedDate.format("DD/MM/YYYY hh:mm")
    : modifiedDate.format("DD/MM/YYYY");
  return formattedDate;
};

// get timestamp of start of the day // i.e., Tue Jun 04 2024 00:00:00 GMT+0530 (India Standard Time)
export const getTimestampAtStartOfDay = (dateVal: any): string | number => {
  return moment(dateFormat(dateVal), "DD/MM/YYYY").startOf("day").valueOf();
};

// get timestamp of end of the day // i.e, Tue Jun 04 2024 23:59:59 GMT+0530 (India Standard Time)
export const getTimestampAtEndOfDay = (dateVal: any): string | number => {
  return moment(dateFormat(dateVal), "DD/MM/YYYY").endOf("day").valueOf();
};

// helper to compare two objects deeply
export const isEqualObject = (objOne: any, objTwo: any): boolean => {
  if (objOne === objTwo) return true;

  if (
    typeof objOne !== "object" ||
    typeof objTwo !== "object" ||
    objOne === null ||
    objTwo === null
  ) {
    return false;
  }
  // Get the keys of both objects
  const objKeysOne = Object.keys(objOne);
  const objKeysTwo = Object.keys(objTwo);

  // If the number of keys is different, objects are not equal
  if (objKeysOne.length !== objKeysTwo.length) return false;

  // Compare each key and value recursively
  for (let key of objKeysOne) {
    if (!objKeysTwo.includes(key)) return false;
    // Recursively compare the values
    if (!isEqualObject(objOne[key], objTwo[key])) return false;
  }
  return true;
};

// Function to convert strings to Capitalize format
export const formatString = (input: string): string => {
  // Convert the input to lowercase to handle case insensitivity
  const lowerCaseInput = input?.toLowerCase();

  // Replace underscores with spaces
  const spacedInput = lowerCaseInput?.replace(/_/g, " ");

  // Capitalize the first letter of each word
  const formattedString = spacedInput?.replace(/\b\w/g, (char) =>
    char?.toUpperCase()
  );

  return formattedString;
};

// helper to check if image url is valid
export const isValidImageUrl = (url: string): Promise<boolean> =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = `${url}?${Date.now()}`;
  });

// Utility function to set data in sessionStorage
export const setSessionStorage = <Type>(key: string, value: Type): void => {
  try {
    const stringifiedValue = JSON.stringify(value);
    sessionStorage.setItem(key, stringifiedValue);
  } catch (error) {
    console.error("Error setting item in sessionStorage", error);
  }
};

// Utility function to get data from sessionStorage
export const getSessionStorage = <Type>(key: string): Type | null => {
  try {
    const storedValue = sessionStorage.getItem(key);
    if (storedValue) {
      return JSON.parse(storedValue) as Type;
    }
    return null;
  } catch (error) {
    console.error("Error getting item from sessionStorage", error);
    return null;
  }
};

// get random element in array of any type
export const getRandomElement = <T>(arr: T[]): T => {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
};

// divide given array into 2d array of given (subarrayLength) length
export const divideArrayTo2D = <T>(
  array: T[],
  subarrayLength: number
): T[][] => {
  const result: T[][] = [];

  for (let i = 0; i < array?.length; i += subarrayLength) {
    const subarray = array?.slice(i, i + subarrayLength);
    result.push(subarray);
  }
  return result;
};

export const getDeliveryAddress = (address: any) => {
  let formatteDeliveryAddress = "";
  formatteDeliveryAddress = address?.addressLine1
    ? `${address?.addressLine1}`
    : "";
  formatteDeliveryAddress += address?.addressLine2
    ? `, ${address?.addressLine2}`
    : "";
  formatteDeliveryAddress += address?.landmark ? `, ${address?.landmark}` : "";
  formatteDeliveryAddress += address?.pinCode ? `, ${address?.pinCode}` : "";
  formatteDeliveryAddress = formatteDeliveryAddress
    ? formatteDeliveryAddress
    : "N/A";
  return formatteDeliveryAddress;
};

// Utility function to add line breaks after every 200 characters
export const addLineBreaks = (text: string, limit: number) => {
  const regex = new RegExp(`.{1,${limit}}`, "g");
  return text.match(regex) || [];
};
