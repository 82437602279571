import { Box, Stack, Typography } from "@mui/material";
import { SoftStar } from "../../../../assets";
import { TypographyConstants } from "../../../../constant/enum";
import Styles from "../index.module.css";

/* heading style */
const yorHeading = {
  fontFamily: "'Satoshi', sans-serif",
  color: "linear-gradient(90.39deg, #474747 21.34%, #737272 78.66%)",
  fontSize: "1.7rem",
  fontWeight: 500,
  "@media (max-width:600px)": {
    fontSize: "1.4rem",
  },
};

export const YORHeadingContent = ({
  heading = "",
  content = <></>,
  padding = "20px",
  gap = "30px",
}) => {
  return (
    <Stack
      className={Styles.YORHeadingContent}
      sx={{ padding: padding, gap: gap }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <img src={SoftStar} />
        <Typography sx={{ textAlign: "center", ...yorHeading }}>
          {heading}
        </Typography>
        <img src={SoftStar} />
      </Box>
      {content}
    </Stack>
  );
};
