import ClearIcon from "@mui/icons-material/Clear";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Autocomplete,
  Chip,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { colors } from "../../../../themes/colors";

interface ICustomAutocomplete {
  choice: any[];
  handleChange: Function;
  placeholder: string;
}

interface IComponentStates {
  options: any;
  batchIndex: number;
  searchText: string;
  selectedOption: any | null;
  open: boolean;
}

const CustomAutocomplete = (props: ICustomAutocomplete) => {
  const { choice, handleChange, placeholder } = props;
  const ITEMS_PER_BATCH: number = 10;

  const [state, setState] = useState<IComponentStates>({
    options: [],
    batchIndex: 0,
    searchText: "",
    selectedOption: null,
    open: false,
  });

  const { options, batchIndex, searchText, selectedOption, open } = state;

  useEffect(() => {
    loadInitialOptions();
  }, [JSON.stringify(choice)]);

  // Load initial batch of data
  const loadInitialOptions = (): void => {
    setState((prevState) => ({
      ...prevState,
      options: choice.slice(0, ITEMS_PER_BATCH),
      batchIndex: 1,
    }));
  };

  // Load next batch of options
  const loadMoreOptions = (): void => {
    const nextBatch = choice.slice(
      batchIndex * ITEMS_PER_BATCH,
      (batchIndex + 1) * ITEMS_PER_BATCH
    );
    if (nextBatch.length > 0) {
      setState((prevState) => ({
        ...prevState,
        options: [...options, ...nextBatch],
        batchIndex: batchIndex + 1,
      }));
    }
  };

  const filteredOptions: any[] = useMemo(() => {
    if (!searchText) return options;
    return choice?.filter((item) =>
      item?.displayName.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, options, choice]);

  // Clear selection and reload initial options
  const handleClearSelection = (): void => {
    setState((prevState) => ({
      ...prevState,
      selectedOption: null,
    }));
    handleChange(null);
    loadInitialOptions();
    toggleDropdown(true);
  };

  const toggleDropdown = (arg?: boolean | undefined): void =>
    setState((prevState) => ({
      ...prevState,
      open: arg !== undefined ? arg : !open,
    }));

  // Handle selection and deselection of options
  const handleOnChange = (event: any, newValue: any): void => {
    if (selectedOption?.name === newValue?.name) {
      // If user selects the same option, clear it and reload initial options
      handleClearSelection();
      return;
    }
    setState((prevState) => ({
      ...prevState,
      selectedOption: newValue,
    }));
    handleChange([newValue?.name]);
    toggleDropdown(false);
  };

  return (
    <Autocomplete
      sx={{ width: 1 }}
      value={selectedOption}
      options={filteredOptions}
      getOptionLabel={(option: any) => option.displayName}
      onInputChange={(event: any, value: any) =>
        setState((prevState) => ({ ...prevState, searchText: value }))
      }
      onChange={handleOnChange}
      disableClearable
      open={open}
      onOpen={() => setState((prevState) => ({ ...prevState, open: true }))}
      onClose={() => setState((prevState) => ({ ...prevState, open: false }))}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={placeholder}
          sx={{
            "& .MuiAutocomplete-inputRoot": {
              padding: "9px 11px 9px 34px !important",
            },
          }}
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <>
                  <IconButton
                    onClick={() => toggleDropdown()}
                    sx={{
                      visibility:
                        filteredOptions?.length > 0 ? "visible" : "hidden",
                      cursor: "pointer",
                    }}
                  >
                    {open ? (
                      <ExpandLessIcon sx={{ color: colors?.gray }} />
                    ) : (
                      <ExpandMoreIcon sx={{ color: colors?.gray }} />
                    )}
                  </IconButton>
                </>
              ),
            },
          }}
        />
      )}
      renderOption={(props, option) => (
        <MenuItem
          {...props}
          key={option?.name}
          value={option?.name}
          sx={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            backgroundColor:
              selectedOption?.name === option?.name
                ? colors?.light_gray
                : "inherit",
          }}
          onClick={() => handleOnChange(null, option)}
        >
          {option?.displayName}
        </MenuItem>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            label={option?.displayName}
            {...getTagProps({ index })}
            sx={{
              maxWidth: "120px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          />
        ))
      }
      slotProps={{
        listbox: {
          onScroll: (event) => {
            const listboxNode = event.currentTarget;
            if (
              listboxNode.scrollTop + listboxNode.clientHeight >=
              listboxNode.scrollHeight
            ) {
              loadMoreOptions();
            }
          },
          sx: {
            maxHeight: "300px",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: colors?.THEME_BLUE,
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: colors?.THEME_BLUE,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: colors?.white,
            },
          },
        },
      }}
    />
  );
};

export default CustomAutocomplete;
