import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import BookingTabs from "./BookingTab";
import OnGoingRide from "./OnGoingRide";
import { useLocation } from "react-router-dom";

const initPagination = {
  limit: 2,
  page: 1,
};
const Index = (props: any) => {
  const { bookingList, totalBooking, currentBooking, bookingListLoader } =
    useSelector((state: RootState) => state.bookingReducer);

  const [currentTab, setCurrentTab] = useState("");
  const location = useLocation();
  useEffect(() => {
    console.log({ totalBooking, bookingList });
  }, [totalBooking]);
  useEffect(() => {
    if (location?.state?.isYor) {
      setCurrentTab("Subscription");
    } else {
      setCurrentTab("all");
    }
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div
        className={`tab-pane fade setting-pane show active'}`}
        id="v-pills-messages"
        role="tabpanel"
        aria-labelledby="v-pills-messages-tab"
        tabIndex={0}
      >
        <div className="booking-h-tab">
          {currentBooking?.message === "No booking Found" &&
          bookingList?.message === "No booking Found" &&
          currentTab === "all" &&
          bookingListLoader === false ? (
            ""
          ) : (
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link freedo-primary-button rounded-pill ${
                    currentTab === "all" ? "active" : ""
                  }`}
                  id="pills-home-tab"
                  onClick={() => setCurrentTab("all")}
                  type="button"
                >
                  Rentals
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link freedo-outline-btn rounded-pill ${
                    currentTab === "Subscription" ? "active" : ""
                  }`}
                  id="pills-contact-tab"
                  type="button"
                  onClick={() => setCurrentTab("Subscription")}
                >
                  Subscription
                </button>
              </li>
              {/*  <li className="nav-item" role="presentation">
                <button
                  className={`nav-link freedo-outline-btn rounded-pill ${
                    currentTab === "completed" ? "active" : ""
                  }`}
                  id="pills-contact-tab"
                  type="button"
                  onClick={() => setCurrentTab("completed")}
                >
                  Completed
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link freedo-outline-btn rounded-pill ${
                    currentTab === "cancel" ? "active" : ""
                  }`}
                  id="pills-book-tab"
                  onClick={() => setCurrentTab("cancel")}
                  type="button"
                >
                  Cancelled
                </button>
              </li> */}
            </ul>
          )}

          <div className="tab-content ride" id="pills-tabContent">
            {currentTab === "all" ? (
              <div
                className={`tab-pane fade show active`}
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabIndex={0}
              >
                <div>
                  <div className="row">
                    <OnGoingRide currentTab={currentTab} />
                    <div className="col-md-6 booking-tab-rides">
                      <BookingTabs
                        bookingStatus={""}
                        itemsperpage={2}
                        classNames="row"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {currentTab === "completed" ? (
              <div
                className={`tab-pane fade show active`}
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
                tabIndex={0}
              >
                <BookingTabs bookingStatus={"completed"} />
              </div>
            ) : null}

            {currentTab === "Subscription" ? (
              <div
                className={`tab-pane fade show active`}
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
                tabIndex={0}
              >
                {" "}
                <BookingTabs bookingStatus={"Subscription"} />
              </div>
            ) : null}

            {currentTab === "cancel" ? (
              <div
                className={`tab-pane fade show active`}
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
                tabIndex={0}
              >
                {" "}
                <BookingTabs bookingStatus={"cancelled"} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
