import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { useState } from "react";
import { useSelector } from "react-redux";

import { squareTexture } from "../../../../assets";
import { GenericObject } from "../../../../interfaces/commonInterface";
import en from "../../../../locales/en.json";
import { RootState } from "../../../../redux/store";
import { colors } from "../../../../themes/colors";
import { CustomButton } from "../../../../yor-src/components/atom/Buttons/CustomButtons";
import { StyleObject } from "./StyleObject";

const { operator, global } = en;

const ScrollableBox = styled(Box)({ ...StyleObject?.scrollableBox });

const BottomSection = (props: any) => {
  const theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down(750));

  const { operatorCmsInfo, operatorCmsInfoLoader } = useSelector(
    (state: RootState) => state.operatorReducer
  );

  const [expanded, setExpanded] = useState<string | false>(false);

  // Handle Accordion panel expansion
  const handleChange = (panel: string) => (_: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Scroll to top of the page
  const scrollToTop = (): void =>
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling effect
    });

  return (
    <>
      {/* Top Horizontal Carousel */}
      <Box sx={StyleObject?.carouselTopBox}>
        <Grid
          container
          sx={{ ...StyleObject?.gridContainer, top: isMobile ? 1200 : 580 }}
        >
          {/* Left Side */}
          <Grid size={{ xs: 0, sm: 4, md: 4 }} />

          {/* Right Side */}
          <Grid size={{ xs: 12, sm: 8, md: 8 }}>
            <Box width={1}>
              <ScrollableBox>
                {operatorCmsInfo?.section1?.cards?.length > 0 ||
                !operatorCmsInfoLoader ? (
                  operatorCmsInfo?.section1?.cards?.map(
                    (card: GenericObject, index: number) => (
                      <Card key={index} sx={StyleObject?.cardStyle}>
                        <CardHeader
                          avatar={
                            card?.icon ? (
                              <img
                                src={card?.icon}
                                alt={operator?.altIcon}
                                style={StyleObject?.imageIconStyle}
                              />
                            ) : (
                              <Skeleton
                                variant="circular"
                                sx={StyleObject?.imageIconStyle}
                              />
                            )
                          }
                        />

                        {/* Card Content with truncated title & description */}
                        <CardContent>
                          <Typography sx={StyleObject?.thcTruncatedTitle}>
                            <Tooltip
                              title={
                                card?.heading ?? (
                                  <Skeleton variant="text" width={100} />
                                )
                              }
                              enterDelay={500}
                              arrow
                            >
                              {card?.heading || <Skeleton variant="text" />}
                            </Tooltip>
                            <Skeleton variant="text" />
                          </Typography>
                          <Typography sx={StyleObject?.thcTruncatedDescription}>
                            <Tooltip
                              title={
                                card?.description ?? (
                                  <Skeleton variant="text" width={100} />
                                )
                              }
                              enterDelay={500}
                              arrow
                            >
                              {card?.description || (
                                <Stack>
                                  {[...Array(2)].map((_, index) => (
                                    <Skeleton key={index} variant="text" />
                                  ))}
                                </Stack>
                              )}
                            </Tooltip>
                          </Typography>
                        </CardContent>
                      </Card>
                    )
                  )
                ) : (
                  <>
                    {[...Array(3)].map((_, index) => (
                      <Card key={index} sx={StyleObject?.cardStyle}>
                        <CardHeader
                          avatar={
                            <Skeleton
                              variant="circular"
                              sx={StyleObject?.imageIconStyle}
                            />
                          }
                        />
                        <CardContent>
                          <Skeleton
                            variant="text"
                            width={150}
                            height={24}
                            sx={{ mb: 1 }}
                          />
                          <Stack>
                            {[...Array(3)].map((_, index) => (
                              <Skeleton
                                key={index}
                                variant="text"
                                height={16}
                              />
                            ))}
                          </Stack>
                        </CardContent>
                      </Card>
                    ))}
                  </>
                )}
              </ScrollableBox>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Freedo Reimagined & How to Join a Fleet Section */}
      <Box sx={StyleObject?.wrapperBox}>
        {/* Freedo Reimagined For Business Section */}
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={isMobile ? 2 : 5}
          sx={{
            ...StyleObject?.freedoReimaginedStack,
            height: isMobile ? "" : 362,
          }}
        >
          <Box
            width={isMobile ? 1 : 4 / 10}
            sx={{ textAlign: isMobile ? "center" : "right" }}
          >
            <img
              src={
                operatorCmsInfo?.section2?.image
                  ? operatorCmsInfo?.section2?.image
                  : squareTexture
              }
              alt={operator?.altImg}
              style={{
                height: isMobile ? 228 : 278,
                width: isMobile ? 200 : 250,
                padding: "unset",
              }}
            />
          </Box>
          <Stack
            width={isMobile ? 1 : 6 / 10}
            gap={3}
            sx={{
              textAlign: "left",
              alignItems: isMobile ? "center" : "",
              padding: isMobile ? 1 : "",
              height: isMobile ? "" : 282,
            }}
          >
            <Typography
              variant={isMobile ? "h5" : "h3"}
              sx={{ fontWeight: 500, maxWidth: isMobile ? 300 : 466 }}
            >
              {operatorCmsInfo?.section2?.heading || (
                <Skeleton
                  variant="text"
                  width={isMobile ? 250 : 320}
                  height={50}
                />
              )}
            </Typography>
            <Typography variant="body1" sx={{ maxWidth: isMobile ? 300 : 466 }}>
              {operatorCmsInfo?.section2?.description || (
                <>
                  {[...Array(4)].map((_, index) => (
                    <Skeleton
                      key={index}
                      variant="text"
                      width={isMobile ? 250 : 350}
                      height={25}
                      sx={{ mt: index % 2 === 0 && index !== 0 ? 2 : 0 }}
                    />
                  ))}
                </>
              )}
            </Typography>
          </Stack>
        </Stack>

        {/* How to Join a Fleet with Freedo Title  */}
        <Typography variant="h3" sx={StyleObject?.howToJoinText}>
          {operatorCmsInfo?.section3?.title || (
            <Skeleton
              variant="text"
              width={isMobile ? 250 : 350}
              height={40}
              sx={{ mx: "auto" }}
            />
          )}
        </Typography>

        {/* How to Join a Fleet with Freedo Carousel  */}
        <Box sx={StyleObject?.boxTop}>
          <Grid justifyContent="center" alignItems="center">
            {/* Right Side */}
            <Grid size={{ xs: 12, sm: 10 }}>
              <Box width={1}>
                <ScrollableBox>
                  {operatorCmsInfo?.section3?.cards?.length > 0 ||
                  !operatorCmsInfoLoader ? (
                    operatorCmsInfo?.section3?.cards?.map(
                      (card: GenericObject, index: number) => (
                        <Card key={index} sx={StyleObject?.howToJoinFleetCard}>
                          <CardHeader
                            avatar={
                              card?.icon ? (
                                <img
                                  src={card?.icon}
                                  alt={operator?.altIcon}
                                  style={StyleObject?.howToJoinFleetCardIconImg}
                                />
                              ) : (
                                <Skeleton
                                  variant="circular"
                                  sx={StyleObject?.howToJoinFleetCardIconImg}
                                />
                              )
                            }
                          />

                          {/* Card Content with truncated title/ description */}
                          <CardContent>
                            <Typography sx={StyleObject?.htjfTruncatedTitle}>
                              <Tooltip
                                title={
                                  card?.heading ?? (
                                    <Skeleton variant="text" width={100} />
                                  )
                                }
                                enterDelay={500}
                                arrow
                              >
                                {card?.heading || <Skeleton variant="text" />}
                              </Tooltip>
                            </Typography>
                            <Typography
                              sx={StyleObject?.htjfTruncatedDescription}
                            >
                              <Tooltip
                                title={
                                  card?.description ?? (
                                    <Skeleton variant="text" width={100} />
                                  )
                                }
                                enterDelay={500}
                                arrow
                              >
                                {card?.description || (
                                  <Stack>
                                    {[...Array(3)].map((_, index) => (
                                      <Skeleton key={index} variant="text" />
                                    ))}
                                  </Stack>
                                )}
                              </Tooltip>
                            </Typography>
                          </CardContent>
                        </Card>
                      )
                    )
                  ) : (
                    <Stack direction="row" spacing={2}>
                      {[...Array(3)].map((_, index) => (
                        <Card key={index} sx={StyleObject?.howToJoinFleetCard}>
                          <CardHeader
                            avatar={
                              <Skeleton
                                variant="circular"
                                sx={StyleObject?.howToJoinFleetCardIconImg}
                              />
                            }
                          />
                          <CardContent>
                            <Skeleton
                              variant="text"
                              width={150}
                              height={25}
                              sx={{ mb: 1 }}
                            />
                            <Stack>
                              {[...Array(3)].map((_, index) => (
                                <Skeleton
                                  key={index}
                                  variant="text"
                                  height={16}
                                />
                              ))}
                            </Stack>
                          </CardContent>
                        </Card>
                      ))}
                    </Stack>
                  )}
                </ScrollableBox>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Make Money By Renting Out Your Vehicle Section */}
      <Box
        sx={{
          ...StyleObject?.boxxTop,
          backgroundImage: `url(${operatorCmsInfo?.section4?.image})`,
        }}
      >
        <Typography variant="h3" gutterBottom color={colors?.white}>
          {operatorCmsInfo?.section4?.title || (
            <Skeleton variant="text" width={250} />
          )}
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          color={colors?.white}
          sx={{ pb: 1 }}
        >
          {operatorCmsInfo?.section4?.description || (
            <Stack>
              {[...Array(2)].map((_, index) => (
                <Skeleton
                  key={index}
                  variant="text"
                  width={isMobile ? 280 : 350}
                />
              ))}
            </Stack>
          )}
        </Typography>
        {operatorCmsInfo?.section4?.buttonText ? (
          <CustomButton
            variant="contained"
            sx={StyleObject?.customBtn}
            onClick={scrollToTop}
          >
            {operatorCmsInfo?.section4?.buttonText}
          </CustomButton>
        ) : (
          <Skeleton variant="rectangular" sx={StyleObject?.customBtn} />
        )}
      </Box>

      {/* FAQ Section */}
      {operatorCmsInfo?.section5 ? (
        <Box sx={StyleObject?.faqBoxStyle}>
          <Typography variant="h3" sx={StyleObject?.faqText}>
            {operatorCmsInfo?.section5?.title}
          </Typography>
          <Container maxWidth="md">
            {operatorCmsInfo?.section5?.faqs?.map(
              (item: GenericObject, index: number) => (
                <Accordion
                  key={index}
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                  sx={StyleObject?.accordianStyle}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === `panel${index}` ? (
                        <RemoveCircleIcon sx={{ color: colors?.primary }} />
                      ) : (
                        <AddCircleIcon sx={{ color: colors?.primary }} />
                      )
                    }
                    sx={StyleObject?.accordionSummaryStyle}
                  >
                    <Typography
                      sx={{
                        ...StyleObject?.accordionTitle,
                        fontSize: isMobile ? "14px" : "20px",
                      }}
                    >
                      {item?.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      sx={{
                        ...StyleObject?.accordionDescription,
                        fontSize: isMobile ? "12px" : "16px",
                      }}
                    >
                      {item?.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )
            )}
          </Container>
        </Box>
      ) : (
        <Box sx={StyleObject?.faqBoxStyle}>
          {/* Title Skeleton */}
          <Skeleton
            variant="text"
            width="40%"
            height={50}
            sx={{ mx: "auto", mb: 3 }}
          />
          <Container maxWidth="md">
            {[...Array(3)].map((_, index) => (
              <Accordion key={index} sx={StyleObject?.accordianStyle}>
                <AccordionSummary
                  expandIcon={
                    expanded === `panel${index}` ? (
                      <RemoveCircleIcon sx={{ color: colors?.primary }} />
                    ) : (
                      <AddCircleIcon sx={{ color: colors?.primary }} />
                    )
                  }
                  sx={StyleObject?.accordionSummaryStyle}
                >
                  {/* Question Skeleton */}
                  <Skeleton variant="text" width="80%" height={30} />
                </AccordionSummary>
                <AccordionDetails>
                  {/* Answer Skeleton */}
                  <Skeleton variant="text" width="90%" height={20} />
                  <Skeleton
                    variant="text"
                    width="85%"
                    height={20}
                    sx={{ mt: 1 }}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </Container>
        </Box>
      )}
    </>
  );
};

export default BottomSection;
