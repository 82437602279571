import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { ITermsAndConditionsModal } from "../../../../interfaces/operatorInterface";
import en from "../../../../locales/en.json";
import { RootState } from "../../../../redux/store";
import { colors } from "../../../../themes/colors";
import { CustomButton } from "../../../../yor-src/components/atom/Buttons/CustomButtons";
import { StyleObject } from "./StyleObject";

const { operator, global } = en;

const TermsAndConditionsModal = (props: ITermsAndConditionsModal) => {
  const {
    isMobile,
    showModal,
    handleModal,
    isChecked,
    handleAgree,
    handleDisagree,
  } = props;

  const { operatorCmsInfo } = useSelector(
    (state: RootState) => state.operatorReducer
  );

  return (
    <Fragment>
      <Box sx={StyleObject?.tncBox} onClick={() => handleModal()}>
        <Checkbox checked={isChecked} />
        <Typography sx={StyleObject?.cursorPointer}>
          {operator?.tnCApply}
        </Typography>
      </Box>

      <Dialog
        open={showModal}
        sx={{ width: isMobile ? 1 : 1 / 2, ...StyleObject?.dialogStyle }}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{ sx: { ...StyleObject?.dialogPaperProps } }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          variant="headingNoPadding"
          color={colors?.THEME_BLUE}
        >
          {global?.tnc}
        </DialogTitle>

        <DialogContent sx={StyleObject?.dialogContentStyle}>
          <DialogContentText
            variant="body1"
            sx={StyleObject?.yourAgreementText}
          >
            {operator?.yourAgreement}
          </DialogContentText>
          <DialogContentText>
            <div
              dangerouslySetInnerHTML={{
                __html: operatorCmsInfo?.termsAndCondition,
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton
            variant="text"
            onClick={handleDisagree}
            sx={StyleObject?.dialogDisagreeBtn}
          >
            {global?.disagree}
          </CustomButton>
          <CustomButton
            onClick={handleAgree}
            variant="contained"
            sx={StyleObject?.dialogAgreementBtn}
          >
            {global?.agree}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default TermsAndConditionsModal;
