import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../../component/CustomPagination";
import { RootState } from "../../../redux/store";
import BookingCard from "./BookingsCard";
import { useState } from "react";
import { useEffect } from "react";
import CardSkelaton from "../../../component/SkelatonLoader/CardSkelaton";
import { getBookingDetails } from "../../../redux/actions/rentActions/bookingAction";
import { setPaginationData, clearPaginationData } from "../../../redux/actions";
import { actions } from "../../../redux";
import { getYorBookingDetails } from "../../../yor-src/redux/actions/yorActions";
import { noCurrentBooking } from "../../../assets";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../constant/routeContants";

const initPagination = {
  limit: 4,
  page: 1,
  status: "",
};
const yorPaginationState = {
  page: 1,
  pageSize: 4,
};

const BookingTabs = ({
  bookingStatus,
  itemsperpage = 2,
  classNames = "col-md-6",
}) => {
  initPagination.status = bookingStatus;
  initPagination.limit = itemsperpage;
  const [sklFlag, setSkl] = useState(true);
  const dispatch = useDispatch();
  const { bookingList, totalBooking } = useSelector(
    (state: RootState) => state.bookingReducer
  );
  const { paginationData } = useSelector((state: any) => state.profileReducer);
  const { yorBookingDetails } = useSelector(
    (state: RootState) => state.yorReducer
  );
  const [pagination, setPagination] = useState(initPagination);
  const [yorPagination, setYorPagination] = useState(yorPaginationState);
  const [currentBookings, setCurrentBookings] = useState<Array<any>>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (bookingStatus == "Subscription") {
      dispatch(getYorBookingDetails(yorPagination));
    } else {
      dispatch(getBookingDetails(pagination));
    }
  }, [pagination, itemsperpage, yorPagination]);

  useEffect(() => {
    setCurrentBookings(bookingList);
    setTimeout(() => {
      setSkl(false);
    }, 500);
  }, [bookingList]);

  useEffect(() => {
    if (bookingStatus == "Subscription") {
      setYorPagination(yorPaginationState);
    } else {
      setPagination(initPagination);
    }
  }, [bookingStatus]);

  return sklFlag ? (
    <div className="row">
      {Array(itemsperpage)
        .fill(null)
        .map((_index, ind) => (
          <div className={classNames} key={ind}>
            <CardSkelaton />
          </div>
        ))}
    </div>
  ) : (
    <>
      {bookingList?.message ? (
        ""
      ) : (
        <div className="row justify-content-center">
          {bookingStatus === "Subscription" ? (
            yorBookingDetails?.bookings?.length ? (
              yorBookingDetails?.bookings?.map((_details, ind) => {
                return (
                  <div className={classNames} key={_details.booking_id + ind}>
                    <BookingCard
                      bookingStatus={bookingStatus}
                      data={_details}
                      pagination={pagination}
                    />
                  </div>
                );
              })
            ) : (
              <>
                <div>
                  <div className="mx-auto text-center">
                    <img
                      src={noCurrentBooking}
                      className="no-booking-img"
                      alt="No Booking Found"
                    />
                    <p className="mt-3">You have not booked any rides</p>
                    <span className="text-muted">
                      Would you like to plan your next ride with us ?
                    </span>
                    <h4 className="mt-2 no-booking-found">
                      Explore Renting Fleet
                    </h4>
                    <div className="mt-5">
                      <a
                        onClick={() => {
                          navigate(Routes.YOR_RENTING_FLEET);
                        }}
                        className="freedo-primary-button rounded-pill mt-5"
                      >
                        Explore Fleet
                      </a>
                    </div>
                  </div>
                </div>
              </>
            )
          ) : (
            bookingList?.map((_details, ind) => {
              return (
                <div className={classNames} key={_details.booking_id + ind}>
                  <BookingCard
                    bookingStatus={bookingStatus}
                    data={_details}
                    pagination={pagination}
                  />
                </div>
              );
            })
          )}
          {/* {bookingList?.map((_details, ind) => {
            return (
              <div className={classNames} key={_details.booking_id + ind}>
                <BookingCard
                  bookingStatus={bookingStatus}
                  data={_details}
                  pagination={pagination}
                />
              </div>
            );
          })} */}
          {bookingStatus == "Subscription" ? (
            yorBookingDetails?.pageData?.total > 4 ? (
              <div className="pagination-wrap mt-3">
                <CustomPagination
                  setPagination={setYorPagination}
                  itemsPerPage={4}
                  result={yorBookingDetails?.bookings}
                  totalrecord={yorBookingDetails?.pageData?.total}
                />
              </div>
            ) : null
          ) : totalBooking > itemsperpage ? (
            <div className="pagination-wrap mt-3">
              <CustomPagination
                setPagination={setPagination}
                itemsPerPage={itemsperpage}
                result={bookingList}
                totalrecord={totalBooking}
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};
export default BookingTabs;
