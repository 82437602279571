import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { forwardRef } from "react";
import { useSelector } from "react-redux";

import { squareTexture } from "../../../../assets";
import { ITopSection } from "../../../../interfaces/operatorInterface";
import en from "../../../../locales/en.json";
import { RootState } from "../../../../redux/store";
import { CustomButton } from "../../../../yor-src/components/atom/Buttons/CustomButtons";
import CustomAutocomplete from "../../../../yor-src/components/atom/InputFields/CustomAutocomplete";
import Input from "../../../../yor-src/components/atom/InputFields/Input";
import OtpModal from "../../../../yor-src/components/template/OtpModal";
import { StyleObject } from "./StyleObject";
import TermsAndConditionsModal from "./TermsAndConstionsModal";

const { operator, global } = en;

const TopSection = forwardRef((props: ITopSection, ref: any) => {
  //
  const {
    generateOtpHandler,
    state,
    handleChange,
    filteredOptions,
    isDisabled,
    handleModal,
    isChecked,
    showModal,
    handleDisagree,
    handleAgree,
    validateOtpHandler,
    fields,
    handleResendOtp,
    show,
    isOtpSubmitBtnEnabled,
    handleModalClose,
    errorState,
  } = props;
  //
  const theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down(750));

  const { leadGenerationLoader, leadVerificationLoader, operatorCmsInfo } =
    useSelector((state: RootState) => state.operatorReducer);

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      sx={StyleObject?.topSectionStack}
    >
      {/* Join Us Form Section */}
      <Box width={isMobile ? 1 : 42 / 100} sx={StyleObject?.joinUsFormTopBox}>
        <Box
          width={isMobile ? 9 / 10 : 6 / 10}
          height={isMobile ? "600px" : "600px"}
          sx={StyleObject?.joinUsFormSecondBox}
        >
          <Typography variant="h3" sx={StyleObject?.textAlignCenter}>
            {operator?.partnerWithUs}
          </Typography>

          {/* Name */}
          <Input
            placeholder={operator?.namePlaceholder}
            value={state?.name}
            inputProps={{ maxLength: 150 }}
            customStyle={StyleObject?.customInputStyle}
            containerWidth={1}
            muiTextFieldRootStyle={{ ...StyleObject?.muiTextFieldRootStyle }}
            inputContainerStyle={{
              "& .MuiOutlinedInput-input": { paddingLeft: "9px !important" },
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange("name", e.target.value);
            }}
            errormessage={!errorState?.name ? null : errorState?.name}
          />

          {/* Phone */}
          <Input
            placeholder={operator?.phonePlaceholder}
            value={state?.phone}
            startAdornment={() => global?.countryCodeIN}
            inputProps={{ maxLength: 10 }}
            customStyle={{ ...StyleObject?.customInputStyle, lineHeight: 0 }}
            containerWidth={1}
            muiTextFieldRootStyle={{ ...StyleObject?.muiTextFieldRootStyle }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange("phone", e.target.value);
            }}
            errormessage={!errorState?.phone ? null : errorState?.phone}
          />

          {/* Email Id */}
          <Input
            placeholder={operator?.emailPlaceholder}
            value={state?.email}
            inputProps={{ maxLength: 150 }}
            customStyle={StyleObject?.customInputStyle}
            containerWidth={1}
            muiTextFieldRootStyle={{ ...StyleObject?.muiTextFieldRootStyle }}
            inputContainerStyle={{
              "& .MuiOutlinedInput-input": { paddingLeft: "9px !important" },
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange("email", e.target.value);
            }}
            errormessage={!errorState?.email ? null : errorState?.email}
          />

          {/* District */}
          <CustomAutocomplete
            placeholder={operator?.cityPlaceholder}
            handleChange={(values: string[]) => {
              handleChange("preferredCity", values);
            }}
            choice={filteredOptions}
          />

          {/* T&C Modal */}
          <TermsAndConditionsModal
            isMobile={isMobile}
            showModal={showModal}
            handleModal={handleModal}
            isChecked={isChecked}
            handleAgree={handleAgree}
            handleDisagree={handleDisagree}
          />

          {/* Button */}
          <CustomButton
            onClick={generateOtpHandler}
            variant="contained"
            loading={leadGenerationLoader}
            sx={StyleObject?.joinUsButton}
            disabled={isDisabled()}
          >
            {global?.submit}
          </CustomButton>
        </Box>

        {/* OTP Verification Modal */}
        {show && (
          <OtpModal
            handleChange={handleChange}
            handleModalClose={handleModalClose}
            otpValue={fields?.otp}
            phoneNumber={fields?.phone}
            openModal={show}
            handleOtpModalSubmit={validateOtpHandler}
            handleResendOtp={handleResendOtp}
            isOtpSubmitBtnEnabled={isOtpSubmitBtnEnabled}
            loading={leadVerificationLoader}
            ref={ref}
          />
        )}
      </Box>

      {/* Right Section */}
      <Box sx={{ width: isMobile ? 1 : 58 / 100 }}>
        <img
          src={
            operatorCmsInfo?.section1?.mainImage
              ? operatorCmsInfo?.section1?.mainImage
              : squareTexture
          }
          alt={operator?.altImg}
          style={StyleObject?.mainImg}
        />
      </Box>
    </Stack>
  );
});

export default TopSection;
