import { Components, Theme } from "@mui/material";
import { colors } from "../colors";

export const inputTextCustomizations: Components<Theme> = {
  MuiTextField: {
    styleOverrides: {
      root: {
        variants: [
          {
            props: {
              variant: "outlined",
              color: "primary",
            },
            style: {
              "& .MuiOutlinedInput-root": {
                borderRadius: "60px",
                height: "42px",
                border: "none",
              },
              "& .MuiOutlinedInput-input": {
                border: "unset !important",
                boxShadow: "unset !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: `2px solid ${colors?.primary}`,
              },
              //   "& .MuiInputLabel-root": {
              //     transform: "translate(17px, 10px) scale(1)", // Adjust label position and scale
              //   },
            },
          },
        ],
      },
    },
  },
};
