import { BASE_URL, CMS_URL, getApiUrls } from "../../config/apiUrls";
import { Axios } from "../../config/axiosConfig";
import { constructUrl } from "../../utills/helper";

export const getMasterCityList = (payload: any) =>
  Axios.get(BASE_URL + getApiUrls()?.getMasterCityList).catch(
    (err: any) => err
  );

export const operatorLeadGeneration = (payload: any) =>
  Axios.post(BASE_URL + getApiUrls()?.operatorLeadGeneration, payload).catch(
    (err: any) => err
  );

export const operatorLeadVerification = (payload: any) =>
  Axios.put(BASE_URL + getApiUrls()?.operatorLeadVerification, payload).catch(
    (err: any) => err
  );

export const retryOtpForOperator = (payload: any) =>
  Axios.post(BASE_URL + getApiUrls()?.retryOtpForOperator, payload).catch(
    (err: any) => err
  );

export const verifyOperatorEmail = (payload: any) =>
  Axios.get(BASE_URL + getApiUrls(payload)?.verifyOperatorEmail).catch(
    (err: any) => err
  );

export const fetchEsignDoc = (payload: any) =>
  Axios.get(BASE_URL + getApiUrls(payload)?.fetchEsignDoc).catch(
    (err: any) => err
  );

export const eSignGenerateOtp = (payload: any) =>
  Axios.post(BASE_URL + getApiUrls()?.eSignGenerateOtp, payload).catch(
    (err: any) => err
  );

export const eSignVerifyOtp = (payload: any) =>
  Axios.post(BASE_URL + getApiUrls()?.eSignVerifyOtp, payload).catch(
    (err: any) => err
  );

export const eSignResendOtp = (payload: any) =>
  Axios.post(BASE_URL + getApiUrls()?.eSignResendOtp, payload).catch(
    (err: any) => err
  );

export const fetchOperatorCmsInfo = (payload: any) => {
  try {
    const url: string = CMS_URL + getApiUrls().fetchOperatorCmsInfo;
    const apiurl: string = constructUrl(url, payload);
    return Axios.get(apiurl).catch((err) => err);
  } catch (err) {
    return err;
  }
};
